import * as styles from "./ViewSafetyDocumentStyles";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ApplicableRules from "../../Components/ApplicableRules/ApplicableRules";
import ApplicableReporting from "../../Components/ApplicableReporting/ApplicableReporting";
import ReTab from "../../unity-ui-common/Components/ReTab";
import SafetyDocumentActivity from "../../Components/SafetyDocumentActivity/SafetyDocumentActivity";
import {
  ContactCompliance,
  SafetyDocumentDetailInformation,
  SiteCompliance,
  SafetyDocument,
  LoadingSpinner,
} from "../../Components";
import {
  FileTextOutlined,
  ContactsOutlined,
  CheckCircleOutlined,
  CodeSandboxOutlined,
  FileOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { file } from "../../API/File";
import { report } from "../../API/Report";

function ViewSafetyDocument() {
  const { reportId } = useParams();
  
  const [reportDetails, setReportDetails] = useState<any>({});
  const [allVersions, setAllVersions] = useState([]);
  const [selectedReport, setSelectedReport] = useState<any>({});
  const [activeTabId, setActiveTabId] = useState<string>("1");
  const [flag, setflag] = useState<boolean>(false);
  const [isDataFetching, setIsDataFetching] = useState<boolean>(false);
  const [commonRefresh,setCommonRefresh] = useState<boolean>(false)
  const [commonRefresh1,setCommonRefresh1] = useState<boolean>(false)
  const [loading,setLoading]= useState(false)
  const fetchReportData = async (id: string) => {
    
    setIsDataFetching(true)
    setLoading(true)
    let res = await report.findOne(id);
    if (res?.statusCode !== 500) {
      setReportDetails(res);
      await fetchAllVersions(res,res?.id)
    }
    setIsDataFetching(false)
    setLoading(false)
  };

  const fetchAllVersions = async (reportDetails: any,id:string) => {
    if (reportId) {
      let queryObj: any = {
        org: reportDetails.orgID,
        serialNumber: reportDetails.serialNumber,
      };
      let res = await report.findVersions(queryObj);
      if (res) {
        res = await Promise.all(
          res.map(async (item: any) => {
            const fileUrls = await file.getSignedUrls(item.attachments);
            return {
              ...item,
              attachedFilesDetails: fileUrls,
            };
          })
        );

        setAllVersions(res);
        // this code is for changing the value of severity when its empty string
        const report: any = [];
        res.forEach((item: any) => {
          if (item.id === reportId) {
            if (!Array.isArray(item?.meta?.severity)) {
              report.push({
                ...item,
                meta: {
                  ...item?.meta,
                  severity: [],
                },
              });
            } else {
              report.push(item);
            }
          }
        });
        // const filterReport = report.find((obj:any) => obj.id === usdeId);
        // console.log(filterReport?.id)
        // console.log(filterReport)
        const selected = report.find((item:any)=>item.id === id)|| report[0]
        setSelectedReport(selected);
       setCommonRefresh1(true)
      }
    }
  };

  const TabItems = [
    {
      title: (
        <span>
          <CheckCircleOutlined />
          Site Compliance
        </span>
      ),
      key: "1",
      children: (
        <SiteCompliance
          safetyDocumentData={selectedReport}
          safetyDocumentID={reportId}
          commonRefresh={commonRefresh}
          setCommonRefresh = {setCommonRefresh}
          commonRefresh1={commonRefresh1}
        />
      ),
    },
    {
      title: (
        <span>
          <ContactsOutlined />
          Contact Compliance
        </span>
      ),
      key: "2",
      children: (
        <ContactCompliance
          safetyDocumentData={selectedReport}
          safetyDocumentID={reportId}
          commonRefresh={commonRefresh}
          setCommonRefresh = {setCommonRefresh}
          
        />
      ),
    },
    {
      title: (
        <span>
          <FileTextOutlined />
          Safety Document
        </span>
      ),
      key: "3",
      children: (
        <SafetyDocument
          filesData={selectedReport?.attachedFilesDetails}
          reportDetail={reportDetails}
          setFlag={setflag}
          flag={flag}
        />
      ),
    },
    {
      title: (
        <span>
          <CodeSandboxOutlined />
          Applicable Rules
        </span>
      ),
      key: "4",
      children: (
        <ApplicableRules data={reportDetails} setFlag={setflag} flag={flag} isDataFetching={isDataFetching} />
      ),
    },
    {
      title: (
        <span>
          <FileOutlined />
          Applicable Reporting
        </span>
      ),
      key: "5",
      children: (
        <ApplicableReporting
          reportId={reportDetails?.id}
          applicableRules={reportDetails?.matchingRules}
          flag={flag}
          isDataFetching={isDataFetching}
        />
      ),
    },
    {
      title: (
        <span>
          <ClockCircleOutlined />
          Activity
        </span>
      ),
      key: "6",
      children: (
        <SafetyDocumentActivity
          flag={activeTabId}
          safetyDocumentID={reportDetails?.id}
        />
      ),
    },
  ];

  useEffect(() => {
    reportId && fetchReportData(reportId);
  }, [flag,commonRefresh,reportId]);
const navigate = useNavigate()
// useEffect(()=>{
//  if(selectedReport?.id !=reportId && selectedReport?.id ){
//   navigate(`/reports/view/${selectedReport?.id}`, { replace: true });
//   reportId && fetchReportData(selectedReport?.id);
//  }
// },[selectedReport?.id])
  
  // useEffect(() => {
  //   if (reportDetails && Object.keys(reportDetails).length)
  //     fetchAllVersions(reportDetails);
  //   setCommonRefresh(false)
  // }, [reportDetails,commonRefresh]);
  useEffect(()=>{
    if(commonRefresh){
      setCommonRefresh(false)
    }
    if(commonRefresh1){
      setCommonRefresh1(false)
    }
  },[commonRefresh,commonRefresh1])

  const handleReportChange = useMemo(() => {
    return (newReportId: string) => {
      if(!isDataFetching){
      if (newReportId !== reportId && newReportId) {
        navigate(`/reports/view/${newReportId}`, { replace: true });
        // reportId && fetchReportData(newReportId);
      }}
    };
  }, [reportId, navigate, fetchReportData,isDataFetching]);
 
  return (
    
    <styles.Container className="subContainer">
     {(Object.values(selectedReport)?.length>0 && !loading)?
    <SafetyDocumentDetailInformation
      setReportDetails={setReportDetails}
      reportDetials={selectedReport}
      reportId={selectedReport?.id}
      allVersions={allVersions}
      selectedReport={selectedReport}
      setSelectedReport={setSelectedReport}
      handleReportChange={handleReportChange}
      setCommonRefresh={setCommonRefresh}
      isDataFetching={isDataFetching}
    />:<LoadingSpinner/>}
    <ReTab
      activeKey={activeTabId}
      onChange={(id: string) => {
        setActiveTabId(id);
      }}
      defaultOpenTab={activeTabId}
      items={TabItems}
    />
  </styles.Container>
  );
}

export default ViewSafetyDocument;
