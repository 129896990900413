import { ruleEngine } from "../../API/RuleEngine";
import { UserMappingByValue, contactMapping } from "../../Helper/Mappings";
import * as Styles from "./ApplicableReportingStyles";
import { useEffect, useState } from "react";
import { convertDateInReadableFormat } from "../../Helper/Methods";
import ReTable from "../../unity-ui-common/Components/ReTable/ReTable";
import { IComponentProps } from "../../Interfaces/Component/ApplicableReporting.interface";

function ApplicableReporting(props: IComponentProps) {
  const { applicableRules, reportId, flag,isDataFetching } = props;
console.log(applicableRules)
  const [tableData, setTableData] = useState<any>([]);
  const [isLoading,setIsLoading] = useState<boolean>()
  const columns = [
    {
      title: "Contact Country",
      dataIndex: "countryName",
      key: "countryName",
      width: 180,
      render: (countryName: any) => {
        return countryName;
      },
    },
    {
      title: "Contact Type",
      dataIndex: "role",
      key: "role",
      width: 180,
      render: (role: any) => {
        const text = UserMappingByValue(role);
        return text;
      },
    },
    {
      title: "Due Date (CD)",
      width: 250,
      dataIndex: "dueDate",
      key: "dueDate",
      render: (dueDate: any, record: any) => {
        const text = dueDate
          ? `${convertDateInReadableFormat(dueDate)} (${record.dueDays})`
          : "-";
        return text;
      },
    },
  ];

  const getTableData = async (applicableRules: any) => {
    const payload = {
      matchingRules: applicableRules,
      reportId: reportId,
    };
    setIsLoading(true)
    const res = await ruleEngine.contactMapper(payload);
    setTableData(
      res?.map((item: any) => {
        return {
          ...item,
          ...item.contact,
        };
      })
      ||[]);
      setIsLoading(false)
  };

  useEffect(() => {
    getTableData(applicableRules);
  }, [flag,applicableRules]);

  return (
    <Styles.Container>
      <ReTable
        name="Applicable Reporting"
        columns={columns}
        data={tableData}
        loading={isDataFetching||isLoading}
        columnOptions={{
          filter: {
            columns: [
              {
                key: "role",
                action: (val: any) => contactMapping(Number(val)),
              },
              "countryName",
              {
                key: "dueDate",
                action: (val: any) => convertDateInReadableFormat(val),
              },
            ],
          },
          sorting: {
            columns: ["role", "countryName", "dueDate"],
          },
        }}
        scroll={{
          x: 800,
          y: 800,
        }}
      />
    </Styles.Container>
  );
}

export default ApplicableReporting;
