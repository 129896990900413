export const modeTemplateOptions = [
  {
    value: "EMAIL",
    title: "Email",
  },
  {
    value: "SYSTEM",
    title: "System",
  },
  {
    value: "DOCUMENT",
    title: "Document",
  },
];

export const company = [
  {
    value: "CompanyA",
    title: "CompanyA",
  },
  {
    value: "CompanyB",
    title: "CompanyB",
  },
  {
    value: "CompanyC",
    title: "CompanyC",
  }, 
];
export const partners = [
  {
    value: "PatnerA",
    title: "PatnerA",
  },
  {
    value: "PatnerB",
    title: "PatnerB",
  },
  {
    value: "PatnerC",
    title: "PatnerC",
  },
];
export const typeTemplateOptionsData: any = {
  EMAIL: [
    {
      value: "SITE_NOTIFICATION",
      title: "Site Notification",
    },
    {
      value: "EC/IRB_NOTIFICATION",
      title: "EC/IRB Notification",
    },
    {
      value: "PARTNER_NOTIFICATION",
      title: "Partner Notification",
    },
    {
      value: "RA_NOTIFICATION",
      title: "RA Notification",
    },
    {
      value: "SAFETY_DOCUMENT_REVIEW",
      title: "Safety Document Review",
    },
  ],
  SYSTEM: [
    {
      value: "SAFETY_DOCUMENT_NOTIFICATION_SITE",
      title: "Safety Document Notification-Site",
    },
    {
      value: "SAFETY_DOCUMENT_NOTIFICATION_PARTNER",
      title: "Safety Document Notification-Partner",
    },
    {
      value: "SAFETY_DOCUMENT_NOTIFICATION_RA",
      title: "Safety Document Notification-RA",
    },
    {
      value: "SAFETY_DOCUMENT_NOTIFICATION_EC/IRB",
      title: "Safety Document Notification-EC/IRB",
    },
  ],
  DOCUMENT: [
    {
      value: "CSR Narrative",
      title: "CSR Narrative",
    },
    {
      value: "Safety Narrative",
      title: "SAE Narrative",
    },
    {
      value: "PSUR",
      title: "Periodic Safety Update Report",
    },
    {
      value: "DSUR",
      title: "Development Safety Update Report",
    }
  ]
};

export const triggerTemplateOptions = [

];

export const mapping = [
  {
    heading: "PVA Master Data",
    mapping: [
      { title: 'Company', data: '{{company}}' },
      { title: 'Company Email', data: '{{company_email}}' },
      { title: 'Company Fax', data: '{{company_fax}}' },
      { title: 'Partner', data: '{{partner}}' },
      { title: 'Partner Email', data: '{{partner_email}}' },
      { title: 'Partner Fax', data: '{{partner_fax}}' },
      { title: 'Product', data: '{{product}}' },
      { title: 'EU Authorised', data: '{{eu_authorised}}' },
      { title: 'MAH in EU', data: '{{mah_in_eu}}' },
      { title: 'Territory', data: '{{territory}}' },
      { title: 'Safety Report', data: '{{safety_report}}' },
      { title: 'Serious Inbound', data: '{{serious_inbound}}' },
      { title: 'Non-Serious Inbound', data: '{{non_serious_inbound}}' },
      { title: 'Exchange Type', data: '{{exchange_type}}' },
      { title: 'WW Literature', data: '{{ww_literature}}' },
      { title: 'Reconciliation', data: '{{reconciliation}}' },
      { title: 'Recon Timeline', data: '{{recon_timeline}}' },
      { title: 'PSUR', data: '{{psur}}' },
      { title: 'PSUR Timeline', data: '{{psur_timeline}}' },
    ],
  },
  // {
  //   heading: "Patient",
  //   mapping: [
  //     { title: 'Patient ID', data: '{{patient_id}}' },
  //     { title: 'Age', data: '{{age}}' },
  //     { title: 'Age Group', data: '{{age_group}}' },
  //     { title: 'Race', data: '{{race}}' },
  //     { title: 'Sex', data: '{{sex}}' }
  //   ],
  // },
  // {
  //   heading: "Event",
  //   mapping: [
  //     { title: 'Onset Date', data: '{{onset_date}}' },
  //     { title: 'Cessation Date', data: '{{cessation_date}}' },
  //     { title: 'Seriousness Criteria', data: '{{seriousness_criteria}}' },
  //     { title: 'Event Reported Term', data: '{{event_reported_term}}' },
  //     { title: 'Event MedDRA LLT', data: '{{event_meddra_llt}}' },
  //     { title: 'Event MedDRA PT', data: '{{event_meddra_pt}}' },
  //     { title: 'Event MedDRA SOC', data: '{{event_meddra_soc}}' },
  //     { title: 'Event Description', data: '{{event_description}}' },
  //     { title: 'Treatment', data: '{{treatment}}' },
  //     { title: 'Outcome', data: '{{outcome}}' },
  //     { title: 'Labeled', data: '{{labeled}}' },
  //     { title: 'Reporter Causality', data: '{{reporter_causality}}' },
  //     { title: 'Company Causality', data: '{{company_causality}}' }
  //   ]
  // },
  // {
  //   heading: "Product",
  //   mapping: [
  //     { title: 'Product Flag', data: '{{product_flag}}' },
  //     { title: 'Tradename', data: '{{tradename}}' },
  //     { title: 'Active Ingredient', data: '{{active_ingredient}}' },
  //     { title: 'Indication', data: '{{indication}}' },
  //     { title: 'Start Date', data: '{{start_date}}' },
  //     { title: 'Stop Date', data: '{{stop_date}}' },
  //     { title: 'Dosage', data: '{{dosage}}' },
  //     { title: 'Route of administration', data: '{{route_of_administration}}' },
  //     { title: 'Action Taken', data: '{{action_taken}}' },
  //     { title: 'WHO-DD code', data: '{{who-dd_code}}' },
  //     { title: 'WHO-ATC code', data: '{{who-atc_code}}' }
  //   ],
  // },
  // {
  //   heading: "Reporter",
  //   mapping: [
  //     { title: 'Title', data: '{{title}}' },
  //     { title: 'Name', data: '{{name}}' },
  //     { title: 'Site Name', data: '{{site_name}}' },
  //     { title: 'Site Number', data: '{{site_number}}' },
  //     { title: 'Address for Correspondence', data: '{{address_for_correspondence}}' },
  //     { title: 'Phone', data: '{{phone}}' },
  //     { title: 'Email ID', data: '{{email_id}}' },
  //     { title: 'Preferred Mode of Correspondence', data: '{{preferred_mode_of_correspondence}}' },
  //     { title: 'Backup Mode of Correspondence', data: '{{backup_mode_of_correspondence}}' },
  //     { title: 'Role', data: '{{role}}' },
  //     { title: 'Language', data: '{{language}}' }
  //   ],
  // },
  // {
  //   heading: "Medical History",
  //   mapping: [
  //     { title: 'Start Date', data: '{{start_date}}' },
  //     { title: 'Stop Date', data: '{{stop_date}}' },
  //     { title: 'Treatment', data: '{{treatment}}' }
  //   ],
  // },
  // {
  //   heading: "Concomitant Medications",
  //   mapping: [
  //     { title: 'Start Date', data: '{{start_date}}' },
  //     { title: 'Stop Date', data: '{{stop_date}}' },
  //     { title: 'Disease Term', data: '{{disease_term}}' },
  //     { title: 'Disease LLT', data: '{{disease_llt}}' }
  //   ],
  // },
  // {
  //   heading: "Case Information",
  //   mapping: [
  //     { title: 'Day 0', data: '{{day_0}}' },
  //     { title: 'Latest Receipt Date', data: '{{latest_receipt_date}}' },
  //     { title: 'Country of Occurrence', data: '{{country_of_occurrence}}' }
  //   ],
  // },
  // {
  //   heading: "Marketing Authorization Holder",
  //   mapping: [
  //     { title: 'Approval Type', data: '{{approval_type}}' },
  //     { title: 'Authorization Country', data: '{{authorization_country}}' },
  //     { title: 'Authorization Country', data: '{{authorization_country}}' }
  //   ],
  // },
  // {
  //   heading: "Study",
  //   mapping: [
  //     { title: 'Study No', data: '{{study_no}}' },
  //     { title: 'Study Name', data: '{{study_name}}' },
  //     { title: 'Site Number', data: '{{site_number}}' },
  //     { title: 'Center Number', data: '{{center_number}}' },
  //     { title: 'Study Registration Number', data: '{{study_registration_number}}' },
  //     { title: 'Registration Country', data: '{{registration_country}}' }
  //   ],
  // },
  // {
  //   heading: "Attachments",
  //   mapping: [
  //     { title: 'Document Name', data: '{{document_name}}' }
  //   ],
  // },
  // {
  //   heading: "Report",
  //   mapping: [
  //     { title: 'PBRER', data: '{{pbrer}}' },
  //     { title: 'DSUR', data: '{{dsur}}' }
  //   ],
  // },
  {
    heading: "Case Processing",
    mapping: [
      { title: 'Global Safety Database', data: '{{COMPANY is the holder of the Global Safety Database and is responsible for its maintenance.Direct access for PARTNER to the Global Safety Database is not available for security reasons. Upon Regulatory Authority request, COMPANY will provide safety data to the PARTNER. }}' },
      { title: 'EudraVigilance case download', data: '{{Each Party is responsible for downloading of ICSRs/Safety Reports from Eudravigilance in accordance with European Medicines Agency (EMA) regulations.}}' },
      { title: 'Safety Report Collection from the Territory', data: '{{PARTNER is responsible for collecting and formally recording all Safety Reports (as defined in Appendix B) associated with the Product(s) in the Territory. }}' },
      { title: 'Exchange of Safety Reports', data: '{{Each Party shall forward to the other Party all Safety Reports which include at least a Product covered under this PVA and an Adverse Event / Special Situation, regardless patient is identifiable or not, with the following timelines:"• serious Safety Reports, including lack of efficacy for contraceptive Product(s): within seven (7) calendar days • non-serious Safety Reports: within thirty (30) calendar days after the first information has been received."This timeline is applicable for follow-up reports as well. The day of initial receipt of the Safety Report by any employee of PARTNER is defined as day zero.The transmission shall be made by e-mail directly to the Global Pharmacovigilance Department using the dedicated email as per Appendix C in CIOMS I or in .xml format. In the event that e-mail transmission is not possible, fax may be used, by using the number indicated in Appendix C.}}' },
      { title: 'Data Processing of Safety Reports', data: '{{All worldwide safety information received will be processed by COMPANY. Safety Reports received from the Territory will be procesed by PARTNER in its Safety Database}}' },
      { title: 'Acknowledgement of receipt', data: '{{Upon receipt of a Safety Report, each Party acknowledges its receipt to the other Party in writing as soon as possible but not later than in four (4) business days from receipt. The acknowledgement shall include the Safety Database / Individual Case ID (if applicable).If the report is excluded, no Safety Database ID will be provided, but the acknowledgement and a statement about the exclusion will be provided to the other Party. }}' },
      { title: 'Expedited reporting to regulatory Authorities', data: '{{PARTNER shall submit the Safety Report from the Territory to the EudraVigilance and to the MHRA (UK) and Swissmedic (CH) if applicable.COMPANY is responsible for submission of Safety Reports from outside of the Territory of the PARTNER to Regulatory Authorities as applicable (including foreign reporting to EudraVigilance).}}' },
      { title: 'Follow up information requests for Safety Reports', data: '{{Parties are responsible for seeking any follow-up information in their territories.Upon request by COMPANY, PARTNER shall seek diligently and in a timely manner, additional follow-up information on initial and follow-up Safety Reports.COMPANY will request maximum three (3) follow-up attempts and the Safety Report cannot be considered lost to follow-up or closed until three (3) documented follow-up attempts were made or until the reporter explicitly states that the Safety Report is lost to follow-up.PARTNER shall follow all pregnancy exposures (maternal or paternal) and pregnancies associated with the use of the Product to pregnancy outcome to ensure that relevant pregnancy data is captured.Upon request of COMPANY, PARTNER shall use product-specific dedicated Targeted Questionnaires/Targeted Follow-up Forms (TFUs) in addition to regular follow-up to obtain further information about an event of special interest. }}' },
      { title: 'Literature Review', data: '{{Weekly worldwide medical/scientific literature review is conducted by COMPANY according to GVP, Module VI.Safety Reports identified form the worldwide literature review shall be exchanged in accordance with the timelines, format and method of exchange outlined in Section Exchange of Safety Reports"PARTNER is responsible for setting up weekly local literature search strategy for the Product(s) in the Territory (incl. list of the screened journals / databases, keywords, tracking and assessment of literature hits and QC procedure). Upon request of COMPANY, PARTNER shall share the local procedure for literature screening and documented results of the search."Local literature cases as well as local literature concerning the benefit/risk profile of the Product, not necessarily including Safety Reports, are to be provided to COMPANY together with the corresponding publication. The publication shall be translated into English.For literature articles containing individual cases, the same stipulated timelines and format as for Safety Reports shall be followed, while for non-ICSR literature articles containing e.g. signal information should be handled as per the Sections of "Emerging Safety Issues / Urgent Safety Information” or "Any other safety issue not considered as ESI/USI" as applicable.}}' },
      { title: 'Eudravigilance Medical Literature Monitoring', data: '{{COMPANY is responsible for the Eudravigilance Medical Literature Monitoring (MLM) activities in accordance with European Medicines Agency (EMA) regulations.}}' },
      { title: 'Reconciliation', data: '{{Each Party shall maintain records of all Safety Reports, both initial and follow-up, forwarded to the other Party.Reconciliation shall be performed quarterly (/monthly) <<select the appropriate one >>  Within fifteen (15) calendar days following the end of each quarter, each Party shall provide the other Party with a list of Safety Reports received from the other Party. Each Party shall reconcile such list of Safety Reports, confirm its accuracy and send their list of Safety Reports back to the other Party within fifteen (15) calendar days. If discrepancies are found, both Parties shall cooperate to promptly resolve such discrepancies.}}' },
      { title: 'Confidentiality of data', data: '{{No data which may allow identifying a patient (such as full name, address, contact details, hospital number, etc.), shall be exchanged. Reporter’s (HCP and non-HCP) name and contact details shall not be exchanged. Both Parties agree to comply with the applicable local and global data privacy legislation.}}' },
      { title: 'Dedicated contacts for Safety Report exchange', data: '{{Appendix C details the dedicated contact details for exchange of safety reports and 24 h contact details. }}' },
    ],
  },
]

// {
//   value: "NOTIFICATION_TEMPLATE",
//   title: "Notification Template",
// },
// {
//   value: "IRBEC_NOTIFICATION_TEMPLATE",
//   title: "EC/IRB Notification Template",
// },
// {
//   value: "IRBEC_CONFIRMATION_REMINDER_TEMPLATE",
//   title: "EC/IRB Confirmation Reminder Template",
// },
// {
//   value: "ACK_REMINDER_TEMPLATE",
//   title: "Acknowledge Reminder Template",
// },
// {
//   value: "IRBEC_INFORM_NOTIFICATION_TEMPALTE",
//   title: "EC/IRB Inform Notification Template",
// },
// {
//   value: "SPONSOR_PENDING_NOTIFICATION_TEMPLATE",
//   title: "Sponsor Pending Notification Template",
// },
// {
//   value: "SPONSOR_DUEDATE_NOTIFICATION_TEMPLATE",
//   title: "Sponsor Due Date Notification Template",
// }