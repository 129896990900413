import { Link } from "react-router-dom";
import { convertDateInReadableFormat, textAndColor } from "../../Helper/Methods";
import { Space, Tag, Tooltip } from "antd";
import { auth } from "../../API/Auth";
import { InfoCircleOutlined } from "@ant-design/icons";
export const boolFilterItems = [
    {
        text: "Yes",
        value: true
    },
    {
        text: "No",
        value: false
    }
]

const baseColumns = [
    {
      title: "Safety Document ID",
      dataIndex: "safetyDatabaseReportNumber",
      key: "safetyDatabaseReportNumber",
      render: (data: any) => {
        return data || "-";
      },
    },
    {
      title: "Notification Date",
      dataIndex: "sentOn",
      key: "sentOn",
      render: (date: any) => {
        return date ? date : "-";
      },
    },
    {
      title: "Day 0",
      dataIndex: "lrd",
      key: "lrd",
      width: 200,
      render: (lrd: string) => {
        return lrd ? convertDateInReadableFormat(lrd) : "-";
      },
    },
    {
      title: "Site Number",
      dataIndex: "site_number",
      key: "site_number",
      width: 200,
      render: (data: any) => {
        return data || "-";
      },
    },
    {
      title: "Study Number",
      dataIndex: "studyNumber",
      key: "studyNumber",
      render: (data: any) => {
        return data || "-";
      },
    },
    {
      title: "Acknowledged",
      dataIndex: "is_acknowledged",
      key: "is_acknowledged",
      render: (isAcknowledged: boolean) => {
        return (
          <Tag color={textAndColor[isAcknowledged ? 1 : 0].color}>
            {isAcknowledged ? "Yes" : "No"}
          </Tag>
        );
      },
    },
    
    {
      title: "Acknowledgement Date",
      dataIndex: "acknowledgedOn",
      key: "acknowledgedOn",
      render: (date: string) => {
        return date ? date : "-";
      },
    },
    {
      title: "EC/IRB Notified",
      dataIndex: "is_irb_notified",
      key: "is_irb_notified",
      render: (is_irb_notified: boolean, record: any) => {
        if (auth.getUsersPrivileges().includes("19")) {
          return (
            <Space>
              <Tag>N/A</Tag>
            </Space>
          );
        }
        let { reason, irb_notified_on } = record;
        const reasonText =
          irb_notified_on !== null && is_irb_notified === false ? reason : null;
        return (
          <Space>
            <Tag color={textAndColor[is_irb_notified ? 1 : 0].color}>
              {is_irb_notified ? "Yes" : "No"}
            </Tag>
            {reasonText !== null && (
              <Tooltip title={record?.reason}>
                <InfoCircleOutlined />
              </Tooltip>
            )}
          </Space>
        );
      },
    },
    
    {
      title: "USDE ID",
      dataIndex: "reportID",
      key: "reportID",
      render: (data: any) => {
        return data || "-";
      },
    },
  ];

export const  allNotificationsColumns = [
  ...baseColumns.slice(0, -1),
  {
    title: "Action",
    dataIndex: "token",
    key: "action",
    render: (token: string,row:any) => {
     
      const text = "View";
      return <Link to={`/notification/${token}/${row?.site_number}`}>{text}</Link>;
    },
  },
  baseColumns[baseColumns.length - 1],
  
]

export const pendingNotificationsColumns = [
  ...baseColumns.slice(0, -1),
 {
    title: "Action",
    dataIndex: "token",
    key: "action",
    render: (token: string,row:any) => {
     
      const text =  "Acknowledge/Notify";
      return <Link to={`/notification/${token}/${row?.site_number}`}>{text}</Link>;
    },
  },
  baseColumns[baseColumns.length - 1]
]