/* eslint-disable react-hooks/exhaustive-deps */
import "./Styles.css";
import { Table, Button, Select, Space, Tag, Badge } from "antd";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { UniviewService } from "../../Classes/Uniview";
import { ReNotification } from "../ReNotification";
import { headerOptions, headerStyles } from "./Constant";
import moment from "moment";
import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { isDifferent, modifyData } from "../../Methods/Methods";
import { SecureDataService } from "../../Classes/SecureData";
import { ArrayToCSV, getUrlParamString } from "./Helper";
import {
  IHeaderButtons,
  IHeaderDropdowns,
  IHeaderTags,
  IProps,
  IUniView,
  IUniviewOption,
} from "./Interface";
import { cloneDeep } from "lodash";

export default function  ReTable(props: IProps) {
  const {
    columns,
    data,
    loading,
    showHeader,
    name,
    title,
    headerTags,
    headerButtons,
    headerDropdowns,
    bordered,
    pagination,
    rowSelection,
    scroll,
    className,
    styles,
    columnOptions,
    expandable,
    showTableStats,
    uniView,
    exportOption,
    refresh,
    preventInitialLoad,
    dataModification,
    selectAllOnlyCurrentPage,
    getfilterFromTable 
  } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const urlQuery = new URLSearchParams(location.search);
  const [newColumns, setNewColumns] = useState<any>([]);
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [newData, setNewData] = useState<any>([]);
  const [isCsvBtnLoading, setIsCsvLoading] = useState(false);
  const [tableHeight, setTableHeight] = useState<number>(400);
  const [paginationPageCount, setPaginationPageCount] = useState<number>(0);
  const [tableLoading, setTableLoading] = useState<boolean>(loading||true);
  const [
    initialGenerateColumnOptionsLoad,
    setInitialGenerateColumnOptionsLoad,
  ] = useState<boolean>(true);
  const [filteritems , setFilterItem] = useState<any>([])
  const [tableCurrentChangesProps, setTableCurrentChangesProps] = useState<any>(
    {}
  );

  const adjustTableHeight = () => {
    const availableHeight = window.innerHeight - 300;
    setTableHeight(availableHeight);
  };

  const fetchDataFromUniview = async (uniViewProps: IUniView) => {
    let { uniView, viewName, apiUrl, filter, endPoint, option } = uniViewProps;
    const dataFetcher = new UniviewService(uniView, viewName, apiUrl);

    let newOrderBy;
    if (option?.orderBy) {
      newOrderBy = {
        [`${option?.orderBy?.key}`]: option?.orderBy?.order,
      };
    }

    const res = await dataFetcher.fetchData(
      filter || {},
      { ...option, orderBy: newOrderBy || {} } || {},
      endPoint
    );
    if (res.status) {
      return res?.data;
    }
  };
  const fetchTableDataFromUniview = async (
    uniViewProps: IUniView,
    tableChangesProps: any
  ) => {
    setTableLoading(true);
    let { viewName, apiUrl, filter, endPoint, option, extra } = uniViewProps;
    const dataFetcher = new UniviewService(
      uniViewProps?.uniView,
      viewName,
      apiUrl
    );

    // creating new option object
    let newOption: any = {
      offset: (tableChangesProps?.pagination?.current - 1) * tableChangesProps?.pagination?.pageSize || 0,
    };
    if (option?.orderBy) {
      newOption["orderBy"] = {
        [`${option?.orderBy?.key}`]: option?.orderBy?.order,
      };
    }

    newOption.limit = tableChangesProps?.pagination?.pageSize || uniViewProps?.option?.limit || 10;

    // creating filters for uniview
    let newFilters = {
      ...uniView?.filter,
    };
    if (filter) {
      newFilters = { ...filter, ...newFilters };
    }
    // when page refresh and url has query
    if (tableChangesProps) {
      newFilters = {
        ...createFilterForUniview(tableChangesProps?.filters),
        ...newFilters,
      };
    }

    const res = await dataFetcher.fetchData(
      newFilters,
      newOption,
      endPoint,
      extra
    );
    if (res?.status) {
      let newData = res.data;
      if (dataModification) {
        newData = modifyData(
          res.data,
          dataModification?.undefinedValuesTo,
          dataModification?.nullValuesTo,
          dataModification?.emptyString
        );
      }
      if (uniViewProps?.returnResponse) {
        uniViewProps.returnResponse({
          ...res,
          data: newData,
        });
      } else {
        setNewData(newData);
      }

      setTableLoading(false);
      setPaginationPageCount(res.count);
    } else {
      setTableLoading(false);
    }
  };

  const handleTableSingleRowSelect = (
    record: any,
    selected: boolean,
    selectedRows: any[],
    nativeEvent: any
  ) => {
    const selectedRowsCount = rowSelection?.setSelectedRowKeys?.length || 0;
    if (selectedRowsCount > 1000) {
      ReNotification({
        header: "Too Many Rows Selection Attempted",
        description: "Click the Export button to download all data.",
        type: "error",
      });
      return
    }
    const key = rowSelection?.rowKey ? rowSelection?.rowKey : "id";
    const storeRowType = rowSelection?.selectRowtype;

    if (selected) {
      rowSelection?.setSelectedRowKeys([
        ...rowSelection.selectedRowKeys,
        record[`${key}`],
      ]);
      if (
        storeRowType === "allData" &&
        rowSelection?.selectedRowValues &&
        rowSelection?.setSelectedRowValues
      ) {
        rowSelection?.setSelectedRowValues([
          ...rowSelection.selectedRowValues,
          record,
        ]);
      }
    } else {
      let newItems: any = [];
      rowSelection?.selectedRowKeys.forEach((item: any) => {
        if (item !== record[`${key}`]) {
          newItems.push(item);
        }
      });
      rowSelection?.setSelectedRowKeys(newItems);
      newItems = [];
      if (
        storeRowType === "allData" &&
        rowSelection?.selectedRowValues &&
        rowSelection?.setSelectedRowValues
      ) {
        rowSelection?.selectedRowValues.forEach((item: any) => {
          if (item[`${key}`] !== record[`${key}`]) {
            newItems.push(item);
          }
        });
        rowSelection?.setSelectedRowValues(newItems);
      }
    }
  };

  const handleTableAllRowSelect = async (
    selected: boolean,
    selectedRows: any,
    changeRows: any
  ) => {

    if (paginationPageCount > 1000) {
      ReNotification({
        header: "Too Many Rows Selected",
        description: "Click the Export button to download all data.",
        type: "error",
      });
      return
    }
    const key = rowSelection?.rowKey ? rowSelection?.rowKey : "id";
    const storeRowType = rowSelection?.selectRowtype;

    if (selected) {
      if (props.selectAllOnlyCurrentPage) { 
        rowSelection?.setSelectedRowKeys(
          [...rowSelection.selectedRowKeys, ...changeRows.map((row: any) => row[`${key}`])] 
        );
        if (storeRowType === "allData" && rowSelection?.setSelectedRowValues) {
          rowSelection?.setSelectedRowValues([...(rowSelection.selectedRowValues || []), ...changeRows]);
        }
      }  
      else if (uniView) {
        delete uniView?.option?.limit;
        let allTableData = [];
        if(paginationPageCount>10){
          allTableData = await fetchDataFromUniview({
            ...uniView,
            filter: createFilterForUniview(tableCurrentChangesProps?.filters),
            option: {
              ...uniView?.option,
              selectFields: storeRowType === "allData" ? [] : [`${key}`],
            },
          });
        } 
        else {
          allTableData = newData;
        }
        rowSelection?.setSelectedRowKeys(
          allTableData.map((ele: any) => ele[`${key}`])
        );

        if (storeRowType === "allData" && rowSelection?.setSelectedRowValues) {
          rowSelection?.setSelectedRowValues(allTableData);
        }
      } else {
        rowSelection?.setSelectedRowKeys(
          newData.map((row: any) => row[`${key}`])
        );
        if (storeRowType === "allData" && rowSelection?.setSelectedRowValues) {
          rowSelection?.setSelectedRowValues(newData);
        }
      }
    } else {
      if (props.selectAllOnlyCurrentPage) {
        const currentKeys = changeRows.map((row: any) => row[`${key}`]);
       
        const newSelectedKeys = Array.isArray(rowSelection?.selectedRowKeys) ?
          ((rowSelection?.selectedRowKeys || []) as number[]).filter(
            (key: any) => !currentKeys.includes(key)
          ) : [];
        rowSelection?.setSelectedRowKeys(newSelectedKeys);
      
        if (storeRowType === "allData" && rowSelection?.setSelectedRowValues) {
          
          rowSelection?.setSelectedRowValues(
            ((rowSelection?.selectedRowValues || []) as any[]).filter(
              (row: any) => !changeRows.includes(row)
            )
          );
        }
      } else {
        rowSelection?.setSelectedRowKeys([]);
        if (storeRowType === "allData" && rowSelection?.setSelectedRowValues) {
          rowSelection?.setSelectedRowValues([]);
        }
      }
    }
  };

  const getFilterOptionItems = (tableData: object[], key: string) => {
    let uniqueItems: object[] = [];
    tableData.forEach((data: any) => {
      if (data.hasOwnProperty(key)) {
        const newItem = {
          text: data[key] ? data[key] : "-",
          value: data[key],
        };
        const isAlreadyPresent = uniqueItems.some((item: any) => {
          return item.text === newItem.text && item.value === newItem.value;
        });
        if (!isAlreadyPresent) {
          uniqueItems.push(newItem);
        }
      }
    });
    return uniqueItems;
  };

  const getFilterOptionItemsFromUniview = async (
    tableName: string,
    viewName: string,
    apiUrl: string,
    extra: any,
    columnKey: string,
    filters: any,
    elementItem?:any
  ) => {
    if(!props.selectAllOnlyCurrentPage){
      rowSelection?.setSelectedRowKeys([]);
    }
    let option: any = {
      orderBy: {},
      selectFields: [columnKey],
      groupBy: [columnKey],
      filters: true,
    };

    let newFilters = {
      ...createFilterForUniview(filters),
    };
    if (uniView?.filter && Object.keys(uniView?.filter)) {
      newFilters = { ...newFilters, ...uniView?.filter };
    }


    const dataFetcher = new UniviewService(tableName, viewName, apiUrl);
    // Using same fetch function to fetch data specific to the group by

    const res = await dataFetcher.fetchData(newFilters, option, "data", extra);
    if (res?.status) {
      // changing the filter object 
      //to make unique items
      return res.data.reduce((uniqueItems: any[], item: any) => {
        const isAlreadyPresent = uniqueItems.some((existingItem) => existingItem.value === item[columnKey]);
        const text = elementItem
        ? elementItem.items.find(
            (option:any) => option.value === item[columnKey]
          )?.text
        : item[columnKey];

      if (!isAlreadyPresent) {
        uniqueItems.push({ text, value: item[columnKey] });
      }
        return uniqueItems;
      }, []);
    } else {
      return [];
    }
  };

  function determineColumnDataType(columnData: any[]): string {
    if (columnData.length === 0) {
      return "unknown"; 
    }
  
    for (const value of columnData) {
      if (value === null || value === undefined || value ==="") {
        continue; 
      }
  
      switch (typeof value) {
        case "string":
          const dateFormats = ["YYYY-MM-DDTHH:mm:ss.SSSZ", "DD-MMM-YYYY","YYYY-MM-DD"];
            for (const dateFormat of dateFormats) {
                if (moment(value, dateFormat, true).isValid()) {
                    return "date";
                }
            }
            return "string"; 
        case "number":
          return "number";
        case "boolean":
          return "boolean";
        default:
          return "unknown"; 
      }
    }
  
    return "unknown";
  }

  function generateColumnSortingOption(cols: any, tableData: any) {
    return cols.map((col: any) => {
      if (tableData.length > 0) {
        let newCol;
        const columnKey = col.key;
        if (
          columnOptions?.sorting &&
          columnOptions?.sorting?.columns.includes(columnKey)
        ) {
          const columnData = tableData.map((row: any) => row[columnKey]);
          const dataType = determineColumnDataType(columnData);
          newCol = {
            ...col,
            sorter: (a: any, b: any) => {
              const aVal = a[columnKey];
              const bVal = b[columnKey];
               
             
              if (aVal === '' || aVal === null || aVal === undefined || aVal === '-') {
                return 1; 
              } else if (bVal === '' || bVal === null || bVal === undefined || bVal === '-') {
                return -1; 
              }
              
              switch (dataType) {
                case "date":
                  return moment(aVal).unix() - moment(bVal).unix();
                case "string":
                  return naturalCompare(aVal, bVal);
                case "number":
                  return aVal - bVal;
                case "boolean":
                  return (aVal ? 1 : -1) - (bVal ? 1 : -1);
                default:
                  return 0;
              }
            },
          };
        } else return col;
        return newCol;
      } else {
        return col;
      }
    });
  }
  function naturalCompare(a: string, b: string): number {
    
    // Handle empty strings first
    a = typeof a === 'string' ? a.trim().toLowerCase() : String(a ?? '').trim().toLowerCase();
    b = typeof b === 'string' ? b.trim().toLowerCase() : String(b ?? '').trim().toLowerCase();

    // Extract prefix and numeric part
    const aPrefix = a.match(/^[a-z]+/i)?.[0] || '';
    const aNumeric = a.replace(/^[a-z]+/i, '').trim();
    const bPrefix = b.match(/^[a-z]+/i)?.[0] || '';
    const bNumeric = b.replace(/^[a-z]+/i, '').trim();

    // Compare prefixes
    if (aPrefix !== bPrefix) {
      return aPrefix.localeCompare(bPrefix);
    }

    // Compare numeric parts as strings
    if (aNumeric !== bNumeric) {
      return aNumeric.localeCompare(bNumeric);
    }

    // If prefixes and numeric parts are equal, compare the remaining parts
    return a.localeCompare(b);
  }  

  function getFilterOptionItemsFromObject(tableData: any[], columnKey: any) {

    let uniqueItems: { text: string; value: any }[] = [];
    tableData.forEach((data: any) => {
      if (data.hasOwnProperty(columnKey.key)) {
        const value = data[columnKey.key];
        const predefinedItem = columnKey.items.find((item: any) => item.value === value);
        const newItem = {
          text: predefinedItem ? predefinedItem.text : value ,
          value: value,
        };
        const isAlreadyPresent = uniqueItems.some((item) => {
          return item.text === newItem.text && item.value === newItem.value;
        });
        if (!isAlreadyPresent) {
          uniqueItems.push(newItem);
        }
      }
    });
   
    return uniqueItems;
  }
  async function generateColumnFilterOption(
    cols: any,
    filters?: any,
    sorter?: any
  ) {
    filters = filters
      ? filters
      : tableCurrentChangesProps?.filters
        ? tableCurrentChangesProps?.filters
        : {};
    sorter = sorter
      ? sorter
      : tableCurrentChangesProps?.sorter
        ? tableCurrentChangesProps?.sorter
        : {};
    
    const newCols = await Promise.all(
      cols.map(async (col: any) => {
        let newCol;
        let columnKey = col.key;
        let isFilterItemGiven = false;
        let filterItems;
        let isMapperIsGiven = false;
        let mapper: any;
        let elementItem : any
        // here we are check weather current column key is present or not in the requested columns array
        let isColumnKeyPresent = false;
        if (columnOptions?.filter) {
          if (columnOptions?.filter?.columns.includes(columnKey)) {
            isColumnKeyPresent = true;
          } else {
            columnOptions?.filter?.columns.forEach((element: any) => {
              if (typeof element === "object" && element.key === columnKey) {
                isColumnKeyPresent = true;
                if (Object.keys(element).includes("items")) {
                   // isFilterItemGiven = true;
                   // filterItems = element.items;
                   //Displaying only items that are in the table
                   // filterItems = getFilterOptionItemsFromObject(newData, element);
                   columnKey=element.key
                   
                   elementItem = element
                }

                if (Object.keys(element).includes("action")) {
                  isMapperIsGiven = true;
                  mapper = element.action;
                }
              }
            });
          }
        }

        if (isColumnKeyPresent) {
          if (uniView && !isFilterItemGiven) {
            // calling the uniview if its provided to get filter items
            filterItems = await getFilterOptionItemsFromUniview(
              uniView.uniView,
              uniView.viewName,
              uniView.apiUrl,
              uniView.extra,
              columnKey,
              filters,
             elementItem
            );
          } else if (!isFilterItemGiven) {
            filterItems = getFilterOptionItems(newData, columnKey);
          }

          // mapping the filter when action is present
          if (isMapperIsGiven) {
            const mappedItems = filterItems.map((fil: any) => {
              return {
                text: mapper(fil.value),
                value: fil.value,
              };
            });
            filterItems = mappedItems;
          }

          newCol = {
            ...col,
            filterSearch: columnOptions?.filter?.filterSearch,
            filters: filterItems,

            onFilter: (value: string | number | boolean, row: any) => {

              const typeOfValue = typeof value;
              const typeOfRowValue = typeof row[columnKey];
              if (typeOfValue === "string" && typeOfRowValue === "string") {
                // Handle date formats
                const dateFormats = ["YYYY-MM-DDTHH:mm:ss.SSSZ", "DD-MMM-YYYY", "YYYY-MM-DD"];
                for (const dateFormat of dateFormats) {
                  if (moment(value as string, dateFormat, true).isValid()) {
                    // If 'value' is a valid date, try parsing 'row[columnKey]' with all formats
                    for (const rowDateFormat of dateFormats) {
                      if (moment(row[columnKey] as string, rowDateFormat, true).isValid()) {
                        return moment(row[columnKey] as string, rowDateFormat).isSame(moment(value as string, dateFormat), 'day');
                      }
                    }
                  }
                }
                return (row[columnKey] as string)?.includes(value as string);
              }else if (Array.isArray(row[columnKey])) {
                return row[columnKey].length === Number(value);
              }
               else {
                return String(row[columnKey]).includes(String(value));
              }
            },
          };
          // if (sorter.field === columnKey && sorter.order) {
          //   newCol = {
          //     ...newCol,
          //     defaultSortOrder: sorter.order,
          //   };
          // }
          // if (filters[columnKey] && filters[columnKey]?.length > 0) {
          //   newCol = {
          //     ...newCol,
          //     filteredValue: filters[columnKey],
          //   };
          // }
        } else return col;
        return newCol;
      })
    );
    
    setNewColumns(newCols);
  }


  const formatTableData = (filterdata: any, columnData: any, i: number) => {
    if (columnData?.render) {
      const returnValue = columnData?.render(
        filterdata[i][columnData.dataIndex],
        filterdata[i]
      );
      return React.isValidElement(returnValue)
        ? filterdata[i][columnData.dataIndex]
        : returnValue;
    } else {
      return filterdata[i][columnData.dataIndex] || "";
    }
  };

  const generateCSVWithoutUniview = () => {
    const filterdata = rowSelection?.selectedRowKeys
      ? rowSelection?.selectedRowKeys.length > 0
        ? newData.filter((ele: any) => {
          return rowSelection?.selectedRowKeys.includes(
            ele[rowSelection.rowKey || "id"] as never
          );
        })
        : newData
      : newData;

    let finalData: string[][] = [],
      existingColumnKey: string[] = [],
      finalColumns: string[] = [];
    let temp: number = 0;
    for (let idx = 0; idx < filterdata.length; idx++) {
      newColumns.forEach((columnData: any) => {
        if (
          columnData?.dataIndex &&
          !existingColumnKey.includes(columnData?.dataIndex)
        ) {
          existingColumnKey.push(columnData?.dataIndex);
          idx === 0 && finalColumns.push(columnData?.title);
          if (!finalData?.[idx]) {
            finalData.push([formatTableData(filterdata, columnData, idx)]);
          } else {
            finalData[idx][temp] = formatTableData(filterdata, columnData, idx);
          }
          temp++;
        }
      });
      temp = 0;
      existingColumnKey = [""];
    }

    const fileName = name || "Table Data";
    ArrayToCSV(
      finalColumns,
      finalData,
      `${fileName + moment().format(" DD-MM-YY hh-mm-ss A")}.csv`
    );
    ReNotification({
      header: "Downloaded Successfully",
      description: "File Download Successful!",
      type: "success",
    });
  };

  const generateCSV = async (fileName: string | undefined) => {
    if (!uniView && !exportOption?.csv?.params?.uniView) {
      generateCSVWithoutUniview();
      return;
    }

    setIsCsvLoading(true);
    if (pagination?.total && pagination?.total > 300) {
      ReNotification({
        header: "Downloading......",
        description: "Large file size, expect longer download time.",
        type: "info",
      });
    }
    const dataFetcher = new UniviewService(
      uniView?.uniView || exportOption?.csv?.params?.uniView || "",
      uniView?.viewName || exportOption?.csv?.params?.viewName || "",
      uniView?.apiUrl || exportOption?.csv?.params?.apiUrl || ""
    );
    const orderByKey = uniView?.option?.orderBy?.key || exportOption?.csv?.params?.option?.orderBy?.key || "";
    const res = await dataFetcher.generateCSV(
      exportOption?.csv?.selectRowtype === "id" ||
        !exportOption?.csv?.selectRowtype
        ? rowSelection?.selectedRowKeys
        : rowSelection?.selectedRowValues,
      {
        orderBy: { [orderByKey]: uniView?.option?.orderBy?.order || exportOption?.csv?.params?.option?.orderBy?.order },
      },
      uniView?.filter || exportOption?.csv?.params?.filter || {},
      fileName ? fileName : exportOption?.csv?.fileName || "Data",
      rowSelection?.rowKey,
      exportOption?.csv?.columns,
      exportOption?.csv?.endPoint,
      exportOption?.csv?.params?.extra
    );
    setIsCsvLoading(false);
    if (res) {
      ReNotification({
        header: "Downloaded Successfully.",
        description: "File Download Successful! ",
        type: "success",
      });
      rowSelection?.setSelectedRowKeys([]);
      if (rowSelection?.setSelectedRowValues) {
        rowSelection?.setSelectedRowValues([]);
      }
    } else {
      ReNotification({
        header: "File Download Failed",
        description: "Please Try Again Later.",
        type: "error",
      });
    }
  };

  function isValidJSON(data: any) {
    try {
      return typeof data === "object" && !Array.isArray(data);
    } catch (e) {
      return false;
    }
  }

  function createFilterForUniview(tableFilters: any) {
    let newFilter: any = {};
    if (tableFilters) {
      Object.keys(tableFilters)?.forEach((key) => {
        if (!tableFilters[key]) return newFilter;
        if (isValidJSON(tableFilters[key])) {
          newFilter[`${key}`] = tableFilters[key];
        }
        else {
          newFilter[`${key}`] = {};
          newFilter[`${key}`] = {
            "=": tableFilters[key]
              .map((value: any) => (value === null ? "null" : value?.toString()?.replace(/,/g, '##')))
              .join(","),
          };
        }
      })
    }
    return newFilter;
  }

  const handleTableChange = async (
    pagination: any,
    filters: any,
    sorter: any
  ) => {
    setTableLoading(true)
    if (uniView) {
      
      filters = { ...tableCurrentChangesProps?.filters, ...filters }
      const allProps = { pagination, sorter, filters };
      setUrlParams(pagination, filters, sorter);
      const urlSearchParams = "?" + decodeURIComponent(urlQuery.toString());
      setTableCurrentChangesProps(allProps);
      navigate(`${location.pathname}${urlSearchParams}`);
    
      let option: IUniviewOption = {};
      // creating sorting props
      if (sorter?.column !== undefined) {
        option["orderBy"] = {
          key: sorter?.column?.key,
          order: sorter?.order === "ascend" ? "ASC" : "DESC",
        };
      }

      // getting new filters items and setting it in the existing columns when filter triggers
      if (Object.keys(filters).length && Object.keys(filters)[0] !== null && isDifferent(tableCurrentChangesProps?.filters, filters)) {
        await generateColumnFilterOption(newColumns, filters, sorter);
      }
      
      fetchTableDataFromUniview(
        {
          ...uniView,
          filter: createFilterForUniview(filters),
          option: {
            ...uniView?.option,
            ...option,
          },
        },
        allProps
      );
    }
    setFilterItem(filters)
    if(getfilterFromTable){
      getfilterFromTable(filters)
    }
    setTableLoading(false)
  }

  const setUrlParams = (pagination: any, filters: any, sorter: any) => {
    const secureDataService = new SecureDataService();
    const paginationLabel = `${name ? name?.replace(/\s+/g, "")?.toLocaleLowerCase() : ""
      }PaginationData`;
    const paginationData = secureDataService.encrypt({
      pagination,
      filters,
      sorter,
    });
    urlQuery.set(paginationLabel, paginationData);
  };

  // const getUrlParams = () => {
  //   const secureDataService = new SecureDataService();
  //   return JSON.parse(secureDataService.decrypt(getUrlParamString()));
  // };
  const getUrlParams = () => {
    const secureDataService = new SecureDataService();
    const decryptedString = secureDataService.decrypt(getUrlParamString());
    

    try {
        return JSON.parse(decryptedString);
    } catch (error) {
        console.error("Error parsing JSON:", error);
        return {}; // Return an empty object or handle the error as needed
    }
};

  // USE EFFECTS >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>


  function filterData(data: any[], filteritems: any) {
    return data.filter((row: any) => {
      for (const filterKey in filteritems) {
        const filterValues = filteritems[filterKey];
        
        if (Array.isArray(filterValues)) {
          // Check if any filter value matches the row value exactly
          if (!filterValues.some((val: any) => val === row[filterKey])) {
            return false; // Exclude the row if no filter value matches exactly
          }
        }
      }
      return true; // Include the row if it matches all filters
    });
  }


  useEffect(() => {
    if(!uniView && filteritems && data){
      setNewData(filterData(data,filteritems))
      return;
    }
    if (data) {
      setNewData(data);
    }
    
  }, [data,filteritems]);

  useEffect(() => {
    let count;
    if (uniView && pagination?.total) {
      count = pagination?.total;
    } else if (!uniView) {
      if (pagination?.total || pagination?.total === 0) {
        count = pagination?.total;
      } else {
        count = newData.length;
      }
    }
    setPaginationPageCount(count);
  }, [pagination?.total]);

  useEffect(() => {
    if (newData?.length && initialGenerateColumnOptionsLoad) {
      const func = async () => {
        setTableLoading(true);
        let newCols;
        newCols = generateColumnSortingOption(columns, newData);
        
        await generateColumnFilterOption(newCols);
        
        setInitialGenerateColumnOptionsLoad(false);
        setTableLoading(false);
      };
      func();
    }
  }, [newData]);

  useEffect(() => {
    let allProps;
    const tablePaginationName = `${name ? name?.replace(/\s+/g, "")?.toLocaleLowerCase() : ""}PaginationData`;
    if (window.location.search.includes(tablePaginationName)) {
      allProps = getUrlParams();
      setTableCurrentChangesProps(allProps);
    }
    if (uniView && !preventInitialLoad) {
      fetchTableDataFromUniview(uniView, allProps);
    }
  }, [refresh, preventInitialLoad]);

  useEffect(() => {
    if (loading !== undefined) {
      setTableLoading(loading);
    }
  }, [loading]);

  useEffect(() => {
    generateColumnFilterOption(newColumns, {}, {});
  }, [name])

  useLayoutEffect(() => {
    adjustTableHeight();
    adjustTableHeight();
    window.addEventListener("resize", adjustTableHeight);
    return () => {
      window.removeEventListener("resize", adjustTableHeight);
    };
  }, []);


  // const updatedColumns = useMemo(() => {
  //   let filteredNewData : any[];
  //   if(!uniView && filteritems && newData){
  //     filteredNewData = filterData(newData,filteritems)

  //   }
     
  //   return newColumns.map((column: any) => {
  //     if (column.filters) {
  //       const filterOrder = (filteredNewData||newData).map((col:any) => col[column.dataIndex]);
  //       const orderedFilters = filterOrder
  //       .map((value:any) => column.filters!.find((filter:any) =>{
          
  //         if (typeof value === 'string' && typeof filter.value === 'string') {
  //           const dateFormats = ["YYYY-MM-DDTHH:mm:ss.SSSZ", "DD-MMM-YYYY", "YYYY-MM-DD"];
  //           for (const dateFormat of dateFormats) {
  //             if (moment(value, dateFormat, true).isValid()) {
  //               for (const filterDateFormat of dateFormats) {
  //                 if (moment(filter.value, filterDateFormat, true).isValid()) {
                   
  //                   return moment(value, dateFormat).isSame(moment(filter.value, filterDateFormat), 'day');
  //                 }
  //               }
  //             }
  //           }
  //         }
  //         return filter.value === (Array.isArray(value) ? value.length : value);
  //       }))
  //       .filter((filter:any) => filter !== undefined);
  //         const isRemainingWanted = Object.values(filteritems).some(value => Array.isArray(value)) || false
  //         const remainingFilters =   isRemainingWanted ? [] :column.filters.filter(
  //           (filter:any) => !orderedFilters.some((orderedFilter:any) => {
  //             if (typeof orderedFilter.value === 'string' && typeof filter.value === 'string') {
  //               const dateFormats = ["YYYY-MM-DDTHH:mm:ss.SSSZ", "DD-MMM-YYYY", "YYYY-MM-DD"];
  //               for (const dateFormat of dateFormats) {
  //                 if (moment(orderedFilter.value, dateFormat, true).isValid() && moment(filter.value, dateFormat, true).isValid()) {
  //                   return moment(orderedFilter.value, dateFormat).isSame(moment(filter.value, dateFormat), 'day');
  //                 }
  //               }
  //             }
  //             return orderedFilter.value === filter.value;
  //           })
  //         );
    
  //       const uniqueFilters = new Map();
  //       [...orderedFilters, ...remainingFilters].forEach(filter => {
  //         uniqueFilters.set(filter.text, filter);
  //       });
        
  //       column.filters = Array.from(uniqueFilters.values())?.filter((filter:any) => filter.value !== undefined && filter.value !== "" && filter.value !== null);
  //     }
  //     return column;
  //   });
  // }, [newColumns,newData,filteritems]);

  const updatedColumns = useMemo(() => {
    let filteredNewData : any[];
    if(!uniView && filteritems && newData){
      filteredNewData = filterData(newData,filteritems)

    }
     
    return newColumns.map((column: any) => {
      if (column.filters) {
        const uniqueFilters = new Set();
        column.filters.forEach((filter:any) => {
          if (filter.value !== null && filter.value !== undefined && filter.value !== "" && filter.value !== "null" && filter.text !== undefined) {
            uniqueFilters.add(filter.text);
          }
        });
        column.filters = Array.from(uniqueFilters).sort((a:any, b:any) => {
         
          if (typeof a === 'number' && typeof b === 'number') {
            return a - b; 
          } else if (typeof a === 'string' && typeof b === 'string') {
            const dateFormats = ["YYYY-MM-DDTHH:mm:ss.SSSZ", "DD-MMM-YYYY","YYYY-MM-DD"];
            for (const dateFormat of dateFormats) {
                if (moment(a, dateFormat, true).isValid() && moment(b, dateFormat, true).isValid()) {
                    return moment(a).unix() - moment(b).unix(); 
                }
            }
            return a.localeCompare(b); 
          } else {
            return 0; 
          }
        }).map(value => {
          const filter = column.filters.find((f :any)=> f.text === value);
          return filter;
        });
      }
      return column;
    });
  }, [newColumns,newData,filteritems]);


  useEffect( ()=>{
   let newCols = generateColumnSortingOption(columns, newData);
   const fn= async()=>{
  
    await generateColumnFilterOption(newCols);
   }
       fn() 
    setNewColumns(updatedColumns);
  },[newData,filteritems])

// useEffect(()=>{
// if(!uniView && filteritems && newData){

// }
// },[])
  
  // COMPONENT VARIABLES >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  const tableTitle = (
    <>
      <div style={headerStyles}>
        <div>
          {title && <h2>{title}</h2>}
          {headerTags?.map((tag: IHeaderTags) => {
            const { color, title } = tag;
            return (
              <Tag key={title} color={color}>
                {title}
              </Tag>
            );
          })}
        </div>
        <div style={headerOptions}>
          {headerDropdowns?.map((select: IHeaderDropdowns, index: number) => {
            const { styles, setSelectedValue, hide } = select;
            return (
              !hide && (
                <Select
                  key={index}
                  style={styles}
                  {...select}
                  onChange={(value: string | number, option: any) => {
                    setSelectedValue(value);
                  }}
                />
              )
            );
          })}
          {headerButtons?.map((btn: IHeaderButtons, index: number) => {
            const { title, visibility } = btn;
            return (
              (visibility || visibility === undefined) && (
                <Button key={index} {...btn}>
                  {title}
                </Button>
              )
            );
          })}
          {exportOption?.pdf && !exportOption?.pdf?.hide && (
            <Button
              onClick={exportOption.pdf.onClick}
              type="primary"
              loading={exportOption.pdf?.loading}
              size="middle"
              icon={<FilePdfOutlined />}
              disabled={exportOption.pdf.disabled || !newData.length}
            >
              Export to PDF
            </Button>
          )}
          {exportOption?.csv && !exportOption?.csv?.hide && (
            <Button
              onClick={() => {
                if (rowSelection?.selectedRowKeys.length === 0) {
                  ReNotification({
                    header: "No Rows Selected",
                    description: "Please select at least one row to export.",
                    type: "warning",
                  });
                  return;
                }
                generateCSV(name);
              }}
              type="primary"
              loading={isCsvBtnLoading}
              size="middle"
              icon={<FileExcelOutlined />}
              disabled={exportOption.csv.disabled || !newData.length}
            >
              Export to CSV
            </Button>
          )}
        </div>
      </div>
      {showTableStats && (
        <Space direction="horizontal">
          <span>
            <strong>Total Rows: </strong>
            <Badge
              count={paginationPageCount || 0}
              showZero
              overflowCount={9999}
            />
          </span>
          {rowSelection && (
            <span>
              <strong>Selected Rows: </strong>
              <Badge
                count={rowSelection?.selectedRowKeys.length}
                showZero
                overflowCount={9999}
              />
            </span>
          )}
        </Space>
      )}
    </>
  );
 
  const componentProps =  {
       
      // key: name,
      columns: columnOptions ? newColumns : columns,
      onChange: handleTableChange,
      loading: tableLoading,
      rowKey: rowSelection?.rowKey,
      // rowKey: "id",
      showHeader: showHeader,
      sortDirections: columnOptions?.sorting?.sortingDirections,
      bordered: bordered,
      pagination: {
        ...pagination,
        current:
          tableCurrentChangesProps?.pagination?.current || pagination?.current,
        total: paginationPageCount,
        hideOnSinglePage: !uniView?newData?.length<11: paginationPageCount<11,
        showSizeChanger: !uniView?newData?.length>10:paginationPageCount>10,
        pageSizeOptions: pagination?.pageSizeOptions
          ? pagination?.pageSizeOptions
          : [10, 20, 50, 100],
      },
      scroll: {
        ...scroll,
        y: tableHeight,
      },
      expandable: {
        ...expandable,
        expandedRowKeys: expandedRows,
        onExpandedRowsChange: (expandedRows: any) => {
          setExpandedRows(expandedRows);
        },
      },
      rowSelection: rowSelection
        ? {
          ...rowSelection,
          onSelect: handleTableSingleRowSelect,
          onSelectAll: handleTableAllRowSelect,
        }
        : undefined,
      title: () => {
        return tableTitle;
      },
    };

  // useEffect( ()=>{
  //   let newCols = generateColumnSortingOption(columns, newData);
  //   const fn= async()=>{
   
  //    await generateColumnFilterOption(newCols);
  //   }
  //       fn() 
  //    setNewColumns(updatedColumns);
  //  },[newData,filteritems])

  return (
    <div className="table">
      <Table style={styles} className={className} dataSource={newData}  {...componentProps} />
    </div>
  );
}