import { BaseAPI } from "./Common";

class Report extends BaseAPI {
  create(reportObj: any, matchingRules: any) {
    let payload = {
      type: reportObj.type,
      typeValue: reportObj.typeValue,
      org: reportObj.org,
      product: reportObj.product,
      studyID: reportObj.studyID,
      studyTitle: reportObj.studyTitle,
      sourceSiteID: reportObj.sourceSiteID,
      sourceSiteName: reportObj.sourceSiteName,
      safetyDatabaseReportNumber: reportObj.safetyDatabaseReportNumber,
      attachments: reportObj.attachments || [],
      meta: reportObj.meta || {},
      tags: reportObj.tags || [],
      lrd: reportObj.lrd,
      serialNumber: reportObj.serialNumber,
      matchingRules: matchingRules,
      country: reportObj.country,
      creationType: reportObj.creationType
    };
    return this.makeRequest("create", "post", payload).then((response) => {
      return response;
    });
  }

  update(
    reportId: string | undefined,
    payload: any
  ) {
    return this.makeRequest(`${reportId}`, "patch", payload).then(
      (response) => {
        return response;
      }
    );
  }

  findAll() {
    return this.makeRequest("findAll", "get", {}).then((response) => {
      return response;
    });
  }

  findOne(id: string | undefined) {
    return this.makeRequest("findOne", "get", { id: id }).then((response) => {
      return response;
    });
  }

  fetchReportData() {
    return this.makeRequest("details", "get").then((response) => {
      return response;
    });
  }

  fetchSitesData() {
    return this.makeRequest("sites", "get").then((response) => {
      return response;
    });
  }

  generate(queryObj: {
    org: string;
    lrdRange: object;
    title: string;
    product: string;
    study_id: string;
    source_site_id: string;
    source_site_name?: string;
    sortOrder: number;
  }) {
    return this.makeRequest("generate", "post", queryObj).then((response) => {
      return response;
    });
  }

  find(queryObj: {
    org?: string;
    lrdRange?: object;
    title?: string;
    product?: string;
    study_id?: string;
    source_site_id?: string;
    source_site_name?: string;
    sortOrder: number;
  }) {
    return this.makeRequest("find", "get", queryObj).then((response) => {
      return response;
    });
  }

  findVersions(queryObj: { org: string; serialNumber: string }) {
    return this.makeRequest("findVersions", "get", queryObj).then(
      (response) => {
        return response;
      }
    );
  }

  async deleteAttachment(id: string, reportId: any) {
    return this.makeRequest(`attachment/${id}`, "delete", reportId).then(
      (response) => {
        return response;
      }
    );
  }

  async addAttachment(reportId: string, attachment: any) {
    return this.makeRequest(`attachment/${reportId}`, "patch", attachment).then(
      (response) => {
        return response;
      }
    );
  }
}

export const report = new Report("reports");
