import ReusableForm from '../../unity-ui-common/Components/ReForm';
import ReInput from '../../unity-ui-common/Components/ReFormFields/ReInput';
import ReSelect from '../../unity-ui-common/Components/ReFormFields/ReSelect';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CopyOutlined, EditOutlined, ExpandOutlined } from '@ant-design/icons';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Button, Collapse, Form, Switch, Upload } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { template } from '../../API/Template';
import { useNavigate, useParams } from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
  company,
  mapping,
  modeTemplateOptions,
  partners,
  triggerTemplateOptions,
  typeTemplateOptionsData,
} from './constants';
import { ReNotification } from '../../unity-ui-common/Components/ReNotification';
// import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import * as Styles from './ViewTemplateStyle';
import { getApiUrl } from '../../API/Utils';
import DocEditor from '../TemplateManagement/DocEditor';

const props = {
  headers: {
    authorization: 'authorization-text',
  },
  action: `${getApiUrl()}template/upload`,
  name: 'file',
};

function ViewTemplate() {
  const [form] = Form.useForm();
  const [queryForm] = Form.useForm();
  const { id: templateId } = useParams();
  const navigate = useNavigate();

  const { Panel } = Collapse;

  const [templateData, setTemplateData]: any = useState();
  const [customQuery, setCustomQuery] = useState<string>('');
  const [fieldName, setFieldName] = useState<string>('');
  const [typeTemplateOptions, setTypeTemplateOptions] = useState<any>([]);
  const [uploadedfile, setUploadedfile] = useState<any>('https://52.90.205.92:9980/browser/ca2ed20/cool.html?WOPISrc=http%3A%2F%2Funitydx-stage-v2.soterius.xyz%2Fapi%2Fwopi%2Ffiles%2FAbbreviated%20Narrative%20Template_20241018T085853015Z.docx&access_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmaWxlTmFtZSI6IkFiYnJldmlhdGVkIE5hcnJhdGl2ZSBUZW1wbGF0ZV8yMDI0MTAxOFQwODU4NTMwMTVaLmRvY3giLCJ1c2VySWQiOiIzNDM0IiwiaWF0IjoxNzMxMDcxMzk5LCJleHAiOjE3MzExNTc3OTl9.gzlwEjnVjkBZeWzhAAioFogDbm7fFiRQXhGMv6P4Gls');

  useEffect(() => {
    if (!templateId) return;
    onPageLoad();
  }, [templateId]);

  // const copyToClipBoard = async (text: string, title: string) => {
  //   if (!text || !title) return;
  //   const prevText = await navigator.clipboard.readText();
  //   if (prevText === text) return;
  //   await navigator.clipboard.writeText(text);
  //   ReNotification({
  //     type: 'info',
  //     description: `${title} is copied.`,
  //     duration: 5,
  //   });
  // };
  const copyToClipBoard = async (text: string, title: string) => {
    if (!text || !title) return;

    // Check if the clipboard API is available
    if (!navigator.clipboard) {
      alert('Clipboard API is not available in this environment.');
      return;
    }

    try {
      // Log the text being copied for debugging
      console.log('Copying to clipboard:', text);

      const prevText = await navigator.clipboard.readText();
      console.log('Previous clipboard text:', prevText);

      // Only copy if the text is different
      if (prevText === text) return;

      await navigator.clipboard.writeText(text);
      ReNotification({
        type: 'info',
        description: `${title} is copied.`,
        duration: 5,
      });
    } catch (error) {
      console.error('Failed to copy text to clipboard:', error);
      alert('Failed to copy text to clipboard. Please try again.');
    }
  };
  const onCopy = (title: string)=>{
    ReNotification({
      type: 'info',
      description: `${title} is copied.`,
      duration: 5,
    });
  }

  async function onPageLoad() {
    let id = templateId || '';
    if (!id || id === 'new-template') {
      setTemplateData({ status: true });
      return;
    } else if (id.includes('||')) {
      const splitArray = id.split('||');
      id = splitArray?.[1];
    }
    const templateRes = await template.getOne(id || '');
    templateRes.status = templateRes.status === 1 ? true : false;
    setTemplateData(templateRes);
    form.setFieldsValue({
      name: templateRes?.name,
      mode: templateRes?.mode,
      type: templateRes?.type,
      trigger: templateRes?.trigger,
      description: templateRes?.description,
      subject: templateRes?.subject,
    });
    console.log(templateRes?.name)
  }

  const handleFormChange = async (changeValue: any, allValues: any) => {
    setTemplateData((prevValue: any) => ({
      ...allValues,
      body: prevValue.body,
      status: prevValue.status,
    }));
  };

  const handleFormSubmit = async (values: any) => {
    values.body = templateData.body;
    values.status = templateData.status ? 1 : 0;
    let res;
    if (templateId?.includes('new-template')) {
      //   const formData = new FormData();
      //   formData.append('attachments', values.attachments.file);

      //   console.log("formData",formData)

      //   console.log("Values Form",values.attachments.file);
      //   const reader = new FileReader();

      //   reader.readAsArrayBuffer(values.attachments.file);
      //    reader.onload = (e) => {
      //   const binaryData = e.target.result;
      //   console.log("Binary data:", binaryData);
      // };

      // console.log("Values Form",values.attachments.file);

      res = await template.create(values);
    } else {
      res = await template.update(templateId || '', values);
    }
    ReNotification({
      type: res?.status ? 'success' : 'error',
      description: res?.message || 'Error In Template Processing',
    });
    if (res.status) navigate('/template-management');
  };

  const genarateCustomQuery = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    // console.log(name, value)
    if (name === 'query' && (!fieldName || fieldName.length === 0)) {
      setCustomQuery('');
      return;
    }

    setCustomQuery((prevValue) => {
      let result = '';
      let splitQuery: any = [];
      if (prevValue.includes('_query=')) {
        const trimmedString = prevValue.slice(2, -2);
        const parts = trimmedString.split('_query=');
        const key = parts[0].trim();
        const value = parts[1].trim().slice(1, -1);
        splitQuery = [key, value];
      }
      if (splitQuery.length === 2 && value) {
        const index = name === 'field' ? 0 : 1;
        splitQuery[index] = value;
        result = `{{${splitQuery[0]}_query="${splitQuery[1]}"}}`;
        index === 0 && setFieldName(result);
      } else if (name === 'field') {
        result = value ? `{{${value}}}` : '';
        setFieldName(result);
      } else if (name === 'query') {
        const trimmedString = prevValue.slice(2, -2);
        result = value
          ? `{{${trimmedString}_query="${value}}}"`
          : `{{${splitQuery[0]}}}`;
      }
      return result;
    });
  };

  return (
    <Styles.Container>
      <div className='mainBody'>
        <div className='headingContainer'>
          <h2 className='name'>
            {templateId?.includes('new-template')
              ? 'New Template'
              : 'Edit Template'}
          </h2>
          <div className='btnContainer'>
            <Switch
              checked={templateData?.status}
              checkedChildren='Active'
              unCheckedChildren='Inactive'
              onChange={(checked: boolean) => {
                setTemplateData((prevData: any) => ({
                  ...prevData,
                  status: checked ? 1 : 0,
                }));
              }}
            />
            {/* <Button type="default" onClick={onPageLoad}>Reset</Button> */}
            <Button
              type='primary'
              onClick={() => {
                form.submit();
              }}
            >
              Save
            </Button>
          </div>
        </div>
        <ReusableForm
          formInstance={form}
          onSubmit={handleFormSubmit}
          onChange={handleFormChange}
          layout='horizontal'
          className='templateForm'
        >
          <div className='formSection1'>
            <ReSelect
              name='mode'
              label='Template Category'
              items={modeTemplateOptions}
              onChange={(value: any) => {
                setTypeTemplateOptions(typeTemplateOptionsData[value] || []);
              }}
            />
            <ReSelect
              name='type'
              label='Template Name'
              items={typeTemplateOptions}
            />
            <ReSelect
              name='trigger'
              label='Template Prompt'
              items={triggerTemplateOptions}
            />
          </div>
          {/* <div className='formSection2'>
            <ReInput type='simple' name='name' label='Name' size='small' />
          </div> */}
          <div className='formSection1'>
            <ReInput type='simple' name='name' label='Name' size='small' />
            <ReSelect
              name='company'
              label='Company'
              items={company}
            />
            <ReSelect
              name='partner'
              label='Partners'
              items={partners}
            />
          </div>
          {templateData?.type === 'EMAIL_TEMPLATE' && (
            <ReInput name='subject' type='simple' label='Subject' />
          )}
          <ReInput name='description' type='textArea' label='Description' />
          <Upload
            {...props}
            onChange={(response) => {
              if (response.file.status !== 'uploading') {
                console.log(response.file, response.fileList);
              }
              if (response.file.status === 'done') {
                console.log('response', response.file.response.url);
                setUploadedfile(response.file.response.url);

                // message.success(`${response.file.response.url}
                //    file uploaded successfully`);
              } else if (response.file.status === 'error') {
                // message.error(`${response.file.name}
                //  file upload failed.`);
              }
            }}
          >
            <Button>Upload Template</Button>
          </Upload>
          {/* <ReUpload
            errorMessage='Please enter your file'
            label='Attach Source File'
            BtnTitle='Click to Upload Data File'
            name='attachments'
            onBeforeUpload={() => {}}
            onRemove={() => {}}
            fileListMaxCount={10}
            fileList={[]}
            // action='https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload'

          /> */}
          <p>Template:</p>
          <Styles.TextEditorContainer>
            <Button
              icon={<ExpandOutlined />}
              onClick={() => window.open(uploadedfile, '_blank')}
            >
              Open Editor
            </Button>
            {/* <CKEditor
                            config={{
                                // plugins: [Alignment],
                                toolbar: [
                                    "Heading",
                                    "bold",
                                    "italic",
                                    "bulletedList",
                                    "numberedList",
                                    "link",
                                    "insertTable",
                                    // "alignment"
                                ],
                            }}
                            editor={ClassicEditor}
                            onChange={(event: any, editor: any) => {
                                const data = editor.getData();
                                setTemplateData((prevValue: any) => { return { ...prevValue, body: data } });
                            }}
                            data={templateData?.body}
                        /> */}
            <DocEditor/>
          </Styles.TextEditorContainer>
        </ReusableForm>
      </div>
      <div className='helperContainer'>
        {/* <div className="query">
                    <h3>Custom Field Generator</h3>
                    <ReusableForm
                        formInstance={queryForm}
                        onSubmit={() => { }}
                    >
                        <ReInput
                            name="field"
                            placeholder="Field Name"
                            type="simple"
                            label=""
                            // onChange={genarateCustomQuery}
                        />
                        <ReInput
                            name="query"
                            placeholder="Enter Your AI Query"
                            type="textArea"
                            label=""
                            // onChange={genarateCustomQuery}
                            disable={!fieldName || fieldName?.length === 0}
                        />
                    </ReusableForm>

                    <div className="customQuerryContainer">
                        <div className="customQuerryDisplay">
                            {customQuery}
                        </div>
                        <div className="copyBtn" onClick={() => { copyToClipBoard(customQuery, fieldName) }}>
                            <CopyOutlined />
                        </div>
                    </div>
                </div> */}
        <div className='mappings'>
          <h3>Mapping</h3>
          <Collapse>
            {mapping?.map((item: any) => (
              <Panel header={item.heading} key={item.key}>
                {item.mapping.map((option: any) => (
                  <div className='optionContainer'>
                    <div className='options'>{option.title}</div>
                    <CopyToClipboard 
                     text={option?.data}
                     onCopy={() => {
                      onCopy(option.title);
                    }}>
                    <div
                      className='actionBtn'
                      // onClick={() => {
                      //   copyToClipBoard(option?.data, option?.title);
                      // }}
                    >
                      <CopyOutlined />
                    </div>
                    </CopyToClipboard>
                    {/* {option.edit !== false && <div className="actionBtn" onClick={() => { queryForm.setFieldsValue({ field: (option.data.replace('{{', '')).replace('}}', '') }); setCustomQuery(option.data); }}>
                                                <EditOutlined />
                                            </div>} */}
                  </div>
                ))}
              </Panel>
            ))}
          </Collapse>
        </div>
      </div>
    </Styles.Container>
  );
}

export default ViewTemplate;
