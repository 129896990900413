import { Button, Dropdown, Form, Tag } from "antd";
import { useEffect, useState } from "react";
import { generateRandomNumbers } from "../../Methods/Methods";
import { closeButton, containerStyles } from "./Constant";
import { CloseCircleFilled, PlusOutlined } from "@ant-design/icons";

interface IItems {
  key: string | number;
  label: string;
}
interface IProps {
  dropDownItems: IItems[];
  formInstance: any;
  label: string;
  name: string;
  noStyle?: boolean;
  required?: boolean;
  disable?: boolean;
  defaultValues?: IItems[];
}

function ReCustomSelect(props: IProps) {
  const {
    dropDownItems,
    label,
    name,
    disable,
    formInstance,
    noStyle,
    required,
    defaultValues,
  } = props;

  const [rules, setRules] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<any>([]);

  const handleRemove = (type: "one" | "all", key?: string) => {
    let items: any;
    if (type === "one") {
      items = selectedItems.filter((item: any) => item.key !== key);
    } else if (type === "all") {
      items = [];
    }
    formInstance.setFieldValue(name, items);
    setSelectedItems(items);
  };

  const handleSelect = (e: any) => {
    const val = e?.key;
    const fieldVal = formInstance.getFieldValue(name);
    
    const updated = Array.isArray(fieldVal) ? [
      ...fieldVal,
      {
        key: `${val}_${generateRandomNumbers()}`,
        label: val,
      },
    ] : [{
        key: `${val}_${generateRandomNumbers()}`,
        label: val,
    }];
    
    formInstance.setFieldValue(name, updated);
    setSelectedItems(updated);
};


  useEffect(() => {
    setRules(
      required
        ? [{ required: true, message: `Please Enter The ${label}` }]
        : []
    );
  }, [required, disable, formInstance]);

  useEffect(() => {
    if (Array.isArray(defaultValues)) {
      formInstance.setFieldValue(name, defaultValues);
      setSelectedItems(defaultValues);
    }
  }, [defaultValues]);

  return (
    <Form.Item label={label} name={name} rules={rules} noStyle={noStyle}>
      <div style={containerStyles}>
        <CloseCircleFilled
          style={closeButton}
          onClick={() => {
            handleRemove("all");
          }}
        />
        <Dropdown menu={{ items: dropDownItems, onClick: handleSelect }}>
          <Button
            icon={<PlusOutlined />}
            style={{ marginRight: "0.5rem" }}
            size="small"
            type="primary"
          />
        </Dropdown>
        {selectedItems?.map((item: any) => {
          return (
            <Tag
              closable
              onClose={(e) => {
                e.preventDefault();
                handleRemove("one", item?.key);
              }}
            >
              {item?.label}
            </Tag>
          );
        })}
      </div>
      {/* <p style={{ textAlign: "right", color: "#888888" }}>
        {selectedItems.length}
      </p> */}
    </Form.Item>
  );
}

export default ReCustomSelect;
