import moment from "moment";
import { contactMapping } from "./Mappings";
import { TUniView, TViewName } from "../Interfaces/Apis/Common.api.interface";
import { Uniview } from "../API/Uniview";

export const mask = (str: string) =>
  str.replace(/(..)(.{1,20})(?=.*@)/g, (_, a, b) => a + "*".repeat(b.length));

export const validateEmail = (str: string) => {
  let validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (str.match(validRegex)) {
    return true;
  } else {
    return false;
  }
};

export const supportIdGenerator = () => {
  return Math.random().toString(36).substr(2, 9);
};

export const makeFirstLetterCapital = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const convertToSimpleCaseFromCamelCase = (text?: string) => {
  if (!text) return "";
  const finalText = text.replace(/([A-Z])/g, " $1").toLowerCase();
  return finalText?.charAt(1).toUpperCase() + finalText.slice(2);
};

export const getTableColumnFilterItems = (
  data: Object[],
  key: string
): Object[] => {
  const items: string[] = [];

  const arrayTypeKeys = [
    "sitesToBeNotified",
    "sitesNotified",
    "sitesAcknowledged",
    "sitesNotifiedToIRB",
  ];
  const dateKeys = [
    "lrd",
    "created_at",
    "sent_on",
    "acknowledged_on",
    "irb_notified_on",
    "sentOn",
    "acknowledgedOn",
    "irbNotifiedOn",
    "dueDate",
  ];

  if (dateKeys.includes(key)) {
    data?.forEach((rc: any) => {
      if (rc[key]) {
        if (!items.includes(convertDateInReadableFormat(rc[key])))
          items.push(convertDateInReadableFormat(rc[key]));
      } else if (!items.includes("-")) items.push("-");
    });
  } else if (arrayTypeKeys.includes(key)) {
    data?.forEach((rc: any) => {
      if (!items.includes(rc[key].length)) items.push(rc[key].length);
    });
  } else if (key === "type") {
    data?.forEach((rc: any) => {
      let itemValue;
      if (rc.type === 100) {
        itemValue = rc.type_value;
      } else {
        itemValue = `${rc.type}-day SUSAR`;
      }
      if (!items.includes(itemValue)) items.push(itemValue);
    });
  } else if (key === "typeValue") {
    data?.forEach((rc: any) => {
      let itemValue;
      if (rc.type === 100 || rc.type === 0) {
        itemValue = rc.type_value || rc.typeValue;
      } else {
        itemValue = `${rc.type}-day SUSAR`;
      }
      if (!items.includes(itemValue)) items.push(itemValue);
    });
  } else {
    data?.forEach((rc: any) => {
      if (!items.includes(rc[key])) items.push(rc[key]);
    });
  }

  return items.map((value: any) => {
    if (key === "type") {
    }
    return {
      text: value,
      value: value,
    };
  });
};

export function getPercentTagColor(value: any) {
  if (value < 100) {
    return "red";
  } else {
    return "green";
  }
}

export const colorFilterItems = [
  {
    text: "Red",
    value: "1",
  },
  {
    text: "Green",
    value: "2",
  },
];
export const textAndColor: {
  [key: string]: any;
} = {
  0: {
    label: "No",
    color: "red",
  },
  1: {
    label: "Yes",
    color: "green",
  },
};
export function getFilteredByColor(colorName: string, value: string) {
  if (colorName === "Red" && value === "1") {
    return true;
  } else if (colorName === "Green" && value === "2") {
    return true;
  }
}
export const statusColorFilterItems = [
  {
    text: "Pending",
    value: "0",
  },
  {
    text: "Sent",
    value: "1",
  },
  {
    text: "Delivered",
    value: "2",
  },
  {
    text: "Failed",
    value: "3",
  },
];
export const statusTextAndColor: {
  [key: string]: any;
} = {
  0: {
    label: "Pending",
    color: "orange",
  },
  1: {
    label: "Sent",
    color: "blue",
  },
  2: {
    label: "Delivered",
    color: "green",
  },
  3: {
    label: "Failed",
    color: "red",
  },
};

export const makeDecimalValueSmaller = (num: string, toFixed: number) => {
  return parseFloat(parseFloat(num).toFixed(toFixed));
};

export function getPercentTagFilteredByColor(
  progressValue: number,
  value: any
) {
  if (progressValue === 100 && value === "2") {
    return true;
  } else if (progressValue !== 100 && value === "1") {
    return true;
  }
}



export const statusTextAndColorForWorkFlow: { [key: string]: any } = {
  0: { label: "Working on it", color: "orange" },
  1: { label: "Not Started", color: "blue" },
  2: { label: "Done", color: "green" },
  3: { label: "Stuck", color: "red" },
};

export function convertDateInReadableFormat(
  date: string,
  time?: boolean,
  format?: string
) {
  const newDate = moment(date).utc();
  const dateValue = newDate.format(format ? format : "DD-MMM-YYYY");
  const timeValue = newDate.format("HH:mm:ss");

  return time
    ? `${dateValue}, ${timeValue}`
    : dateValue !== "Invalid date"
    ? `${dateValue}`
    : `NA`;
}

export function truncate(str: any, maxLength: any) {
  if (str?.length > maxLength) {
    return str?.substring(0, maxLength - 3) + ".....";
  } else {
    return str;
  }
}

interface IProps {
  setLoading: Function;
  uniView: TUniView;
  viewName: TViewName;
  setTableData: Function;
  setPaginationPageSize: Function;
  shortByKey: string;
  sortType?: string;
}

export const fetchCVTableData = async (props: IProps) => {
  const {
    setLoading,
    uniView,
    viewName,
    setTableData,
    setPaginationPageSize,
    shortByKey,
  } = props;
  setLoading(true);
  let obj: any = {};
  const univiewService = new Uniview(uniView, viewName);
  obj[`${shortByKey}`] = props?.sortType || "DESC";
  const option = {
    limit: 10,
    orderBy: obj,
  };
  const res = await univiewService.fetchData({}, option);
  if (res?.status) {
    setTableData(res.data);
    setPaginationPageSize(res.count);
  }
  setLoading(false);
};

export function capitalizeSubstring(str: string, substring: string) {
  return str.replace(new RegExp(substring, "gi"), (match) =>
    match.toUpperCase()
  );
}

export function convertToSimpleCase(str: string) {
  // Use regex to split the camel case string into words
  const words = str.split(/(?=[A-Z])/);

  // Join the words with a space separator
  const simpleCaseString = words.join(" ");

  return simpleCaseString.toLowerCase();
}

export function disabledFutureDate(current: any) {
  return current && current > moment().endOf("day");
}

export function convertToCamelCase(str: string) {
  const words = str.toLowerCase().split(" ");

  const camelCaseWords = words.map((word, index) => {
    if (index === 0) {
      return word;
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
  });

  return camelCaseWords.join("");
}

export function removeDuplicatesFromArray(arr: string[]) {
  let unique: string[] = [];
  arr?.forEach((ele) => {
    if (unique.indexOf(ele) === -1) {
      unique.push(ele);
    }
  });
  unique.sort((a, b) => a.localeCompare(b));
  return unique;
}

export const GetRoleName = (id: string, roleData: any) => {
  return roleData.find((data: any) => data?.id === id)?.name;
};

export const replaceRoleAndCountryNames = (
  inputString: string,
  allCountriesMapper: any
) => {
  if (!inputString) return;
  const roleRegex = /role\s*=\s*'(\d+)'/g;
  const countryRegex = /country\s*=\s*'([A-Z]{2})'/g;
  let replacedString = inputString.replace(
    roleRegex,
    (match: string, roleNum: string) => {
      const roleName = contactMapping(roleNum ? parseInt(roleNum) : 0);
      return `role = '${roleName === "-" ? "Old Roles Nums" : roleName}'`;
    }
  );
  replacedString = replacedString.replace(
    countryRegex,
    (match, countryAlpha) => {
      const country = allCountriesMapper[countryAlpha];
      return `country = '${country || "-"}'`;
    }
  );
  return replacedString;
};
