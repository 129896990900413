import ReusableForm from "../../unity-ui-common/Components/ReForm";
import ReInput from "../../unity-ui-common/Components/ReFormFields/ReInput";
import ReUpload from "../../unity-ui-common/Components/ReFormFields/ReUpload";
import ReModal from "../../unity-ui-common/Components/ReModal";
import KnowledgeBase from "../../Components/KnowledgeBase/KnowledgeBase";
import FAQ from "../../Components/FAQ/FAQ";
import { useState } from "react";
import { SmallSupportCard, SupportIdGenerator } from "../../Components";
import {
  MailOutlined,
  BookOutlined,
  CloseOutlined,
  BulbOutlined,
} from "@ant-design/icons";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import { Card, Form } from "antd";
import * as styles from "./SupportStyles";
import { file } from "../../API/File";
import { notification } from "../../API/Notifications";

function Support() {
  const [form] = Form.useForm();
  const [supportId, setSupportId] = useState<string>("");
  const [showForm, setShowForm] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [attachmentUploading, setAttachmentUploading] =
    useState<boolean>(false);
  const [attacmentData, setAttacmentData] = useState<any>([]);
  const [showKnowledgeBase, setShowKnowledgeBase] = useState<boolean>(false);
  const [showFAQS, setShowFAQS] = useState<boolean>(false);
  const handleBeforeUpload = async (fileObj: any) => {
    setAttachmentUploading(true);
    const filenameArr = fileObj["name"].split(".");
    const file_type = filenameArr[filenameArr.length - 1];

    const res = await file.getUrl(file_type);
    const fileName = filenameArr[0];

    setFileList([...fileList, fileObj]);
    await file.remoteUploadFile(res.url, fileObj);

    setAttacmentData([
      ...attacmentData,
      {
        fileUID: fileObj.uid,
        id: res.id,
        filename: fileName,
        file_type,
      },
    ]);

    setAttachmentUploading(false);
    return true;
  };

  const handleSubmit = async (values: any) => {
    const payload = {
      subject: values.subject || "",
      text: values.text + ` Support ID: ${supportId.toLocaleUpperCase()}` || "",
      attachmentList: attacmentData || [],
    };
    const res = await notification.sendEmail(
      "send-multi-attachment-email",
      payload
    );
    if (res?.status) {
      form.resetFields();
      setAttacmentData([]);
      setFileList([]);
      setShowForm(false);
      ReNotification({
        description: `Email Sent Successfully`,
        placement: "bottomLeft",
        type: "success",
      });
    } else {
      ReNotification({
        description: `Unable to Send Email`,
        placement: "bottomLeft",
        type: "error",
      });
    }
  };
  const toggleFormVisibility = () => {
    setShowForm((prevValue) => !prevValue);
    form.resetFields();
    setAttacmentData([]);
    setFileList([]);
  };

  return (
    <styles.Container>
      <styles.Left>
        <SupportIdGenerator supportId={supportId} setSupportId={setSupportId} />
        <div className="smallCards">
          <SmallSupportCard
            title="Contact via Email"
            buttonTitle="Contact us"
            onClick={toggleFormVisibility}
            Icon={<MailOutlined style={{ fontSize: "2rem" }} />}
          />
          <SmallSupportCard
            title="Learn from Knowledge Base"
            buttonTitle="Visit Knowledge Base"
            Icon={<BookOutlined style={{ fontSize: "2rem" }} />}
            onClick={() => setShowKnowledgeBase(!showKnowledgeBase)}
          />
          <SmallSupportCard
            title="FAQ"
            buttonTitle="Open FAQs"
            Icon={<BulbOutlined style={{ fontSize: "2rem" }} />}
            onClick={() => setShowFAQS(!showKnowledgeBase)}
          />
          <ReModal
            width={900}
            visibility={showFAQS}
            onCancel={() => setShowFAQS(false)}
            children={<FAQ />}
            title={"Frequently Asked Questions (FAQs)"}
            footer={null}
          />
          <ReModal
            visibility={showKnowledgeBase}
            onCancel={() => setShowKnowledgeBase(false)}
            children={<KnowledgeBase />}
            title={"Knowledge Base"}
            footer={null}
          />
        </div>
      </styles.Left>
      <styles.Right>
        {showForm ? (
          <Card className="contact-us-form" title={`Contact via Email`}>
            <div className="close" onClick={toggleFormVisibility} onKeyDown={() => {}}>
              <CloseOutlined />
            </div>
            <ReusableForm
              formInstance={form}
              onSubmit={handleSubmit}
              submitBtnText="Send Email"
              submitBtn
            >
              <ReInput label="Subject" name="subject" type="simple" required />
              <ReInput
                label="Description"
                name="text"
                type="textArea"
                textAreaOptions={{
                  rowSize: 7,
                }}
                required
              />
              <div className="upload-btn">
                <ReUpload
                  label="Upload Screen Shots"
                  name="screenshot"
                  BtnTitle="Click to Upload"
                  onBeforeUpload={handleBeforeUpload}
                  onRemove={(fileObj: any) => {
                    const index = fileList.indexOf(fileObj);
                    const newFileList = fileList.slice();
                    newFileList.splice(index, 1);
                    setFileList(newFileList);
                    const index2 = attacmentData.indexOf(fileObj);
                    const newFileList2 = attacmentData.slice();
                    newFileList2.splice(index2, 1);
                    setAttacmentData(newFileList2);
                  }}
                  fileList={fileList}
                  disable={attachmentUploading}
                  fileListMaxCount={20}
                  errorMessage={"Error"}
                  accept={".png,.jpg,.jpeg"}
                />
              </div>
            </ReusableForm>
          </Card>
        ) : (
          <></>
        )}
      </styles.Right>
    </styles.Container>
  );
}

export default Support;
