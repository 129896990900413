import { useEffect, useState } from "react";
import ReTable from "../../unity-ui-common/Components/ReTable/ReTable";
import { convertDateInReadableFormat } from "../../Helper/Methods";
import { Link } from "react-router-dom";
import { IResponse } from "../../Interfaces/Apis/Common.api.interface";
import { Uniview } from "../../API/Uniview";
import { file } from "../../API/File";
import { API_URL } from "../../API/Config";
import { actionTextGenerator } from "./ActionTextGenerator";

interface IProps {
  safetyDocumentID: string;
  flag: string;
}

function SafetyDocumentActivity(props: IProps) {

  const { safetyDocumentID, flag } = props;
  const [activities, setActivities] = useState<any[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [paginationTotalPage, setPaginationTotalPage] = useState<any>(0);

  const columns = [
    {
      title: "Action Type",
      key: "operationType",
      dataIndex: "operationType",
      width: 160,
      render: (value: string, auditLog: any) => {
        let text = value;
        if (auditLog?.operationType === "EC/IRB Informed") {
          const type = auditLog?.entityType;
          if (type === "3") text = "EC/IRB Not Informed";
          if (type === "1" && !auditLog?.valueAfter?.notificationDate) text = "EC/IRB Will Be Informed";
        }
        if (auditLog?.operationType.includes('Report')) {
          text = value.replace('Report', 'Safety Document')
        }
        return text;
      }
    },
    {
      title: "Action Taken By",
      dataIndex: "owner",
      key: "owner",
      width: 150,
      render: (owner: any) => {
        return owner?.name;
      },
    },
    {
      title: "Action Date and Time",
      dataIndex: "createdAt",
      key: "created_at",
      width: 130,
      render: (date: any) => {
        return (date && convertDateInReadableFormat(date, true)) || "-";
      },
    },
    {
      title: "Action",
      dataIndex: "ref",
      key: "ref",
      width: 350,
      render: (data: any, allData: any) => {
        return actionTextGenerator(allData);
      },
    },
    // {
    //   title: "File",
    //   key: "valueAfter",
    //   dataIndex: "valueAfter",
    //   width: 150,
    //   render: (valueAfter: any) => {
    //     if (valueAfter?.allAttachmentData) {
    //       return valueAfter?.allAttachmentData.map((data: any) => {
    //         return (
    //           <Link to={data?.url}>
    //             {data?.name}
    //           </Link>
    //         )
    //       });
    //     } else {
    //       return "-";
    //     }
    //   },
    // },
  ];

  const createNewTableData = async (data: any) => {
    return await Promise.all(
      data?.map(async (audit: any) => {
        if (
          (
            (audit?.valueAfter?.attachment && audit?.valueAfter?.attachment?.length) ||
            (audit?.valueAfter?.attachments && audit?.valueAfter?.attachments?.length) ||
            (audit?.valueAfter?.attachmentsInformIRB && audit?.valueAfter?.attachmentsInformIRB?.length) ||
            (audit?.valueAfter?.evidence && audit?.valueAfter?.evidence?.length)
          ) && (
            audit?.operationType === "Distribution Imported" ||
            audit?.operationType === "EC/IRB Informed" ||
            audit?.operationType === "Notification Acknowledged" ||
            audit?.operationType === "Distribution Updated"
          )
        ) {
          let fileData = audit?.valueAfter?.attachment || audit?.valueAfter?.attachments || audit?.valueAfter?.attachmentsInformIRB || [audit?.valueAfter?.evidence];
          let allFileRecord: any[] = [];
          if (audit?.operationType === "EC/IRB Informed") {
            const beforeAttachmentData = audit?.valueBefore?.attachmentsInformIRB || [];
            if (fileData?.length !== beforeAttachmentData?.length) {
              fileData = fileData?.filter((fileId: string) => (!beforeAttachmentData?.includes(fileId)))
            }
          }
          await Promise.all(fileData.map(async (fileId: string) => {
            const { fileRecord, url } = await file.getFileData(
              fileId
            );
            allFileRecord.push({
              id: fileId,
              name: fileRecord?.filename,
              type: fileRecord?.file_type,
              url: url,
            });
          }));
          return {
            ...audit,
            valueAfter: {
              ...audit.valueAfter,
              allAttachmentData: allFileRecord,
            },
          };
        }
        return audit;
      })

    );
  };

  const getActivityData = async (id: string) => {
    setTableLoading(true);
    const uniViewService = new Uniview("audit-logs", "all");
    const res: IResponse = await uniViewService.fetchData(
      {
        entityID: { "=": safetyDocumentID },
      },
      {
        limit: 10,
        orderBy: { createdAt: "DESC" },
        offset: 0,
      }
    );
    if (res?.status) {
      setActivities(await createNewTableData(res.data));
      setPaginationTotalPage(res?.count);
    }
    setTableLoading(false);
  };

  useEffect(() => {
    getActivityData(safetyDocumentID);
  }, [flag, safetyDocumentID]);

  return (
    <div>
      <ReTable
        columns={columns}
        data={activities}
        loading={tableLoading}
        name="Safety Document Activity"
        title="Safety Document Activity"
        pagination={{
          total: paginationTotalPage,
        }}
        scroll={{
          x: 1000,
          y: 800,
        }}
        uniView={{
          uniView: "audit-logs",
          viewName: "all",
          apiUrl: API_URL,
          option: {
            orderBy: {
              key: "createdAt",
              order: "DESC",
            },
            limit: 10,
          },
          filter: {
            entityID: { "=": safetyDocumentID },
          },
          returnResponse: async (res: IResponse) => {
            setActivities(await createNewTableData(res.data));
            setPaginationTotalPage(res?.count);
          },
        }}
      />
    </div>
  );
}

export default SafetyDocumentActivity;
