import { Button, Form, Tag } from "antd";
import { useEffect, useState } from "react";
import { file } from "../../API/File";
import { ruleEngine } from "../../API/RuleEngine";
import { convertDateInReadableFormat } from "../../Helper/Methods";
import ReusableForm from "../../unity-ui-common/Components/ReForm";
import ReSelect from "../../unity-ui-common/Components/ReFormFields/ReSelect";
import ReDatePicker from "../../unity-ui-common/Components/ReFormFields/ReDatePicker";
import ReInput from "../../unity-ui-common/Components/ReFormFields/ReInput";
import ReUpload from "../../unity-ui-common/Components/ReFormFields/ReUpload";
import ReRadioGroup from "../../unity-ui-common/Components/ReFormFields/ReRadioGroup";
import { auth } from "../../API/Auth";
import {
  InvestigatorCausalityDropDownItems,
  seriousnessCriteriaDropDownItems,
  severityDropDownItems,
} from "../../Helper/Mappings";
import ReCustomSelect from "../../unity-ui-common/Components/ReCustomSelect/ReCustomSelect";

interface Iprops {
  initialData?: any;
  onFormSubmit: Function;
  submitButtonText: string;
  type: "version" | "new";
  typeOfImport?: number;
}

function CreateSafetyDocument(prop: Iprops) {
  const { initialData, onFormSubmit, submitButtonText, type, typeOfImport } = prop;
  const [form] = Form.useForm(); // form instance
  const [allCountries, setAllCountries] = useState<any>([]);
  const [attachmentList, setAttachmentList] = useState<any>([]);
  const [attachmentformData, setAttachmentformData] = useState<any>([]);
  const [submitting, setSubmitting] = useState(false);
  const [documentTypeOtherVisibility, setDocumentTypeOtherVisibility] =
    useState<boolean>(false);
  const [attachmentData, setAttachmentData] = useState<any>([]);

  const getCountries = async () => {
    const res = await ruleEngine.getCountries();
    setAllCountries(res);
  };

  const handleFormChanges = (changedValues: any, allValues: any) => {
    if (allValues.type === 7 || allValues.type === 15) {
      form.setFieldValue("typeValue", "");
      setDocumentTypeOtherVisibility(false);
    } else {
      setDocumentTypeOtherVisibility(true);
    }
  };

  const handleFormSubmit = async (values: any) => {
    setSubmitting(true);

    if (values.type || values?.typeValue === undefined)
      values["typeValue"] = "";

    if (!values.typeValue && !values.type) return;

    values.attachments = [...attachmentformData, ...attachmentData];
    if (values.lrd) {
      values["day0"] = convertDateInReadableFormat(values.lrd._d);
    }

    let newSeverityValue: string[] = [];
    values?.severity?.forEach((item: any) => {
      newSeverityValue.push(item.label);
    });
    values["meta"] = {
      saeReportedTerms: values.saeReportedTerm,
      seriousnessCriteria: values.seriousnessCriteria,
      severity: newSeverityValue,
      investigatorCausality: values.investigatorCausality,
      sponsorCausality: values.sponsorCausality,
    };
    values["tags"] = [];
    values["serialNumber"] = "";
    values["org"] = auth.getCurrentWorkspace().id;
    values.creationType = typeOfImport === 0 ? "xml" : "createDocument";
    onFormSubmit(values);
    setSubmitting(false);
  };

  const onBeforeAttachmentUpload = async (fileformData: any) => {
    setSubmitting(true);

    const filenameArr = fileformData["name"].split(".");
    const file_type = filenameArr[filenameArr.length - 1];

    const res = await file.getUrl(file_type);
    const fileName = filenameArr[0];
    setAttachmentList((prev: any) => [...prev, fileformData]);
    await file.remoteUploadFile(res.url, fileformData);
    setAttachmentformData((prev: any) => [
      ...prev,
      {
        fileUID: fileformData.uid,
        id: res.id,
        filename: fileName,
        file_type,
      },
    ]);
    setSubmitting(false);
  };

  const handleAttachmentFileRemove = (fileformData: any) => {
    setSubmitting(true);
    const index = attachmentList.indexOf(fileformData);
    const newFileList = attachmentList.slice();
    newFileList.splice(index, 1);
    setAttachmentList(newFileList);
    setAttachmentformData((prev: any) =>
      prev.filter((item: any) => item.fileUID !== fileformData.uid)
    );
    setSubmitting(false);
  };

  const getAttachmentData = async (attachment: string[]) => {
    const fileData = await Promise.all(
      attachment.map(async (id: string) => {
        const { fileRecord } = await file.getFileData(id);
        return {
          // fileUID: fileRecord.id,
          id: fileRecord.id,
          file_type: fileRecord.file_type,
          filename: fileRecord.filename,
        };
      })
    );
    setAttachmentData(fileData);
  };

  useEffect(() => {
    if (form.getFieldValue("type") === 0) {
      setDocumentTypeOtherVisibility(true);
    } else {
      setDocumentTypeOtherVisibility(false);
    }
  }, [initialData]);

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (initialData?.attachment?.length && type === "version") {
      getAttachmentData(initialData?.attachment);
    }
  }, [initialData?.attachment]);

  return (
    <div>
      <ReusableForm
        formInstance={form}
        onChange={handleFormChanges}
        onSubmit={handleFormSubmit}
        initialFormValues={initialData}
      >
        <div style={{ marginBottom: "1rem" }}>
          <p style={{ marginBottom: "0" }}>
            Safety Document Type
            <label style={{ color: "red", fontSize: "1.2rem" }}>* </label>
          </p>
          <ReRadioGroup
            defaultValue={7}
            label="Safety Document"
            noStyle
            name="type"
            items={[
              {
                title: "7-day SUSAR",
                value: 7,
              },
              {
                title: "15-day SUSAR",
                value: 15,
              },
              {
                title: "Other",
                value: 0,
              },
            ]}
          />
          <ReInput
            type="simple"
            name="typeValue"
            label=""
            maxLength={200}
            required={documentTypeOtherVisibility}
            form={form}
            noStyle
            width="250px"
            disable={!documentTypeOtherVisibility}
          />
        </div>
        <ReInput
          label="Study Number"
          name="studyID"
          type="simple"
          required={!documentTypeOtherVisibility}
        />
        <ReInput
          required={!documentTypeOtherVisibility}
          form={form}
          label="Product"
          name="product"
          type="simple"
        />
        <ReInput
          label="Study Title"
          required={!documentTypeOtherVisibility}
          form={form}
          name="studyTitle"
          type="simple"
        />
        <ReInput
          label="Site Number"
          name="sourceSiteID"
          type="simple"
          required={!documentTypeOtherVisibility}
          form={form}
        />
        <ReInput
          name="sourceSiteName"
          label="Site Name"
          type="simple"
          required={!documentTypeOtherVisibility}
          form={form}
        />
        <ReCustomSelect
          formInstance={form}
          label="Severity"
          name="severity"
          required={!documentTypeOtherVisibility}
          dropDownItems={severityDropDownItems?.map((ele: any) => {
            return {
              key: ele.title,
              label: ele.title,
            };
          })}
          defaultValues={
            type === "new"
              ? form.getFieldValue("severity") || []
              : initialData?.severity
          }
        />
        <ReDatePicker
          label="Day 0"
          name="lrd"
          dateFormat="DD-MMM-YYYY"
          featureDates
          form={form}
          type="simple"
          width="100%"
          required={!documentTypeOtherVisibility}
        />
        <ReInput
          label="Safety Document ID"
          form={form}
          name="safetyDatabaseReportNumber"
          type="simple"
          required={!documentTypeOtherVisibility}
        />
        <ReSelect
          label="Investigator Causality"
          form={form}
          type="multiple"
          name="investigatorCausality"
          items={InvestigatorCausalityDropDownItems.map((inv: any) => {
            return {
              value: inv.title,
              title: inv.title,
            };
          })}
          required={!documentTypeOtherVisibility}
        />
        <ReSelect
          label="Sponsor Causality"
          form={form}
          type="multiple"
          name="sponsorCausality"
          items={InvestigatorCausalityDropDownItems.map((inv: any) => {
            return {
              value: inv.title,
              title: inv.title,
            };
          })}
          required={!documentTypeOtherVisibility}
        />
        <ReSelect
          form={form}
          type="multiple"
          label="Seriousness Criteria"
          name="seriousnessCriteria"
          items={seriousnessCriteriaDropDownItems.map((ser: any) => {
            return {
              value: ser.key,
              title: ser.title,
            };
          })}
          required={!documentTypeOtherVisibility}
        />
        <ReSelect
          label="Country"
          name="country"
          form={form}
          searchable
          items={allCountries.map((cou: any) => {
            return {
              value: cou.alpha2,
              title: cou.name,
            };
          })}
          required={!documentTypeOtherVisibility}
        />
        <ReInput
          label="SAE Reported Term"
          name="saeReportedTerm"
          form={form}
          type="simple"
          required={!documentTypeOtherVisibility}
        />
        {type === "version" && (
          <div
            style={{
              margin: "0.5rem 0",
              display: "flex",
              flexWrap: "wrap",
              rowGap: "0.5rem",
            }}
          >
            {attachmentData.map((ele: any) => {
              return (
                <Tag
                  closable
                  onClose={() => {
                    setAttachmentData(
                      attachmentData.filter((att: any) => att.id !== ele.id)
                    );
                  }}
                  key={ele.id}
                >
                  {ele.filename}
                </Tag>
              );
            })}
          </div>
        )}

        <ReUpload
          errorMessage="Please enter your file"
          accept=".pdf"
          label="Attach File"
          BtnTitle="Click to Upload File"
          name="attachments"
          onBeforeUpload={onBeforeAttachmentUpload}
          onRemove={handleAttachmentFileRemove}
          fileListMaxCount={8}
          fileList={attachmentList}
          multiple
        />
        <Form.Item noStyle>
          <Button type="primary" htmlType="submit" loading={submitting}>
            {submitButtonText}
          </Button>
        </Form.Item>
      </ReusableForm>
    </div>
  );
}

export default CreateSafetyDocument;
