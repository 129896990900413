import {  Select } from "antd";
import * as styles from "./ReportDetailHeaderStyles";
function CaseDetailHeader(props: {
  reportId: string;
  lrd: string;
  dueDate: string;
  allVersions: any;
  selectedReport: any;
  setSelectedReport: React.Dispatch<any>;
  handleReportChange?:(newReportId: string) => void|undefined;
  setIsMatchingRulesVisible:React.Dispatch<React.SetStateAction<boolean>>;
  setIsRuleVisible:React.Dispatch<React.SetStateAction<boolean>>;
  isDataFetching:boolean
}) {
  const { reportId, lrd, dueDate, handleReportChange,setIsMatchingRulesVisible,setIsRuleVisible,isDataFetching } = props;
  
  return (
    <styles.Container>
      <h3>{reportId}</h3>
      <styles.subContainers>
        
        <div className="box select">
          <span className="heading">USDE ID</span>
          {/* <Select
            value={props.selectedReport?.id}
            defaultValue="Select Version"
            bordered={false}
          >
            {props.allVersions.map((ele: any) => (
              <Select.Option value={ele.id} key={ele.id}>
                <h4
                  onClick={() =>{
                    props.setSelectedReport(
                      props.allVersions.find((item: any) => item.id === ele.id)
                    )
                    
                    handleReportChange && handleReportChange(ele.id)
                    setIsMatchingRulesVisible(false);
                    setIsRuleVisible(false)
                  }
                }
                onKeyDown={() => {}}
                >
                  {ele.id}
                </h4>
              </Select.Option>
            ))}
          </Select> */}
          <Select
           value={props.selectedReport?.id|| undefined}
          //  defaultValue="Select Version"
           bordered={false}
          onChange={(value) => {
         if (isDataFetching) {
         return;
         }
         const newSelectedReport = props.allVersions.find((item: any) => item.id === value);
           props.setSelectedReport(newSelectedReport);
           handleReportChange && handleReportChange(value);
           setIsMatchingRulesVisible(false);
           setIsRuleVisible(false)
          }}
         >
       {props.allVersions.map((ele: any) => (
        <Select.Option value={ele.id} key={ele.id}>
          <h4>{ele.id}</h4>
        </Select.Option>
       ))}
       </Select>
        </div>
        <div className="box">
          <span>Day 0</span>
          <p>{lrd}</p>
        </div>
        <div className="box">
          <span>Notification Due Date</span>
          <p>{dueDate}</p>
        </div>
      </styles.subContainers>
    </styles.Container>
  );
}

export default CaseDetailHeader;
