import { BaseAPI } from "./Common";
class Template extends BaseAPI {
  getAllTemplates(): Promise<any> {
    return this.makeRequest("getAll", "get");
  }
  getOne(id: string): Promise<any> {
    return this.makeRequest(`get/${id}`, "get");
  }
  update(id: string, payload: any): Promise<any> {
    return this.makeRequest(`update/${id}`, "patch", payload);
  }
  create(payload: any): Promise<any> {
    return this.makeRequest("create", "post", payload);
  }
}
export const template = new Template("template");
