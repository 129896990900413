import { useEffect, useState } from "react";
import ReDrawer from "../../unity-ui-common/Components/ReDrawer";
import ReusableForm from "../../unity-ui-common/Components/ReForm";
import { Form, Result, Tag } from "antd";
import ReUpload from "../../unity-ui-common/Components/ReFormFields/ReUpload";
import ReInput from "../../unity-ui-common/Components/ReFormFields/ReInput";
import ReRichTextEditor from "../../unity-ui-common/Components/ReRichTextEditor/ReRichTextEditor";
import { Button } from "antd/lib";
import { DataParser } from "../../unity-ui-common/Classes/DataParser";
import * as Styles from "./EmailSafetyDocumentsDrawerStyles";
import ReTable from "../../unity-ui-common/Components/ReTable/ReTable";
import { safetyDocumentColumns } from "../../Routes/SafetyDocument/Constant";
import { distributions } from "../../API/Distribution";
import ReModal from "../../unity-ui-common/Components/ReModal";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";

interface IProps {
  visibility: boolean;
  setVisibility: Function;
  selectedSafetyDocument: any[];
  createNewColumns?:any[]
}

export default function EmailSafetyDocumentsDrawer(props: IProps) {
  const [form] = Form.useForm();

  const { visibility, setVisibility, selectedSafetyDocument, createNewColumns } = props;
 
  const [successfulRequest, setSuccessfulRequest] = useState<boolean>(false);
  const [attachmentList, setAttachmentList] = useState<any>([]);
  const [response, setResponse] = useState<any>();
  const [submitButtonLoading, setSubmitButtonLoading] =
    useState<boolean>(false);
  const [contactListEmails, setContactListEmails] = useState<string[]>([]);
  const [selectedSafetyDocumentKeys, setSelectedSafetyDocumentKeys] =
    useState<any>([]);

  const handleFormSubmit = async (values: any) => {
    if (
      !contactListEmails.length ||
      !attachmentList[0] ||
      !values.subject ||
      !values.body
    ) {
      return;
    }

    setSubmitButtonLoading(true);
    const res = await distributions.importAndSendMail(
      selectedSafetyDocumentKeys,
      attachmentList[0],
      values.subject,
      values.body,
      contactListEmails
    );
    setSubmitButtonLoading(false);
    if (!res?.statusCode) {
      setSuccessfulRequest(true);
      setResponse(res);
    } else {
      ReNotification({
        header: "Email Safety Documents",
        description: "Email not sent: Please select Safety Document",
        duration: 1000,
        placement: "bottomLeft",
        type: "error",
      });
    }
  };

  const onBeforeAttachmentUpload = async (fileformData: any) => {
    setResponse(null);
    setSuccessfulRequest(false);
    const dataParseService = new DataParser();
    const contactListData: any = await dataParseService.XLMSToJson(
      fileformData
    );
    if (contactListData.length) {
      const columnIndex = contactListData[0].indexOf("Primary Email ID");
      const emails = contactListData
        .slice(1)
        .map((row: string) => {
          return row[columnIndex];
        })
        .filter((ele: string) => ele !== undefined);
      setContactListEmails(emails);
    }
    setAttachmentList([fileformData]);
  };
  const handleAttachmentFileRemove = (fileformData: any) => {
    setResponse(null);
    setSuccessfulRequest(false);
    const index = attachmentList.indexOf(fileformData);
    const newFileList = attachmentList.slice();
    newFileList.splice(index, 1);
    setAttachmentList(newFileList);
    setContactListEmails([]);
  };

  useEffect(() => {
    if (visibility === false) {
      form.resetFields();
      setAttachmentList([]);
      setResponse(null);
      setSuccessfulRequest(false);
      setContactListEmails([]);
    }
    setSelectedSafetyDocumentKeys(
      selectedSafetyDocument?.map((ele: any) => ele?.id)
    );
  }, [visibility]);

  return (
    <div>
      <ReDrawer
        visibility={visibility}
        width={750}
        title="Email Safety Documents"
        onCancel={() => {
          setVisibility(false);
        }}
        extraContent={
          <>
            <Button
              type="primary"
              onClick={() => {
                form.submit();
              }}
              loading={submitButtonLoading}
            >
              Send
            </Button>
          </>
        }
      >
        {successfulRequest && (
          <ReModal
            width={600}
            title="Email Safety Documents"
            visibility={visibility}
            onCancel={() => {
              setVisibility(false);
            }}
            footer={false}
          >
            <Styles.ModalBody>
              <Result
                status="success"
                title="Email sent successfully"
                subTitle={
                  <>
                    Notifications were{" "}
                    <strong> sent to {response?.emailsSent} </strong> contacts{" "}
                  </>
                }
                extra={[
                  <Button
                    onClick={async () => {
                      setVisibility(false);
                    }}
                  >
                    Done
                  </Button>,
                ]}
              />
            </Styles.ModalBody>
          </ReModal>
        )}
        <ReusableForm
          formInstance={form}
          onSubmit={handleFormSubmit}
          initialFormValues={{
            subject: "You have received Safety Documents from UNITYdx",
          }}
        >
          <div>
            <ReUpload
              errorMessage="Please upload the file"
              accept="xlms"
              label="Contact List"
              BtnTitle="Click to Upload File"
              name="attachments"
              onBeforeUpload={onBeforeAttachmentUpload}
              onRemove={handleAttachmentFileRemove}
              fileListMaxCount={1}
              fileList={attachmentList}
              multiple
              required
            />
            <Styles.EmailTo>
              <span className="to">To</span>
              <div className="emails">
                {contactListEmails.map((email: string) => {
                  return (
                    <Tag
                      key={email}
                    >
                      {email}
                    </Tag>
                  );
                })}
              </div>
            </Styles.EmailTo>
          </div>
          <ReInput
            name="subject"
            label="Subject"
            max={300}
            type="simple"
            required
            form={form}
          />
          <ReRichTextEditor
            required
            name="body"
            label="Email Body"
            form={form}
          />
        </ReusableForm>
       
        <ReTable
          columns={createNewColumns}
          data={selectedSafetyDocument}
          name="Selected Safety Document"
          title="Selected Safety Document"
          rowSelection={{
            selectedRowKeys: selectedSafetyDocumentKeys,
            setSelectedRowKeys: setSelectedSafetyDocumentKeys,
            type: "checkbox",
            rowKey: "id",
          }}
          scroll={{
            x: 2500,
            y: 800,
          }}
        />
      </ReDrawer>
    </div>
  );
}
