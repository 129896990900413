import * as styles from "./NotificationDetailStyles";
import { useEffect, useState } from "react";
import { DisplayMsg } from "..";
import NotiDetailedTemplate from "../../Configs/Templates/NotiDetailedTemplate";
import { useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import IrbNotificationTemplate from "../../Configs/Templates/IrbNotificationTemplate";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import ReModal from "../../unity-ui-common/Components/ReModal";
import {
  Alert,
  Badge,
  Button,
  Checkbox,
  DatePicker,
  Input,
  Popconfirm,
  Radio,
  Space,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import {
  CloseOutlined,
  FilePdfTwoTone,
  UploadOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import ReLoading from "../../unity-ui-common/Components/ReLoading/ReLoading";
import { convertDateInReadableFormat } from "../../Helper/Methods";
import { file } from "../../API/File";
import { distributions } from "../../API/Distribution";
import { report as reportAPI } from "../../API/Report";

const FileLink = (props: { file: any }) => {
  const { file } = props;
  if (!file || !file.url) return null;
  return (
    <Button
      key={file.fileRecord.id}
      type="link"
      href={file.url}
      icon={<FilePdfTwoTone />}
      style={{ padding: "0" }}
    >
      {file?.fileRecord?.filename}
    </Button>
  );
};

function NotificationDetail(props: {
  user: string;
  onAdditionalTextChange?: any;
  editable?: boolean;
  reportId?: string | undefined;
  setId?: React.Dispatch<any>;
  activeTabId?: string;
}) {
  const { TextArea } = Input;
  const { onAdditionalTextChange, editable, activeTabId } = props;
  const { token, site } = useParams();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [progress, setSetProgress] = useState(false);
  const [selectedRadioBtn, setSelectedRadioBtn] = useState<number>(1);
  const [distribution, setDistribution] = useState<any>({});
  const [report, setReport] = useState({});
  const [additionalText, setAdditionalText] = useState<string>("");
  const [coverText, setCoverText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [fileDetails, setFileDetails] = useState<any>([]); // report file details  - report.attachments
  const [attachedFileDetails, setAttachedFileDetails] = useState<any>([]); // distribution file details - distribution.attachments
  const [attachmentsInformIRB, setAttachmentsInformIRB] = useState<any>([]); // distribution file details - distribution.attachmentsInformIRB
  const [reason, setReason] = useState("");
  const [irbEmails, setIrbEmails] = useState<string>("");
  const [irbEmailBody, setIrbEmailBody] = useState<string>("");
  const [irbNotifiedOn, setIrbNotifiedOn] = useState<string>("");
  const [evidence, setEvidence] = useState<any>([]);
  const [uploading, setUploading] = useState<any>(false);
  const [evidenceFilesInfo, setEvidenceFilesInfo] = useState<any>({});
  const [attachedFilesInfo, setAttachedFilesInfo] = useState<any>([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false);
  const [erroredState, setErroredState] = useState<boolean>(false);
  const [siteIRBNotifiedBy, setSiteIRBNotifiedBy] = useState<any>({});
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isAckModalVisible, setIsAckModalVisible] = useState(false);
  const [notificationTemplate, setNotificationTemplate] = useState(null);
  const handleAcknowledge = async () => {
    let attachments = attachedFilesInfo;

    if (token) {
      let res = await distributions.acknowledge(token, attachments, site);
      if (res && res?.statusCode) {
        ReNotification({
          description: res?.message,
          duration: 1000,
          placement: "bottomLeft",
          type: "error",
        });
        setErroredState(true);
      } else {
        ReNotification({
          description: "Acknowledged successfully",
          duration: 1000,
          placement: "bottomLeft",
          type: "success",
        });
      }
      getDistribution();
    } else {
      ReNotification({
        description: "Invalid Token",
        duration: 1000,
        placement: "bottomLeft",
        type: "error",
      });
    }
    setIsAckModalVisible(false);
  };

  const handleInformIrbEc = async () => {
    if (token) {
      setUploading(true);
      const options: any = {
        notifyType: selectedRadioBtn,
        reason: reason,
        emails: irbEmails,
        emailBody: irbEmailBody,
      };

      if (irbNotifiedOn !== "") options["notificationDate"] = irbNotifiedOn;
      if (selectedRadioBtn === 1 && Object.keys(evidenceFilesInfo).length) {
        options["evidence"] = evidenceFilesInfo;
      }
      if (selectedRadioBtn === 2 && Object.keys(attachedFilesInfo).length) {
        options["attachmentsInformIRB"] = attachedFilesInfo;
      }

      let res = await distributions.informIrbEc(token, options, site);
      if (res && res?.statusCode) {
        ReNotification({
          description: res?.message,
          duration: 1000,
          placement: "bottomLeft",
          type: "error",
        });
        if (res?.statusCode === 1) {
          setErroredState(true);
          setIsModalVisible(false);
        }
      } else {
        ReNotification({
          description: "IRB status marked successfully",
          duration: 1000,
          placement: "bottomLeft",
          type: "success",
        });
        setIsModalVisible(false);
      }
      setUploading(false);
      getDistribution();
    } else {
      ReNotification({
        description: "Invalid token",
        duration: 1000,
        placement: "bottomLeft",
        type: "error",
      });
    }
  };

  const setDistributionDetails = async (distribution: any) => {
    if (Object.keys(distribution).length) {
      const { irbNotficationEvidence, attachments, attachmentsInformIRB } =
        distribution;
      setDistribution(distribution);
      let attachmentIds = [];
      if (attachments?.length) {
        attachmentIds = attachments;
      }
      let irbAttachmentIds = [];
      if (attachmentsInformIRB?.length) {
        irbAttachmentIds = attachmentsInformIRB;
      }
      if (irbNotficationEvidence !== null) {
        irbAttachmentIds.push(irbNotficationEvidence);
      }

      const fileUrls = await file.getSignedUrls(attachmentIds);
      const irbFileUrls = await file.getSignedUrls(irbAttachmentIds);

      setAttachmentsInformIRB(irbFileUrls);
      setAttachedFileDetails(fileUrls); //set attachments
    }
  };

  const setReportDetails = async (res: any) => {
    if (res && res.report) {
      props.setId && props.setId(res.report.safetyDatabaseReportNumber);
      setAdditionalText(res.additionalText);
      setCoverText(res.coverText);
      setReport(res.report);
      const fileUrls = await file.getSignedUrls(res.report.attachments);
      setFileDetails(fileUrls);
    }
  };

  const getDistribution = async () => {
    setLoading(true);
    setNotificationTemplate(null);
    if (token) {
      let res = await distributions.getDistribution(token, site);
      if (res) {
        if (res?.statusCode) {
          ReNotification({
            description: res?.message,
            duration: 1000,
            placement: "bottomLeft",
            type: "error",
          });
          setErroredState(true);
        } else {
          setNotificationTemplate(res.templateHTML);
          setDistributionDetails(res?.distribution);
          setReportDetails(res);

          setSiteIRBNotifiedBy(res.siteIRBNotifiedBy);
        }
      }
    }
    setLoading(false);
  };
  const getReport = async () => {
    setLoading(true);
    setNotificationTemplate(null);
    const reportData = await reportAPI.findOne(props.reportId);
    const fileUrls = await file.getSignedUrls(reportData.attachments);
    setReport(reportData);
    setNotificationTemplate(reportData.notificationTemplateHtml);
    setFileDetails((prev: any) => {
      return [...prev, ...fileUrls];
    });
    setLoading(false);
  };
  useEffect(() => {
    if (editable) {
      getReport();
    }
    getDistribution();
  }, [token]);

  const uploadEvidence = async (fileObj: any) => {
    setEvidence([fileObj]);
    const res = await uploadFile(fileObj);
    setEvidenceFilesInfo(res);
    return false;
  };

  const uploadProps: UploadProps = {
    onRemove: (fileObj) => {
      const index = fileList.indexOf(fileObj);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setAttachedFilesInfo((prev: any) =>
        prev.filter((item: any) => item.fileUID != fileObj.uid)
      );
    },
    beforeUpload: async (fileObj) => {
      setFileList([...fileList, fileObj]);
      const res = await uploadFile(fileObj);
      setAttachedFilesInfo((prev: Array<any>) => {
        return [...prev, res];
      });
      return true;
    },
    fileList,
    disabled: uploading,
  };

  const uploadFile = async (fileObj: any) => {
    setUploading(true);
    const filenameArr = fileObj["name"].split(".");
    const file_type = filenameArr[filenameArr.length - 1];

    const res = await file.getUrl(file_type);
    const fileName = filenameArr[0];

    await file.remoteUploadFile(res.url, fileObj);
    setUploading(false);
    return {
      fileUID: fileObj.uid,
      id: res.id,
      filename: fileName,
      file_type,
    };
  };
  const handleResetDistributions = async ({
    siteNumber,
    token,
  }: {
    siteNumber: string;
    token: string | null | undefined;
  }) => {
    if (token) {
      let res = await distributions.resetDistribution(siteNumber, token);
      if (res && res?.updatedDistributionIds?.length) {
        getDistribution();
        ReNotification({
          description: "Reset Successfull",
          duration: 1000,
          placement: "bottomLeft",
          type: "success",
        });
        // getTableData();
      } else {
        ReNotification({
          description: "Reset Failed",
          duration: 1000,
          placement: "bottomLeft",
          type: "success",
        });
      }
    }
  };

  return (
    <div>
      <styles.AlertContainer>
        {!loading && (distribution?.siteName || distribution?.siteNumber) && (
          <Alert
            message={
              <>
                Notification for Site - {distribution?.siteNumber}
                {/* {" - "} */}
                {" : "}
                {distribution?.siteName} | {distribution?.roleTitle}
              </>
            }
            type="info"
            closable
            banner
          />
        )}
      </styles.AlertContainer>
      <styles.Container>
        {erroredState && <DisplayMsg width="100%" message="Invalid Token." />}
        {loading && (
          <ReLoading
            type="skeleton"
            prop={{
              loading: loading,
              active: true,
              rows: 18,
              width: 1000,
            }}
          />
        )}
        {!loading && !erroredState && (
          <div className="details">
            {activeTabId !== "3" ? (
              <NotiDetailedTemplate
                distribution={distribution}
                report={report}
                notificationTemplate={notificationTemplate}
                additionalText={additionalText}
                onAdditionalTextChange={onAdditionalTextChange}
                showAdditionalText={editable}
              />
            ) : (
              <IrbNotificationTemplate
                distribution={distribution}
                notificationTemplate={notificationTemplate}
                report={report}
                additionalText={additionalText}
                coverText={coverText}
                onAdditionalTextChange={onAdditionalTextChange}
                showAdditionalText={editable}
              />
            )}

            <Space direction="horizontal" size="large">
              {fileDetails?.map((item: any) => (
                <FileLink file={item} />
              ))}
            </Space>

            {distribution && distribution["isAcknowledged"] && (
              <styles.Indicator>
                <Badge
                  count={<CheckCircleOutlined style={{ color: "green" }} />}
                />
                {[16].includes(distribution?.role)
                  ? `
              Acknowledged on 
              ${convertDateInReadableFormat(distribution["acknowledgedOn"])}.
              `
                  : `
              You have acknowledged that you have received and read the
              information regarding Safety Document on 
              ${convertDateInReadableFormat(distribution["acknowledgedOn"])}.
              `}
              </styles.Indicator>
            )}

            {distribution &&
              !distribution["isIrbNotified"] &&
              distribution["reason"] != null && (
                <styles.Indicator>
                  <Badge
                    count={<CheckCircleOutlined style={{ color: "green" }} />}
                  />
                  Not notified to EC/IRB on{" "}
                  {convertDateInReadableFormat(distribution["irbNotifiedOn"])}{" "}
                  with reason : {distribution["reason"]}
                </styles.Indicator>
              )}

            {!distribution["isAcknowledged"] &&
              [15, 17, 18, 19, 5].includes(distribution?.role) && (
                <>
                  <p>
                    <Checkbox
                      onChange={() => {
                        isCheckboxChecked
                          ? setIsCheckboxChecked(false)
                          : setIsCheckboxChecked(true);
                      }}
                    />{" "}
                    I have received and read the information regarding this
                    Safety Document.
                  </p>
                  <Button
                    type="primary"
                    size="middle"
                    onClick={() => {
                      setIsAckModalVisible(true);
                    }}
                    disabled={isCheckboxChecked ? false : true}
                  >
                    I Acknowledge
                  </Button>
                </>
              )}

            {distribution?.["role"] == 16 &&
              !distribution["isAcknowledged"] && (
                <styles.Label>
                  <p> Acknowledgement Pending</p>
                </styles.Label>
              )}

            {attachedFileDetails &&
              attachedFileDetails.length > 0 &&
              distribution["isAcknowledged"] &&
              // auth.isPermissionExists(["5"])
              [15, 17, 16, 18, 19].includes(distribution?.role) && (
                <>
                  <styles.Label>Attachment(s)</styles.Label>
                  <Space direction="horizontal" size="large">
                    {attachedFileDetails?.map((item: any) => (
                      <FileLink file={item} />
                    ))}
                  </Space>
                </>
              )}
            {attachmentsInformIRB &&
              attachmentsInformIRB.length > 0 &&
              [15, 17, 16].includes(distribution?.role) && (
                <>
                  <styles.Label>Attachment(s) sent to EC/IRB </styles.Label>
                  <Space direction="horizontal" size="large">
                    {attachmentsInformIRB?.map((item: any) => (
                      <FileLink file={item} />
                    ))}
                  </Space>
                </>
              )}

            {!editable &&
              (distribution?.isIrbNotified ? (
                <styles.Indicator>
                  <Badge
                    count={<CheckCircleOutlined style={{ color: "green" }} />}
                  />
                  Notified to EC/IRB on{" "}
                  {convertDateInReadableFormat(distribution["irbNotifiedOn"])}.
                </styles.Indicator>
              ) : (
                !distribution["isIrbNotified"] &&
                distribution["reason"] == null && (
                  <p>
                    <br />
                    {
                      // auth.isPermissionExists(["6"])
                      [15, 16].includes(distribution?.role) && (
                        <Button
                          type="default"
                          size="middle"
                          onClick={() => {
                            setIsModalVisible(true);
                          }}
                        >
                          Inform EC/IRB
                        </Button>
                      )
                    }
                  </p>
                )
              ))}
            {(distribution["isAcknowledged"] ||
              distribution["isIrbNotified"]) && (
              <>
                <Popconfirm
                  title={
                    <p style={{ margin: "0" }}>
                      {distribution["isAcknowledged"] &&
                      distribution["isIrbNotified"]
                        ? "Are you sure you want to reset both the acknowledgment and the IRB status?"
                        : distribution["isAcknowledged"]
                        ? "Are you sure you want to reset the acknowledgment?"
                        : "Are you sure you want to reset the IRB status?"}
                    </p>
                  }
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() =>
                    handleResetDistributions({
                      siteNumber: distribution?.siteNumber,
                      token,
                    })
                  }
                >
                  <Button size="middle" type="default" style={{marginTop:"7px"}}>
                    {distribution["isAcknowledged"] &&
                    distribution["isIrbNotified"]
                      ? "Reset Acknowledgment & IRB Status"
                      : distribution["isAcknowledged"]
                      ? "Reset Acknowledgment"
                      : "Reset IRB Status"}
                  </Button>
                </Popconfirm>
              </>
            )}

            <ReModal
              width={550}
              visibility={isModalVisible}
              onOkay={() => {
                setIsModalVisible(false);
              }}
              onCancel={() => {
                setIsModalVisible(false);
              }}
              closable={false}
              title={
                <styles.ModalHeader>
                  <CloseOutlined
                    onClick={() => {
                      setIsModalVisible(false);
                    }}
                  />
                  <h3>Inform EC/IRB</h3>
                </styles.ModalHeader>
              }
              footer={
                <styles.ModalFooter>
                  <Button
                    type="primary"
                    size="middle"
                    onClick={() => {
                      handleInformIrbEc();
                      setSetProgress(true);
                    }}
                    disabled={uploading}
                  >
                    Done
                  </Button>
                </styles.ModalFooter>
              }
            >
              <styles.ModalBody>
                <h4>Select the option regarding information EC/IRB</h4>
                <styles.RadioBtns>
                  <Radio
                    name="radioBtn1"
                    onClick={() => setSelectedRadioBtn(1)}
                    checked={selectedRadioBtn === 1 && true}
                  >
                    <p>I will inform/have informed EC/IRB</p>
                    <h5>If you will inform EC/IRB through other methods</h5>
                  </Radio>
                  {selectedRadioBtn === 1 && (
                    <>
                      <h4>Select the date when EC/IRB was informed</h4>
                      <DatePicker
                        style={{ width: "20rem" }}
                        // value={moment(irbNotifiedOn)}
                        disabledDate={(current) => current.isAfter(moment())}
                        onChange={(date, dateString: string) =>
                          setIrbNotifiedOn(dateString)
                        }
                      />
                      <h4>Attach record of notification to EC/IRB</h4>
                      <Upload
                        fileList={evidence}
                        onRemove={(_file) => setEvidence([])}
                        beforeUpload={(fileObj) => uploadEvidence(fileObj)}
                      >
                        <Button icon={<UploadOutlined />}>Upload</Button>
                      </Upload>
                    </>
                  )}
                  <Radio
                    name="radioBtn2"
                    onClick={() => setSelectedRadioBtn(2)}
                    checked={selectedRadioBtn === 2 && true}
                  >
                    <p>I want to inform EC/IRB</p>
                    <h5>If you will inform EC/IRB through this system</h5>
                  </Radio>
                  {selectedRadioBtn === 2 && (
                    <>
                      <Input
                        onChange={(e) => setIrbEmails(e.target.value)}
                        placeholder="Enter EC email id"
                      />
                      {/* <RichTextEditor
                      onChange={(data: string) => setIrbEmailBody(data)}
                    /> */}

                      <styles.TextEditorContainer>
                        <CKEditor
                          config={{
                            toolbar: [
                              "Heading",
                              "bold",
                              "italic",
                              "bulletedList",
                              "numberedList",
                              "link",
                              "insertTable",
                            ],
                          }}
                          editor={ClassicEditor}
                          onChange={(event: any, editor: any) => {
                            const data = editor.getData();
                            setIrbEmailBody(data);
                          }}
                        />
                      </styles.TextEditorContainer>

                      <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Attachments</Button>
                      </Upload>
                    </>
                  )}
                  <Radio
                    name="radioBtn3"
                    onClick={() => setSelectedRadioBtn(3)}
                    checked={selectedRadioBtn === 3 && true}
                  >
                    <p>I don't want to inform EC/IRB</p>
                    <h5>I don't want to inform EC/IRB</h5>
                  </Radio>
                  {selectedRadioBtn === 3 && (
                    <>
                      <TextArea
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        maxLength={1000}
                        showCount
                        placeholder="Reason for not informing EC"
                      />
                    </>
                  )}
                </styles.RadioBtns>
              </styles.ModalBody>
            </ReModal>
            <ReModal
              width={550}
              visibility={isAckModalVisible}
              onOkay={() => {
                setIsAckModalVisible(false);
              }}
              onCancel={() => {
                setIsAckModalVisible(false);
              }}
              closable={false}
              title={
                <styles.ModalHeader>
                  <CloseOutlined
                    onClick={() => {
                      setIsAckModalVisible(false);
                    }}
                  />
                  <h3>Attachments</h3>
                </styles.ModalHeader>
              }
              footer={
                <styles.ModalFooter>
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => {
                      // handleInformIrbEc();
                      handleAcknowledge();
                      setSetProgress(true);
                    }}
                    disabled={uploading}
                  >
                    Done
                  </Button>
                </styles.ModalFooter>
              }
            >
              <styles.ModalBody>
                <Upload {...uploadProps}>
                  <Button icon={<UploadOutlined />}>Attachments</Button>
                </Upload>
              </styles.ModalBody>
            </ReModal>
          </div>
        )}
      </styles.Container>
    </div>
  );
}

export default NotificationDetail;
