


function DocEditor() {
  const collaboraUrl = "https://52.90.205.92:9980/browser/ca2ed20/cool.html"; // Collabora editor URL
  const wopiSrc = "https://unitydx-dev.soterius.xyz/docs-service/wopi/files/Abbreviated Narrative Template_20241018T085853015Z"; // WOPI source URL

  return (
    <>
      <iframe
          src={`https://52.90.205.92:9980/browser/ca2ed20/cool.html?WOPISrc=http%3A%2F%2Funitydx-stage-v2.soterius.xyz%2Fapi%2Fwopi%2Ffiles%2FAbbreviated%20Narrative%20Template_20241018T085853015Z.docx&access_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmaWxlTmFtZSI6IkFiYnJldmlhdGVkIE5hcnJhdGl2ZSBUZW1wbGF0ZV8yMDI0MTAxOFQwODU4NTMwMTVaLmRvY3giLCJ1c2VySWQiOiIzNDM0IiwiaWF0IjoxNzMxMDcxMzk5LCJleHAiOjE3MzExNTc3OTl9.gzlwEjnVjkBZeWzhAAioFogDbm7fFiRQXhGMv6P4Gls`}

        // src={`${collaboraUrl}?WOPISrc=${encodeURIComponent(wopiSrc)}`}
        style={{ width: '100%', height: '100vh', border: 'none' }}
        title="Collabora Online Editor"
      />
    </>
  );
}

export default DocEditor;
