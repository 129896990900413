import { Link } from "react-router-dom";
import { auth } from "../../API/Auth";

export const safetyDocumentColumns = [
    {
      title: "Safety Document ID",
      dataIndex: "safety_database_report_number",
      key: "safety_database_report_number",
      render: (id: string, record: any) =>
        auth.getUsersPrivileges().includes("3") ||
        auth.getUsersPrivileges().includes("4") ? (
          id || "-"
        ) : (
          <Link to={"/reports/view/" + record.id}>{id || "-"}</Link>
        ),
    },
    {
      title: "Day 0",
      dataIndex: "lrd",
      key: "lrd",
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      render: (product: string) => {
        return product || "-";
      },
    },
    {
      title: "Study Number",
      dataIndex: "study_id",
      key: "study_id",
      width: 200,
      filterSearch: true,
      onFilter: (query: string, record: any) =>
        record.study_id.startsWith(query),
      sorter: (a: any, b: any) => a.study_id.localeCompare(b.study_id),
      render: (id: string, record: any) => id || "-",
    },
    {
      title: "Safety Document Type",
      dataIndex: "type",
      key: "type",
    },
    // {
    //   title: "Sponsor",
    //   dataIndex: "org",
    //   key: "org",
    //   width: 130,
    //   filterSearch: true,
    //   onFilter: (query: string, record: any) => record.org.startsWith(query),
    //   sorter: (a: any, b: any) => a.org.localeCompare(b.org),
    // },
    {
      title: "Site Number",
      dataIndex: "source_site_id",
      key: "source_site_id",
      render: (id: string, record: any) => id || "-",
    },
    {
      title: "Site Name",
      dataIndex: "source_site_name",
      key: "source_site_name",
      render: (id: string, record: any) => id || "-",
    },
    {
      title: "USDE ID",
      dataIndex: "id",
      key: "id",
    },
    // {
    //   title: "Action",
    //   dataIndex: "id",
    //   width: 180,
    //   render: (id: string) => (
    //     <Space direction="horizontal">
    //       <Link to={"/reports/view/" + id}>View</Link>
    //       <span> | </span>
    //       <Button
    //         size="small"
    //         style={{ padding: "0" }}
    //         type="link"
    //         onClick={() => {
    //           handleCreateVesionButtonClicks(id);
    //         }}
    //       >
    //         Create Version
    //       </Button>
    //     </Space>
    //   ),
    // },
  ];

export  const cameltoSnakeConverstion ={
    safety_database_report_number :"safetyDatabaseReportNumber",
    product:"product",
    study_id:"studyID",
    type:"type",
    source_site_name:"sourceSiteName",
    source_site_id:"sourceSiteID",
    lrd:"lrd",
    id:"id"
   }