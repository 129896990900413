import { auth } from "../../API/Auth";
import { EyeOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import * as styles from "./KnowledgeBaseStyles";
import Iframe from "react-iframe";
import { useState } from "react";
import { Button } from "antd";

function KnowledgeBase() {
  const [pdfUrl, setPdfUrl] = useState("");

  const url = new URL(window.location.href);
  const pdfHeight =
    (window.innerHeight + window.innerHeight * 0.095).toString() + "px";

  const allManuals: {
    title: string;
    fileName: string;
    permission: string[];
  }[] = [
    {
      title: "Central IRB User Guide",
      fileName: "Central IRB User Guide.pdf",
      permission: ["17"],
    },
    {
      title: "Principal Investigator & Other Site Personnel User Guide",
      fileName: "Principal Investigator & Other Site Personnel User Guide.pdf",
      permission: ["15", "16"],
    },
    {
      title: "Safety Team Administrator User Guide",
      fileName: "Safety Team Administrator User Guide.pdf",
      permission: ["12"],
    },
    {
      title: "Safety Team User Guide",
      fileName: "Safety Team User Guide.pdf",
      permission: ["13"],
    },
    {
      title: "Sponsor User Guide",
      fileName: "Sponsor User Guide.pdf",
      permission: ["14"],
    },
  ];

  const downloadUserManual = (fileName: string) => {
    setPdfUrl(encodeURI(`${url.origin}/Assets/${fileName}`));
  };

  return (
    <styles.Container className="manual-container">
      {allManuals?.map((manual) =>
        auth.isPermissionExists(manual.permission) ? (
          <div
            className="manual"
            onClick={() => {
              downloadUserManual(manual.fileName);
            }}
            onKeyDown={() => {}}
          >
            {manual.title}
            <EyeOutlined />
          </div>
        ) : (
          <></>
        )
      )}
      {pdfUrl && (
        <>
          <Iframe
            url={pdfUrl}
            width="100%"
            height={pdfHeight}
            overflow="hidden"
            allowFullScreen
            className="pdf-viewer"
          />
          <Button
            className="pdf-close-btn"
            type="primary"
            onClick={() => {
              setPdfUrl("");
            }}
          >
            <ArrowLeftOutlined />
            Back
          </Button>
        </>
      )}
    </styles.Container>
  );
}

export default KnowledgeBase;
