import * as styles from "./ReportIndicatorStyles";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "antd";

function CasesIndicator(props: { item: any; loading: boolean }) {
  const { item, loading } = props;
  const navigate = useNavigate();
  const handleClick = () => {
    if (item.clickable) {
      navigate(item.url);
    }
  };
  return (
    <styles.Container>
      <div className="card" onClick={handleClick} onKeyDown={() => {}}>
        {loading ? (
          <Skeleton.Button
            block
            active
            shape="square"
            size="large"
            style={{ width: "100%", height: "30px" }}
          />
        ) : (
          <h4>{item.title}</h4>
        )}
        {loading ? (
          <Skeleton.Button
            block
            active
            shape="square"
            size="large"
            style={{ width: "25%", height: "50px" }}
          />
        ) : (
          <span>{item.count}</span>
        )}
      </div>
    </styles.Container>
  );
}

export default CasesIndicator;
