import * as styles from "./SendNotificationModalStyles";
import NotificationDetail from "../NotificationDetail/NotificationDetail";
import { useEffect, useState } from "react";
import { Button, Tag } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { distributions } from "../../API/Distribution";
import { report } from "../../API/Report";
import ReminderTemplate from "../../Configs/Templates/ReminderTemplate";
import { subtractDate } from "../../Helper/Util";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import ReTab from "../../unity-ui-common/Components/ReTab";
import ReModal from "../../unity-ui-common/Components/ReModal";
import {
  AcknowledgedStatus,
  DistributionStatus,
  permissionsEnum,
} from "../../Helper/Mappings";
import { convertDateInReadableFormat } from "../../Helper/Methods";
import ReTable from "../../unity-ui-common/Components/ReTable/ReTable";

function SendNotificationModal(props: {
  visibility: boolean;
  setVisibility: any;
  reportId: string | undefined;
  flag: boolean;
  setFlag: Function;
}) {
  const { visibility, setVisibility, reportId, flag, setFlag } = props;

  const [allContactsSelectedKeys, setAllContactsSelectedKeys] = useState<
    string[]
  >([]);
  const [distributionList, setData] = useState<any[]>([]);
  const [siteContactsSelectedKeys, setSiteContactsSelectedKeys] = useState<
    string[]
  >([]);
  const [siteContactList, setSiteContactList] = useState<any[]>([]);
  const [irbContactsSelectedKeys, setIrbContactsSelectedKeys] = useState<
    string[]
  >([]);
  const [irbContactList, setIrbContactList] = useState<any[]>([]);
  const [pendingAckSelectedKeys, setPendingAckSelectedKeys] = useState<
    string[]
  >([]);
  const [pendingDistributionList, setPendingDistributionList] = useState<any[]>([]);
  const [activeTabId, setActiveTabId] = useState<string>("1");
  const [isSummaryVisible, setIsSummaryVisible] = useState(false);
  const [additionalTextToSend, setAdditionalTextToSend] = useState("");
  const [reportData, setReportData] = useState<any>({
    safetyDatabaseReportNumber: "",
  });
  const [activeTemplatesTabId, setActiveTemplatesTabId] = useState("1");
  const [simpleTableLoading, setSimpleTableLoading] = useState<boolean>(false);
  const [sendNotificationButtonLoading, setSendNotificationButtonLoading] =
    useState<boolean>(false);

  const getData = async () => {
    setSimpleTableLoading(true);
    if (!reportId) {
      return [];
    } else {
      const data = await distributions.findAll(reportId);
      if (Array.isArray(data) && data.length > 0) {
        const reportData = await report.findOne(data[0].reportID);
        setReportData(reportData);
        setData(data);

        setSiteContactList(
          data.filter((item: any) => {
            if (item.role === 15 || item.role === 16) return item;
          })
        );
        setIrbContactList(
          data.filter((item: any) => {
            if (item.role === 17) return item;
          })
        );

        setPendingDistributionList(() => {
          return data.filter((item: any) => {
            //   // (OSP && status=sent) && (PI && status!=pending && !isAcknowledged)
            //   if (item.role == 17 || item.role == 16) {
            //     // role 5 - IRB and 4 - OSP
            //     if (item.distributionStatus == 1) return true; // 2 - Delivered
            //     else return false;
            //   } else if (item.role == 15) {
            //     // role 3 - PI
            //     if (
            //       item.distributionStatus != 0 &&
            //       item.distributionStatus != 3 &&
            //       !item.isAcknowledged
            //     )
            //       return true; // 0 - Pending
            //     else return false;
            //   } else if (
            //     item.distributionStatus != 0 &&
            //     item.distributionStatus != 3 &&
            //     item["isAcknowledged"] == false
            //   )
            //     return true;
            return (
              item.distributionStatus != 0 &&
              item.distributionStatus != 3 &&
              item.isAcknowledged === false
            )
          });
        });
        setSimpleTableLoading(false);

        let selectedRows = data.map((row: any) => {
          if (row["distributionStatus"] == 0)
            // Pending
            return row.id;
        });
        setAllContactsSelectedKeys(selectedRows);
        let pendingSelected = data.map((row: any) => {
          if (row.role == 17 || row.role == 16) {
            // role 5 - IRB and 4 - OSP
            if (row.distributionStatus != 2) return row.id; // 2 - Delivered
          } else if (row.role == 15) {
            // role 3 - PI
            if (
              row.distributionStatus != 0 &&
              row.distributionStatus != 3 &&
              !row.isAcknowledged
            )
              return row.id; // 0 - Pending
          } else if (
            row.distributionStatus != 0 &&
            row.distributionStatus != 3 &&
            row["isAcknowledged"] == false
          ) {
            // Sent but not acknowledged
            return row.id;
          }
        });
        setPendingAckSelectedKeys(pendingSelected);
      }
      setSimpleTableLoading(false);
    }
  };

  const sendNotifications = async () => {
    setSendNotificationButtonLoading(true);
    // need to figure out which reminder template is sending from front-end
    // if (activeTabId !== "3" && activeTemplatesTabId === "2") {
    // for reminder ack templates
    //   console.log("template type reminder ack");
    // } else if (activeTabId == "3" && activeTemplatesTabId === "2") {
    // for reminder EC/IRB templates
    //   console.log("template type reminder EC/IRB");
    // }

    let res = await distributions.sendNotifications(
      activeTabId == "1"
        ? allContactsSelectedKeys
        : activeTabId == "2"
          ? siteContactsSelectedKeys
          : activeTabId == "3"
            ? irbContactsSelectedKeys
            : pendingAckSelectedKeys,

      additionalTextToSend,
      activeTabId !== "3" && activeTemplatesTabId === "2"
        ? "Acknowledgement"
        : activeTabId == "3" && activeTemplatesTabId === "2"
          ? "EC/IRB"
          : undefined,
      activeTemplatesTabId === "2"
    );
    if (res && !res?.notSent?.length) {
      ReNotification({
        description: "Notifications sent",
        duration: 1000,
        placement: "bottomLeft",
        type: "success",
      });
      // props.onSend && props.onSend();
    } else {
      ReNotification({
        description:
          "Notifications not sent for Emails - " + res.notSent.join(", "),
        duration: 1000,
        placement: "bottomLeft",
        type: "warning",
      });
      // props.onSend && props.onSend();
    }
    setSendNotificationButtonLoading(false);
    setFlag(!flag);
  };

  const columns = [
    {
      title: "Site Name ",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
    },
    {
      title: "Site Number ",
      dataIndex: "siteNumber",
      key: "siteNumber",
      width: 200,
    },
    {
      title: "Study Number",
      dataIndex: "studyNumber",
      key: "studyNumber",
      width: 130,
    },
    { title: "Contact Name", dataIndex: "name", key: "name", width: 250 },
    {
      title: "Email ID",
      dataIndex: "primaryEmailID",
      key: "primaryEmailID",
      width: 250,
    },
    { title: "Role", dataIndex: "roleTitle", key: "roleTitle", width: 230 },
    {
      title: "Report Delivery Status",
      dataIndex: "distributionStatus",
      key: "distributionStatus",
      width: 200,
      render: (status: any) => (
        <Tag
          color={
            (DistributionStatus[status] && DistributionStatus[status].color) ||
            "pink"
          }
          key={status}
        >
          {(DistributionStatus[status] && DistributionStatus[status].label) ||
            status}
        </Tag>
      ),
    },
    {
      title: "Date to Notification (CD)",
      dataIndex: "sentOn",
      key: "sentOn",
      width: 220,
      render: (sentOn: string, record: any) => {
        return (
          (sentOn &&
            convertDateInReadableFormat(sentOn) +
            " (" +
            subtractDate(new Date(sentOn), new Date(reportData.lrd)) +
            ")") ||
          "-"
        );
      },
    },
    {
      title: "Acknowledgement Received",
      dataIndex: "isAcknowledged",
      key: "isAcknowledged",
      width: 240,
      render: (isAcknowledged: any) => {
        isAcknowledged = isAcknowledged ? 1 : 0;
        return (
          <Tag
            color={
              (AcknowledgedStatus[isAcknowledged] &&
                AcknowledgedStatus[isAcknowledged].color) ||
              "pink"
            }
          >
            {(AcknowledgedStatus[isAcknowledged] &&
              AcknowledgedStatus[isAcknowledged].label) ||
              isAcknowledged}
          </Tag>
        );
      },
    },
    {
      title: "EC/IRB Notified",
      dataIndex: "isIrbNotified",
      key: "isIrbNotified",
      width: 150,
      render: (isIrbNotified: any, record: any) => {
        isIrbNotified = isIrbNotified ? 1 : 0;

        if (parseInt(record?.role) === permissionsEnum.PARTNER) {
          return <Tag>N/A</Tag>;
        }
        return (
          <Tag
            color={
              (AcknowledgedStatus[isIrbNotified] &&
                AcknowledgedStatus[isIrbNotified].color) ||
              "pink"
            }
          >
            {(AcknowledgedStatus[isIrbNotified] &&
              AcknowledgedStatus[isIrbNotified].label) ||
              isIrbNotified}
          </Tag>
        );
      },
    },
  ];

  const handleSendNotificationClicks = async () => {
    if (isSummaryVisible) {
      await sendNotifications();
      setVisibility(false);
      setIsSummaryVisible(false);
    } else {
      setIsSummaryVisible(true);
    }
  };

  const templateTabItems = [
    {
      title: "Notification Template",
      key: "1",
      children: (
        <NotificationDetail
          user="cro"
          onAdditionalTextChange={setAdditionalTextToSend}
          editable={true}
          reportId={reportId}
          activeTabId={activeTabId}
        />
      ),
    },
    {
      title: `Reminder ${activeTabId !== "3" ? "Acknowledgement" : "IRB/EC"
        } Template`,
      key: "2",
      children: (
        <ReminderTemplate
          templateType={activeTabId !== "3" ? "Acknowledgement" : "IRB/EC"}
          additionalText={additionalTextToSend}
          onAdditionalTextChange={setAdditionalTextToSend}
          report={reportData}
        />
      ),
    },
  ];
  const listTabItems = [
    {
      title: "All Contacts",
      key: "1",
      children: getCommonTable("All Contacts"),
    },
    {
      title: "Site Contacts",
      key: "2",
      children: getCommonTable("Site Contacts"),
    },
    {
      title: "IRB Contacts",
      key: "3",
      children: getCommonTable("IRB Contacts"),
    },
    {
      title: "Pending Acknowledgement",
      key: "4",
      children: getCommonTable("Pending Acknowledgement"),
    },
  ];

  function getCommonTable(name: string) {
    let tableProps: any = {};
    switch (name) {
      case "All Contacts":
        tableProps = {
          data: distributionList,
          rowKeys: allContactsSelectedKeys,
          setRowKeys: setAllContactsSelectedKeys,
        };
        break;

      case "Site Contacts":
        tableProps = {
          data: siteContactList,
          rowKeys: siteContactsSelectedKeys,
          setRowKeys: setSiteContactsSelectedKeys,
        };
        break;

      case "IRB Contacts":
        tableProps = {
          data: irbContactList,
          rowKeys: irbContactsSelectedKeys,
          setRowKeys: setIrbContactsSelectedKeys,
        };
        break;
      case "Pending Acknowledgement":
        tableProps = {
          data: pendingDistributionList,
          rowKeys: pendingAckSelectedKeys,
          setRowKeys: setPendingAckSelectedKeys,
        };
        break;
    }

    return (
      <ReTable
        name="List of Recipients"
        title="List of Recipients"
        data={tableProps?.data}
        loading={simpleTableLoading}
        columns={columns}
        rowSelection={{
          selectedRowKeys: tableProps?.rowKeys,
          type: "checkbox",
          setSelectedRowKeys: tableProps?.setRowKeys,
          rowKey: "id",
        }}
      />
    );
  }

  useEffect(() => {
    getData();
  }, [visibility]);

  return (
    <styles.Container>
      <ReModal
        visibility={visibility}
        onOkay={() => {
          setVisibility(false);
        }}
        onCancel={() => {
          setVisibility(false);
        }}
        closable={false}
        width={1160}
        title={
          <styles.ModalHeader>
            <CloseOutlined
              onClick={() => {
                setVisibility(false);
              }}
            />
            <h3>
              Send Notification for&nbsp;
              {reportData &&
                (reportData.safetyDatabaseReportNumber || reportId)}
            </h3>
          </styles.ModalHeader>
        }
        footer={
          <styles.ModalFooter>
            {isSummaryVisible && (
              <Button
                size="large"
                onClick={() => {
                  setIsSummaryVisible(false);
                }}
              >
                Back
              </Button>
            )}
            <Button
              type="primary"
              loading={sendNotificationButtonLoading}
              size="large"
              onClick={handleSendNotificationClicks}
            >
              {isSummaryVisible ? "Send" : "Next"}
            </Button>
          </styles.ModalFooter>
        }
      >
        <styles.ModalBody>
          {!isSummaryVisible ? (
            <>
              <h2 className="heading">
                Select the recipients to whom safety report is to be sent
              </h2>

              <ReTab
                onChange={(id: any) => setActiveTabId(id)}
                activeKey={activeTabId}
                items={listTabItems}
              />
            </>
          ) : (
            <ReTab
              activeKey={activeTemplatesTabId}
              onChange={(key: string) => setActiveTemplatesTabId(key)}
              items={templateTabItems}
            />
          )}
        </styles.ModalBody>
      </ReModal>
    </styles.Container>
  );
}

export default SendNotificationModal;