import { BaseAPI } from "./Common";

class Distribution extends BaseAPI {
  async import(safetyDocumentID: string, file: File, attachmentData: any) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("safetyDocumentID", safetyDocumentID);
    formData.append("attachmentData", JSON.stringify(attachmentData));

    return await this.makeRequest("import", "post", formData, true);
  }

  async importAndSendMail(
    safetyDocumentIDs: Array<string>,
    file: File,
    subject: string,
    body: string,
    contactEmails: string[]
  ) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("subject", subject);
    formData.append("body", body);
    formData.append("contactEmails", JSON.stringify(contactEmails))
    formData.append("safetyDocumentIDs", safetyDocumentIDs.join(","));

    return await this.makeRequest("importAndMail", "post", formData, true);
  }

  async emailSafetyDocument(payload: any, file: File, attachmentData: any) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("attachmentData", JSON.stringify(attachmentData));
    return await this.makeRequest("", "post", formData);
  }

  findAll(reportID: string) {
    return this.makeRequest("findAll", "get", { reportID: reportID }).then(
      (response) => {
        return response;
      }
    );
  }

  getDistributionsBySites(reportID: string) {
    return this.makeRequest("getDistributionsBySites", "get", {
      reportID: reportID,
    }).then((response) => {
      return response;
    });
  }

  sendNotifications(
    distributionIds: Array<string>,
    additionalText: string,
    templateType?: string,
    isRemiderNotification?: boolean
  ) {
    return this.makeRequest("sendNotifications", "post", {
      distributionIds,
      additionalText,
      templateType,
      isRemiderNotification,
    });
  }

  resetAck(
    reportId: string,
    siteNumber: string,
     ) {
    return this.makeRequest("reset-acknowledgment", "post", {
      reportId,
      siteNumber,
    });
  }


  resetDistribution(
    siteNumber: string,
    token:string
   
  ) {
    return this.makeRequest("reset-distribution", "post", {
      siteNumber,
      token
    });
  }

  resetIRB(
    reportId: string,
    siteNumber: string,
   
  ) {
    return this.makeRequest("reset-informIrbEc", "post", {
      reportId,
      siteNumber,
    });
  }


  getDistribution(token: string, site?: string) {
    return this.makeRequest("getDistribution", "get", { token: token, site:site }).then(
      (response) => {
        return response;
      }
    );
  }

  acknowledge(token: string, attachments: any, site?:string) {
    return this.makeRequest("acknowledge", "post", {
      token: token,
      attachments,
      site:site
    }).then((response) => {
      return response;
    });
  }

  informIrbEc(
    token: string,
    options: {
      notifyType: number;
      notificationDate?: Date;
      attachments?: any;
      evidence?: any;
      reason?: string;
      emails?: string;
      emailBody?: string;
    },
    site?:string
  ) {
    return this.makeRequest("informIrbEc", "post", {
      token: token,
      options: options,
      site:site
    }).then((response) => {
      return response;
    });
  }

  getDistributionsForUser() {
    return this.makeRequest("getDistributionsForUser", "get").then(
      (response) => {
        return response;
      }
    );
  }

  getPendingAck() {
    return this.makeRequest("pendingAcks", "get", {}).then((response) => {
      return response;
    });
  }

  async update(payload: {
    id: string;
    sentOn: string;
    acknowledgedOn: string;
  }) {
    return await this.makeRequest("update", "post", payload);
  }

  async getComplianceData(payload: any) {
    return await this.makeRequest("complianceData", "post", payload);
  }

  async generateComplianceDataPdf(payload: any) {
    return await this.makeRequest("complianceData/export-pdf", "post", payload);
  }
}

export const distributions = new Distribution("distributions");
