import { loadState } from "../sessionstorage";
import { API_URL } from "./Config";

async function makePostRequest(
  path: string,
  payload?: any,
  multipart?: boolean
) {
  try {
    let headers = await getHeaders(multipart);

    let body = payload;
    if (!multipart) body = JSON.stringify(payload);
    const res = await fetch(API_URL + path, {
      method: "POST",
      headers: headers,
      body: body,
      credentials: "include",
    });
    const data = await res?.json();
    return data;
  } catch (err) {
    console.error(err);
  }
}

async function makePatchRequest(
  path: string,
  payload?: any,
  multipart?: boolean
) {
  try {
    let headers = await getHeaders(multipart);
    let body = payload;
    if (!multipart) body = JSON.stringify(payload);
    const res = await fetch(API_URL + path, {
      method: "PATCH",
      headers: headers,
      body: body,
      credentials: "include",
    });

    return res;
  } catch (err) {
    console.error(err);
  }
}

async function makeGetRequest(path: string, payload?: any) {
  try {
    let url = API_URL + path;
    if (payload) url = url + "?" + new URLSearchParams(payload);
    let headers = await getHeaders();
    const res = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: headers,
    });
    const data = await res?.json();
    return data;
  } catch (err) {
    console.error(err);
  }
}

async function makeDeleteRequest(
  path: string,
  payload?: any,
  multipart?: boolean
) {
  try {
    let url = API_URL + path;
    let headers = await getHeaders();
    let body;
    if (payload) body = JSON.stringify(payload);
    const res = await fetch(url, {
      method: "DELETE",
      headers: headers,
      credentials: "include",
      body: body,
    });

    const data = await res?.json();
    return data;
  } catch (err) {
    console.error(err);
  }
}

export async function makeRequest(
  path: string,
  method: string = "get",
  payload?: any,
  multipart?: boolean
): Promise<any> {
  if (method === "post") return makePostRequest(path, payload, multipart);
  else if (method === "patch")
    return makePatchRequest(path, payload, multipart);
  else if (method === "delete")
    return makeDeleteRequest(path, payload, multipart);
  else return makeGetRequest(path, payload);
}

async function getHeaders(multipart?: boolean) {
  let headers = {};
  if (!multipart)
    headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

  const handleResponse: any = async (attempt = 0) => {
    const sessionData = loadState()?.session || {};

    if (
      !loadState()?.session?.userAccessToken &&
      !sessionStorage.getItem("userAccessToken") &&
      attempt < 5
    ) {
      await new Promise((resolve) => setTimeout(resolve, 100));
      return handleResponse(attempt + 1);
    }

    if (
      sessionData &&
      (sessionData.userAccessToken || sessionStorage.getItem("userAccessToken"))
    ) {
      const token =
        sessionStorage.getItem("userAccessToken") ||
        sessionData.userAccessToken;
      headers = {
        ...headers,
        Authorization: `Bearer ${token}`,
        user: JSON.stringify(sessionData.userData),
      };
    }

    return headers;
  };

  return await handleResponse();
}

export class BaseAPI {
  path: string;
  constructor(path: string) {
    this.path = path;
  }

  async makeRequest(
    endpoint: string,
    method: string = "get",
    payload?: any,
    multipart?: boolean
  ) {
    let fullPath = endpoint != "" ? this.path + "/" + endpoint : this.path;
    let res = await makeRequest(fullPath, method, payload, multipart);

    if (
      res &&
      (res?.statusCode == 401 || res?.statusCode == 400) &&
      endpoint == "logout"
    ) {
      sessionStorage.removeItem("appState");
      let current = window.location.pathname;
      if (current != "/") {
        sessionStorage.setItem("redirect", current);
        window.location.href = "/";
        return;
      }
    } else if (res && res?.statusCode == 401 && endpoint != "login") {
      let current = window.location.pathname;
      sessionStorage.setItem("redirect", current);
      sessionStorage.setItem("unauth", "true");
      window.location.href = "/";
    } else if (res && res?.statusCode == 403) {
      sessionStorage.removeItem("appState");
      let current = window.location.pathname;
      sessionStorage.setItem("redirect", current);
      window.location.href = "/unauthorized";
    } else return res;
  }
}
