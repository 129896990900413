import styled from "styled-components";

export const Container = styled.div`
  background-color: #ffffff;
  padding: 0.5rem 1rem;
  width: 100% fit-content;

 .spacing {
        margin-right: 6px; 
    }

  .safety-document-edit-form > div{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    gap: 0.5rem 0.75rem;
    margin-top: 1rem;
  }

  .dropDown {
    margin-top: 1rem;
    .formItem {
      display: flex;
      column-gap: 0.25rem;
      
      >span{
        width: 33%;
      }
      .formInput, .formData{
        >*{
          width: 100%;
          max-width: 100%;
        }  
      width: 67%;
      }
      h4 {
        margin: 0;
        color: gray;
      }
    }
  }
`;
export const Options = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 1rem;
`;
export const CreateReportBtn = styled.form`
  margin-top: 1rem;
`;