import * as styles from "./AllNotifiactionsStyles";
import { useEffect, useRef, useState } from "react";
import ReTab from "../../unity-ui-common/Components/ReTab";
import ReTable from "../../unity-ui-common/Components/ReTable/ReTable";
import { allNotificationsColumns, boolFilterItems, pendingNotificationsColumns } from "./Constant";
import { PathIndicator } from "../../Components";
import { IUniView } from "../../unity-ui-common/Components/ReTable/Interface";
import { convertDateInReadableFormat, textAndColor } from "../../Helper/Methods";
import { auth } from "../../API/Auth";
import { API_URL } from "../../API/Config";

const sortingColumnKeys = [
  "irb_notified_on",
  "sentOn",
  "irb_notified_on",
  "lrd",
  "site_number",
  "safetyDatabaseReportNumber",
  "reportID",
  "studyNumber",
  "is_acknowledged",
  "acknowledgedOn",
  "is_irb_notified",
];
const filterColumnKeys = [
  "safetyDatabaseReportNumber",
  // "sentOn",
  {
    key: "sentOn",
    action: (date: any) =>
      date ? convertDateInReadableFormat(date) : "-",
  },
  {
    key: "lrd",
    action: (date: any) =>
      date ? convertDateInReadableFormat(date) : "-",
  },
 
  "site_number",
  "studyNumber",
  {
    key: "is_acknowledged",
    items: [
      {
        text: "Yes",
        value: true,
      },
      {
        text: "No",
        value: false,
      },
    ],
  },
  "reportID",
  {
    key: "acknowledgedOn",
    action: (date: any) =>
      date ? convertDateInReadableFormat(date) : "-",
  },
  {
    key: "is_irb_notified",
    items: [
      {
        text: "Yes",
        value: true,
      },
      {
        text: "No",
        value: false,
      },
    ],
  },
];

function AllNotifiactions() {
  const [refreshReTable, setRefreshReTable] = useState<boolean>(false);
  const [allNotificationsData, setAllNotificationsData] = useState([]);
  const [pendingActions, setPendingActions] = useState<Array<Object>>([]);
  const [activeTabId, setActiveTabId] = useState<string>("1");
  const [selectedAllNotiRowKeys, setSelectedAllNotiRowKeys] = useState<
    string[]
  >([]);
  const [selectedPendingActionRowKeys, setSelectedPendingActionRowKeys] =
    useState<string[]>([]);

  

  const filterColumnsForUser = (columns: any) => {
    //   SAFETY_TEAM_ADMIN, //+12
    //   SAFETY_TEAM,
    //   SPONSOR,
    //   PRINCIPLE_INVESTIGATOR 15,
    //   OTHER_SITE_PERSONNEL  16,
    //   INSTITUTIONAL_REVIEW_BOARD 17,
    //   REGULATORY_AUTHORITY 18,
    //   PARTNER 19,

    let newColumns = [];
    if (auth.isPermissionExists(["15", "16"])) {
      return columns;
    } else if (
      auth.isPermissionExists(["17"]) ||
      auth.isPermissionExists(["18"]) ||
      auth.isPermissionExists(["19"])
    ) {
      newColumns = columns.filter(
        (item: any) => item.key !== "is_irb_notified"
      );
    }
    return newColumns;
  };

  const getUniviewConfig = (
    forValue: "all" | "pending",
    isCsv: boolean
  ): IUniView => {
    let obj: IUniView = {
      uniView: "distributions",
      viewName: "notifications",
      apiUrl: API_URL,
      option: {
        orderBy: {
          // key: "updated_at",
          key:"sentOn",
          order: "DESC",
          
        },
        limit: isCsv ? 100 : 10,
      },
      filter: {},

      extra: {
        notificationType: "all",
      },
    };

    if (forValue === "pending") {
      obj.extra.notificationType = "pending";
    }

    return obj;
  };
  const pendingActionsTable = (
    <ReTable
      
      refresh={refreshReTable}
      name="Pending Actions"
      columns={filterColumnsForUser(pendingNotificationsColumns)}
      selectAllOnlyCurrentPage={true}
      scroll={{
        x: 2000,
        y: 800,
      }}
      uniView={getUniviewConfig("pending", false)}
      rowSelection={{
        selectedRowKeys: selectedPendingActionRowKeys,
        setSelectedRowKeys: setSelectedPendingActionRowKeys,
        rowKey: "id",
        type: "checkbox",
      }}
      exportOption={{
        csv: {
          params: getUniviewConfig("all", true),
          // disabled:
          //   activeTabId === "1"
          //     ? !pendingActions.length
          //     : !allNotificationsData.length,
          columns: filterColumnsForUser(pendingNotificationsColumns).map((each: any) => each.key),
        },
      }}
      columnOptions={{
        sorting: {
          columns: sortingColumnKeys,
          sortingDirections: ["ascend", "descend"],
        },
        filter: {
          columns: filterColumnKeys,
        },
      }}
    />
  );

  const allNotificationsTable = (
  <ReTable
      
       refresh={refreshReTable}
       name="All Notifications"
       columns={filterColumnsForUser(allNotificationsColumns)}
       selectAllOnlyCurrentPage={true}
       scroll={{
        x: 2000,
        y: 800,
       }}
       uniView={getUniviewConfig("all", false)}
       rowSelection={{
         selectedRowKeys: selectedAllNotiRowKeys,
         setSelectedRowKeys: setSelectedAllNotiRowKeys,
         rowKey: "id",
         type: "checkbox",
       }}
       exportOption={{
         csv: {
           params: getUniviewConfig("all", true),
          // disabled:
          //   activeTabId === "1"
          //     ? !pendingActions.length
          //     : !allNotificationsData.length,
           columns: filterColumnsForUser(allNotificationsColumns).map((each: any) => each.key),
         },
       }}
       columnOptions={{
         sorting: {
          columns: sortingColumnKeys,
           sortingDirections: ["ascend", "descend"],
         },
         filter: {
           columns: filterColumnKeys,
         },
       }}
     />
   );

  // const table = (
  //   <ReTable
      
  //     refresh={refreshReTable}
  //     name={activeTabId === "1" ? "Pending Actions" : "All Notifications"}
  //     columns={filterColumnsForUser(columns)}
  //     selectAllOnlyCurrentPage={true}
  //     scroll={{
  //       x: 2000,
  //       y: 800,
  //     }}
  //     uniView={
  //       activeTabId === "1"
  //         ? getUniviewConfig("pending", false)
  //         : getUniviewConfig("all", false)
  //     }
  //     rowSelection={{
  //       selectedRowKeys:
  //         activeTabId === "1"
  //           ? selectedPendingActionRowKeys
  //           : selectedAllNotiRowKeys,
  //       setSelectedRowKeys:
  //         activeTabId === "1"
  //           ? setSelectedPendingActionRowKeys
  //           : setSelectedAllNotiRowKeys,
  //       rowKey: "id",
  //       type: "checkbox",
  //     }}
  //     exportOption={{
  //       csv: {
  //         params: getUniviewConfig("all", true),
  //         // disabled:
  //         //   activeTabId === "1"
  //         //     ? !pendingActions.length
  //         //     : !allNotificationsData.length,
  //         columns: filterColumnsForUser(columns).map((each: any) => each.key),
  //       },
  //     }}
  //     columnOptions={{
  //       sorting: {
  //         columns: sortingColumnKeys,
  //         sortingDirections: ["ascend", "descend"],
  //       },
  //       filter: {
  //         columns: filterColumnKeys,
  //       },
  //     }}
  //   />
  // );

  useEffect(() => {
    // fetchData();
    const id = sessionStorage.getItem("notificationsTabActiveId");
    if (id) {
      setActiveTabId(id);
    }
  }, []);
useEffect(()=>{
  setRefreshReTable(true)
},[activeTabId])

  return (
    <styles.Container className="subContainer">
      <PathIndicator
        items={[
          {
            title: "Notifications",
            url: "/notifications",
          },
        ]}
      />
      <ReTab
        onChange={(id: any) => {
          setActiveTabId(id);
          sessionStorage.setItem("notificationsTabActiveId", id);
          setRefreshReTable(true)
        }}
        activeKey={activeTabId}
        items={[
          {
            title: "Pending Actions",
            key: "1",
            children: pendingActionsTable,
          },
          {
            title: "All Notifications",
            key: "2",
            children: allNotificationsTable,
          },
        ]}
      />
    </styles.Container>
  );
}

export default AllNotifiactions;
