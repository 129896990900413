import { Tag } from "antd";
import { getPercentTagColor } from "../../Helper/Methods";
import { remap } from "../../Helper/Util";
import { Link } from "react-router-dom";

export const columns = [
  {
    title: "Safety Document ID",
    dataIndex: "safetyDatabaseReportNumber",
    key: "safetyDatabaseReportNumber",
    width: 220,
    render: (safetyDatabaseReportNumber: string, data: any) => {
      const { id } = data;
      return (
        <Link to={"/reports/view/" + id}>{safetyDatabaseReportNumber}</Link>
      );
    },
  },
  {
    title: "Product",
    dataIndex: "product",
    key: "product",
    width: 150,
    render: (product: string) => {
      return product;
    },
  },
  {
    title: "Study Number",
    dataIndex: "studyID",
    key: "studyID",
    width: 200,
    render: (studyID: string) => {
      return studyID;
    },
  },
  {
    title: "Safety Document Type",
    dataIndex: "typeValue",
    key: "typeValue",
    width: 220,
    render: (typeValue: string) => {
      return typeValue;
    },
    // render: (type: number, row: any) => {
    //   if (row.type === 0 || row.type === 100) {
    //     return row.typeValue || "-";
    //   } else {
    //     return `${type}-day SUSAR`;
    //   }
    // },
  },
  {
    title: "Active Sites",
    dataIndex: "sitesToBeNotified",
    key: "sitesToBeNotified",
    width: 150,
    render: (sitesToBeNotified: Array<string>) => {
      return (sitesToBeNotified && sitesToBeNotified.length) || 0;
    },
  },
  {
    title: "Site Notification Compliance",
    dataIndex: "siteNotificationNumber",
    key:"siteNotificationNumber",
    width: 250,
    render: (siteNotificationNumber: number, row: any) => {
      let progressValue = remap(
        row.sitesNotified.length,
        0,
        row.sitesToBeNotified.length,
        0,
        100
      );
      if (isNaN(progressValue)) progressValue = 0;
      return (
        <>
          <Tag color={getPercentTagColor(progressValue)} className="percentTag">
            {progressValue.toFixed(0)}%
          </Tag>
          {row.sitesNotified.length}/{row.sitesToBeNotified.length}
        </>
      );
    },
  },
  {
    title: "Site Acknowledgement Compliance",
    dataIndex: "siteAcknowlegedNumber",
    key:"siteAcknowlegedNumber",
    width: 290,
    render: (siteAcknowlegedNumber: number, row: any) => {
      let progressValue = remap(
        row.sitesAcknowledged.length,
        0,
        row.sitesToBeNotified.length,
        0,
        100
      );
      if (isNaN(progressValue)) progressValue = 0;
      return (
        <>
          <Tag color={getPercentTagColor(progressValue)} className="percentTag">
            {progressValue.toFixed(0)}%
          </Tag>
          {row.sitesAcknowledged.length}/{row.sitesToBeNotified.length}
        </>
      );
    },
  },
  {
    title: "EC/IRB Notification Compliance",
    dataIndex: "siteNitifiedToIRBNumber",
    key:"siteNitifiedToIRBNumber",
    width: 270,
    render: (siteNitifiedToIRBNumber: number, row: any) => {
      let progressValue;
      if (row.sitesNotifiedToIRB && row.sitesToBeNotified) {
        progressValue = remap(
          row.sitesNotifiedToIRB.length,
          0,
          row.sitesToBeNotified.length,
          0,
          100
        );
        if (isNaN(progressValue)) progressValue = 0;
      }
      return (
        <>
          <Tag color={getPercentTagColor(progressValue)} className="percentTag">
            {progressValue.toFixed(0)}%
          </Tag>
          {row.sitesNotifiedToIRB.length}/{row.sitesToBeNotified.length}
        </>
      );
    },
  },
  {
    title: "USDE ID",
    dataIndex: "id",
    key: "id",
    width: 150,
    render: (id: string) => {
      return id || "-";
    },
  },
];
