import { useEffect, useState } from "react";
import * as styles from "./ContactComplianceStyles";
import { Button, Empty, Form, Tag, Tooltip } from "antd";
import SendNotificationModal from "../SendNotificationModal/SendNotificationModal";
import { LoadingSpinner, DisplayMsg } from "..";
import SideDrawer from "../SideDrawer/SideDrawer";
import { distributions } from "../../API/Distribution";
import { subtractDate } from "../../Helper/Util";
import { auth } from "../../API/Auth";
import moment from "moment";
import ReusableForm from "../../unity-ui-common/Components/ReForm";
import ReDatePicker from "../../unity-ui-common/Components/ReFormFields/ReDatePicker";
import ReUpload from "../../unity-ui-common/Components/ReFormFields/ReUpload";
import { file } from "../../API/File";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import ReDrawer from "../../unity-ui-common/Components/ReDrawer";
import ReTable from "../../unity-ui-common/Components/ReTable/ReTable";
import ImportContactListModal from "../ImportContactListModal/ImportContactListModal";
import {
  EditOutlined,
  UploadOutlined,
  BellOutlined,
  PlusOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  convertDateInReadableFormat,
  getTableColumnFilterItems,
  makeFirstLetterCapital,
  statusTextAndColor,
} from "../../Helper/Methods";
import { DistributionStatus, distributionsStatusMapping } from "../../Helper/Mappings";
import { Uniview } from "../../API/Uniview";
import { API_URL } from "../../API/Config";
import { IResponse } from "../../Interfaces/Apis/Common.api.interface";
import {
  IComponentProps,
  SiteDetails,
} from "../../Interfaces/Component/ContactCompliance.interface";
import { IUniView } from "../../unity-ui-common/Components/ReTable/Interface";

function ContactCompliance(props: IComponentProps) {
  const { safetyDocumentID, setCommonRefresh, commonRefresh } = props;
  const univiewConfig: IUniView = {
    uniView: "distributions",
    viewName: "contactCompliance",
    apiUrl: API_URL,
    option: {
      orderBy: {
        key: "createdAt",
        order: "ASC",
      },
      limit: 10,
    },
    filter: {
      reportID: { "=": safetyDocumentID },
    },
    returnResponse: (res: IResponse) => {
      setSimpleTableLoading(true);
      if (res?.status) {
        setTableData(res.data);
      }
      setSimpleTableLoading(false);
    },
  };
  const [refreshReTable, setRefreshReTable] = useState<boolean>(false);
  const [currentPermissions, setCurrentPermissions] = useState<string[]>([]);
  useEffect(() => {
    setCurrentPermissions(auth.getUsersPrivileges());
  }, []);

  const [form] = Form.useForm();

  // const [flag, setFlag] = useState<boolean>(false);
  const [importModal, setImportModal] = useState(false);
  const [notiModal, setNotiModal] = useState(false);
  const [isFileUploded, setIsFileUploded] = useState(false);
  const [isImported, setIsImported] = useState(false);
  const [sideDrawerVisible, setSideDrawerVisible] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [sideDrawerData, setSideDrawerData] = useState({});
  const [attachmentList, setAttachmentList] = useState<any>([]);
  const [attachmentformData, setAttachmentformData] = useState<any>([]);
  const [simpleTableLoading, setSimpleTableLoading] = useState<boolean>(true);
  const [editTableRecordDrawer, setEditTableRecordDrawer] =
    useState<boolean>(false);
  const [editingTableRecord, setEditingTableRecord] = useState<any>({});

  const columns = [
    {
      title: "",
      width: 40,
      render: (value: any, record: any) => {
        return [17, 18].includes(record.role) ? ( //check omar
          <Tooltip title="Edit">
            <EditOutlined
              style={{ cursor: "pointer" }}
              onClick={() => {
                setEditingTableRecord(record);
                setEditTableRecordDrawer(true);
              }}
            />
          </Tooltip>
        ) : (
          ""
        );
      },
    },
    {
      title: "Site Name",
      dataIndex: "siteName",
      key: "siteName",
      width: 190,
      // sorter: (a: any, b: any) => a.siteName.localeCompare(b.siteName),
      // filters: getTableColumnFilterItems(tableData, "siteName"),
      // filterSearch: true,
      // onFilter: (value: any, record: any) => record.siteName.startsWith(value),
    },
    {
      title: "Site Number",
      dataIndex: "siteNumber",
      key: "siteNumber",
      width: 190,
      // sorter: (a: any, b: any) => a.siteNumber.localeCompare(b.siteNumber),
      // filters: getTableColumnFilterItems(tableData, "siteNumber"),
      // filterSearch: true,
      // onFilter: (value: any, record: any) =>
      //   record.siteNumber.startsWith(value),
    },
    {
      title: "Contact Name",
      dataIndex: "name",
      key: "name",
      width: 220,
      // filters: getTableColumnFilterItems(tableData, "name"),
      // filterSearch: true,
      // onFilter: (value: any, record: any) => record.name.startsWith(value),
      // sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: "Role",
      dataIndex: "roleTitle",
      key: "roleTitle",
      width: 240,
      // filters: getTableColumnFilterItems(tableData, "roleTitle"),
      // filterSearch: true,
      // onFilter: (value: any, record: any) => record.roleTitle.startsWith(value),
      // sorter: (a: any, b: any) => a.roleTitle.localeCompare(b.roleTitle),
    },
    {
      title: "Email ID",
      dataIndex: "primaryEmailID",
      key: "primaryEmailID",
      width: 250,
      // filters: getTableColumnFilterItems(tableData, "primaryEmailID"),
      // filterSearch: true,
      // onFilter: (value: any, record: any) =>
      //   record.primaryEmailID.startsWith(value),
      // sorter: (a: any, b: any) =>
      //   a.primaryEmailID.localeCompare(b.primaryEmailID),
    },
    {
      title: "Report Delivery Status",
      dataIndex: "distributionStatus",
      key: "distributionStatus",
      width: 200,
      // filters: statusColorFilterItems,
      // onFilter: (value: string, record: any) => {
      //   const { distributionStatus } = record;
      //   return (
      //     makeFirstLetterCapital(
      //       statusTextAndColor[distributionStatus].color
      //     ) === statusColorFilterItems[distributionStatus].text &&
      //     statusColorFilterItems[distributionStatus].value === value
      //   );
      // },
      render: (status: string) => (
        <Tag
          color={
            (DistributionStatus[status] && DistributionStatus[status].color) ||
            "pink"
          }
          key={status}
        >
          {(DistributionStatus[status] && DistributionStatus[status].label) ||
            status}
        </Tag>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      width: 250,
      // sorter: (a: any, b: any) => a.dueDays - b.dueDays,
      // filters: getTableColumnFilterItems(tableData, "dueDate"),
      // filterSearch: true,
      // onFilter: (value: any, record: any) => {
      //   const { dueDate } = record;
      //   return convertDateInReadableFormat(dueDate).startsWith(value);
      // },
      render: (value: any, record: any) => {
        const text = record.dueDate
          ? `${convertDateInReadableFormat(record?.dueDate)} (${
              record?.dueDays
            })`
          : "-";
        return text;
      },
    },
    {
      title: "Date of Notification (CD)",
      dataIndex: "sentOn",
      key: "sentOn",
      width: 240,
      // filters: getTableColumnFilterItems(tableData, "sentOn"),
      // filterSearch: true,
      // onFilter: (value: any, record: any) => {
      //   const { sentOn } = record;
      //   return convertDateInReadableFormat(sentOn).startsWith(value);
      // },
      render: (sentOn: string, data: any) => {
        return (
          (sentOn &&
            convertDateInReadableFormat(sentOn) +
              " (" +
              subtractDate(new Date(sentOn), new Date(data?.lrd)) +
              ")") ||
          "-"
        );
      },
    },
  ];

  const getSideDrawerData = async (id: any) => {
    let selectedDistribution: SiteDetails;
    selectedDistribution =
      tableData.find((data: any) => data["reportID"] === id) || {};

    const timelineData = await fetchTimeLineData(id, "distributions");
    setSideDrawerData({
      details: {
        id: selectedDistribution.id,
        site_contact_name: selectedDistribution.name,
        email: selectedDistribution.primaryEmailID,
        site_name: selectedDistribution.siteName,
        site_number: selectedDistribution.siteNumber,
        evidence: selectedDistribution.irbNotficationEvidence,
        attachments: selectedDistribution.attachments,
      },
      timeLine: timelineData,
    });
  };

  const fetchTimeLineData = async (id: string, type: string) => {
    if (id) {
      const univiewService = new Uniview("audit-logs", "all");
      const res: IResponse = await univiewService.fetchData(
        {
          entityID: { "=": id },
          entityType: { "=": type },
          operationType: { "=": "Notification Sent" },
        },
        {
          orderBy: {
            createdAt: "ASC",
          },
        }
      );
      if (res?.status) {
        return res?.data?.map((data: any) => {
          return {
            id: data?.id,
            type: data?.operationType,
            entityName: data?.entityName,
            createdAt: data?.createdAt,
            distributionName: data?.ref?.distributionName,
            valueAfter: data?.valueAfter,
          };
        });
      }
    }
  };

  // async function getTableData() {
  // setSimpleTableLoading(true);
  // if (!safetyDocumentID) return [];
  // await distributions.findAll(safetyDocumentID).then((data: any) => {
  //   setTableData(data);
  // });
  // setSimpleTableLoading(false);
  // }

  const handleEditTableRecordFormSubmit = async (values: any) => {
    const payload = {
      id: editingTableRecord.id,
      sentOn: convertDateInReadableFormat(values.sentOn._d),
      acknowledgedOn: convertDateInReadableFormat(values.acknowledgedOn._d),
      attachments: attachmentformData,
    };
    const res = await distributions.update(payload);
    if (res?.status) {
      ReNotification({
        description: res?.message,
        duration: 1000,
        placement: "bottomLeft",
        type: "success",
      });
      setEditTableRecordDrawer(false);
      setEditingTableRecord({});
      setRefreshReTable(!refreshReTable);
      if(setCommonRefresh){
        setCommonRefresh(!commonRefresh)
      }
      
    } else {
      ReNotification({
        description: res?.message,
        duration: 1000,
        placement: "bottomLeft",
        type: "error",
      });
    }
  };

  const setEditTableRowFormInitialValues = () => {
    form.setFieldsValue({
      acknowledgedOn: editingTableRecord.acknowledgedOn
        ? moment(editingTableRecord.acknowledgedOn)
        : "",
      sentOn: editingTableRecord.sentOn
        ? moment(editingTableRecord.sentOn)
        : "",
    });
  };

  useEffect(() => {
    // getTableData();
    setRefreshReTable(!refreshReTable);
  }, [safetyDocumentID]);

  useEffect(() => {
    getSideDrawerData(safetyDocumentID);
  }, [tableData]);

  useEffect(() => {
    setEditTableRowFormInitialValues();
  }, [editingTableRecord]);

  const onBeforeAttachmentUpload = async (fileformData: any) => {
    const filenameArr = fileformData["name"].split(".");
    const file_type = filenameArr[filenameArr?.length - 1];
    const res = await file.getUrl(file_type);
    const fileName = filenameArr[0];

    setAttachmentList([...attachmentList, fileformData]);
    await file.remoteUploadFile(res.url, fileformData);

    setAttachmentformData((prev: any) => [
      ...prev,
      {
        fileUID: fileformData.uid,
        id: res.id,
        filename: fileName,
        file_type,
      },
    ]);
  };

  const handleAttachmentFileRemove = (fileformData: any) => {
    const index = attachmentList.indexOf(fileformData);
    const newFileList = attachmentList.slice();
    newFileList.splice(index, 1);
    setAttachmentList(newFileList);
    setAttachmentformData((prev: any) =>
      prev.filter((item: any) => item.fileUID !== fileformData.uid)
    );
  };

  return (
    <styles.Container>
      <styles.Body>
        <SendNotificationModal
          reportId={safetyDocumentID}
          visibility={notiModal}
          setVisibility={setNotiModal}
          // onSend={getTableData}
          flag={refreshReTable}
          setFlag={setRefreshReTable}
        />
        <ReDrawer
          title="Contact Compliance"
          visibility={editTableRecordDrawer}
          width={700}
          footer={false}
          onCancel={() => {
            setEditTableRecordDrawer(false);
          }}
        >
          <ReusableForm
            onSubmit={handleEditTableRecordFormSubmit}
            formInstance={form}
            submitBtn
          >
            <ReDatePicker
              label="Notification Date"
              name="sentOn"
              type="simple"
              dateFormat="DD-MMM-YYYY"
              width="100%"
              featureDates
              required
            />
            <ReDatePicker
              label="Acknowledgement Date"
              name="acknowledgedOn"
              type="simple"
              dateFormat="DD-MMM-YYYY"
              width="100%"
              featureDates
              required
            />
            <ReUpload
              errorMessage="Please enter your file"
              accept=".pdf"
              label="Attach File"
              BtnTitle="Click to Upload File"
              name="attachments"
              onBeforeUpload={onBeforeAttachmentUpload}
              onRemove={handleAttachmentFileRemove}
              fileListMaxCount={1}
              fileList={attachmentList}
            />
          </ReusableForm>
        </ReDrawer>
        {/* <>
          {currentPermissions?.length > 0 ? (
            : (
              <DisplayMsg message="No site contacts imported" />
            )
          ) : (
            <LoadingSpinner />
          )}
        </> */}
        {currentPermissions.includes("3") && (
          <ReTable
            refresh={refreshReTable}
            name="Contact Compliance"
            // loading={simpleTableLoading}
            columns={columns}
            data={tableData}
            exportOption={{
              csv: {
                // disabled: !tableData.length,
                params: univiewConfig,
              },
            }}
            uniView={univiewConfig}
            scroll={{
              x: 800,
              y: 800,
            }}
            columnOptions={{
              sorting: {
                columns: [
                  "siteName",
                  "siteNumber",
                  "name",
                  "roleTitle",
                  "primaryEmailID",
                  "distributionStatus",
                  "dueDate",
                  "sentOn",
                ],
                sortingDirections: ["ascend", "descend"],
              },
              filter: {
                    filterSearch: true,
                columns: [
                  "siteName",
                  "siteNumber",
                  "name",
                  "roleTitle",
                  "primaryEmailID",
                  {
                    key: "distributionStatus",
                    items: distributionsStatusMapping,
                  },
                  {
                    key: "dueDate",
                    action: (date: any) =>
                       date ? convertDateInReadableFormat(date) : "-",
                  },
                  {
                    key: "sentOn",
                      action: (date: any) =>
                   date ? convertDateInReadableFormat(date) : "-",
                  },
                ],
              },
            }}
            headerButtons={[
              {
                title: "Import",
                icon: <PlusOutlined />,
                onClick: () => {
                  setImportModal(!importModal);
                },
                visibility: auth.isPermissionExists(["0"]),
              },
              {
                title: "Send Notifications",
                icon: <BellOutlined />,
                onClick: () => {
                  setNotiModal(!notiModal);
                },
                visibility: auth.isPermissionExists(["0"]),
                disabled: !tableData?.length,
              },
              {
                title: "View",
                icon: <EyeOutlined />,
                onClick: () => {
                  setSideDrawerVisible(!sideDrawerVisible);
                },
                visibility: auth.isPermissionExists(["0"]),
              },
            ]}
          />
        )}
        <ImportContactListModal
          visibility={importModal}
          setVisibility={setImportModal}
          isImported={isImported}
          setIsImported={setIsImported}
          setIsFileUploded={setIsFileUploded}
          isFileUploded={isFileUploded}
          safetyDocumentID={safetyDocumentID}
          // getTableData={getTableData}
          flag={refreshReTable}
          setFlag={setRefreshReTable}
        />
        <SideDrawer
          sideDrawerVisible={sideDrawerVisible}
          setSideDrawerVisible={setSideDrawerVisible}
          data={sideDrawerData}
        />
      </styles.Body>
    </styles.Container>
  );
}

export default ContactCompliance;
