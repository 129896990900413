import ReusableForm from "../../unity-ui-common/Components/ReForm";
import ReTable from "../../unity-ui-common/Components/ReTable/ReTable";
import ReTab from "../../unity-ui-common/Components/ReTab";
import ReDatePicker from "../../unity-ui-common/Components/ReFormFields/ReDatePicker";
import ReFilter from "../../unity-ui-common/Components/ReFormFields/ReFilter";
import { Button, Form, Space } from "antd";
import { useState, useEffect } from "react";
import { Container } from "../SitesReport/SitesReportStyles";
import { GiantFilter } from "../SafetyDocumentReport/SafetyDocumentReportStyles";
import { SearchOutlined } from "@ant-design/icons";
import { ICheckbox } from "../../unity-ui-common/Components/ReFormFields/Interface";
import {
  // aggregatedSitesReportColumns,
  segregatedSitesReportColumns,
} from "./Constant";
import { convertDateInReadableFormat } from "../../Helper/Methods";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import { useNavigate } from "react-router-dom";
import { Uniview } from "../../API/Uniview";
import { API_URL } from "../../API/Config";

const SitesReport = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  let allFilterItems: any = {
    siteNumber: [],
    siteName: [],
    name: [],
  };
  // const option = {
  //   limit: 10,
  //   orderBy: {
  //     createdAt: "ASC",
  //   },
  // };
  const defaultRequiredGroupby = [["siteNumber", "siteName", "name"]];

  const [refreshReTable, setRefreshReTable] = useState<boolean>(false);
  const [activeTabValue, setActiveTabValue] = useState<string>("0");
  const [showFilterItems, setShowFilterItems] = useState<any>(allFilterItems);
  const [selectedFilters, setSelectedFilters] = useState<any>({});
  // const [tableData, setTableData] = useState<Array<any>>([]);
  const [selectedRowValues, setSelectedRowValues] = useState<any[]>([]);
  // const [paginationPageSize, setPaginationPageSize] = useState<number>(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [requiredGroupBy, setRequiredGroupBy] = useState(
    defaultRequiredGroupby
  );
  const [loading, setLoading] = useState({
    siteNumber: false,
    siteName: false,
    name: false,
    table: false,
  });

  const handleFilterReset = async () => {
    form.resetFields();
    // setTableData([]);
    setShowFilterItems(allFilterItems);
    setSelectedFilters({});
    setRequiredGroupBy(defaultRequiredGroupby);
    activeTabValue === "0" &&
      (await getFitersData(
        {},
        activeTabValue === "0"
          ? defaultRequiredGroupby[0]
          : defaultRequiredGroupby[1]
      ));
    // getTableData({});
    setSelectedRowKeys([]);
    setSelectedRowValues([]);
    navigate(`${window.location.pathname}`);
    setRefreshReTable(!refreshReTable);
  };

  // const createNewDataForTable = (data: any) => {
  //   return data?.map((record: any, idx: number) => {
  //     return {
  //       ...record,
  //       type:
  //         record?.type === 0 ? record.typeValue : `${record.type}-day SUSAR`,
  //     };
  //   });
  // };

  // console.log(selectedRowKeys, selectedRowValues);

  // const getTableData = async (filter: any) => {
  //   const uniView = new Uniview("distributions", "siteReport");
  //   setLoading((prevValue) => ({
  //     ...prevValue,
  //     table: true,
  //   }));
  //   const tableDataRes = await uniView.fetchData(
  //     filter,
  //     option,
  //     activeTabValue === "1" ? "aggregateData" : "data"
  //   );
  //   setLoading((prevValue) => ({
  //     ...prevValue,
  //     table: false,
  //   }));
  //   if (tableDataRes?.status) {
  //     setTableData(createNewDataForTable(tableDataRes.data || []));
  //     setPaginationPageSize(tableDataRes.count);
  //   }
  // };

  const getFitersData = async (filter?: any, currentGroupBy?: string[]) => {
    if (!filter || Object?.keys(filter)?.length === 0) {
      setLoading((prevValue) => ({
        ...prevValue,
        siteNumber: true,
        siteName: true,
        name: true,
      }));
    } else if (currentGroupBy && currentGroupBy?.length) {
      let loadingState: any = {};
      currentGroupBy?.forEach((ele: string) => {
        loadingState[ele] = true;
      });
      setLoading(loadingState);
    }
    const dataFetcher = new Uniview("distributions", "siteReport");
    const groupByElements =
      currentGroupBy || requiredGroupBy[parseInt(activeTabValue)];
    const res = await dataFetcher.getFilter(filter || {}, groupByElements);
    setLoading((prevValue) => ({
      ...prevValue,
      siteNumber: false,
      siteName: false,
      name: false,
    }));
    if (res?.status) {
      let filterData: any = {};
      groupByElements?.forEach((filterItems) => {
        filterData[filterItems] = res?.data?.[filterItems];
      });
      setShowFilterItems((prevValue: any) => ({
        ...prevValue,
        ...filterData,
      }));
      allFilterItems = {
        ...allFilterItems,
        ...filterData,
      };
    }
  };

  const handleFilterCheckboxSelect = async (
    filterName: string,
    checkboxEle: ICheckbox
  ) => {
    let filter = selectedFilters,
      groupBy = requiredGroupBy;
    let currentGroupBy = requiredGroupBy[parseInt(activeTabValue)];
    const index = currentGroupBy.indexOf(filterName);
    if (form.getFieldValue(checkboxEle?.name)) {
      setShowFilterItems((prevValue: any) => ({
        ...prevValue,
        [filterName]: allFilterItems?.[filterName],
      }));
      if (filter.hasOwnProperty(filterName)) {
        delete filter[filterName];
      }
      index === -1 && currentGroupBy.push(filterName);
    } else {
      setShowFilterItems((prevValue: any) => ({
        ...prevValue,
        [filterName]: [checkboxEle],
      }));
      filter[filterName] = {
        "=": checkboxEle?.name,
      };
      index !== -1 && currentGroupBy.splice(index, 1);
    }
    groupBy[parseInt(activeTabValue)] = currentGroupBy;
    setSelectedFilters(filter);
    setRequiredGroupBy(groupBy);
    getFitersData(filter, currentGroupBy);
    // getTableData(filter);
    setRefreshReTable(!refreshReTable);
  };

  const handleFilterTextChange = (filterName: string): any => {
    if (
      !allFilterItems?.[filterName] ||
      allFilterItems?.[filterName]?.length === 0
    )
      return;
    const lowerCaseSearchTerm = form.getFieldValue(filterName)?.toLowerCase();
    const searchBasedFilter = allFilterItems?.[filterName]?.filter(
      (ele: any) =>
        ele.label.toLowerCase().includes(lowerCaseSearchTerm) ||
        ele.name.toLowerCase().includes(lowerCaseSearchTerm)
    );
    setShowFilterItems((prevValue: any) => ({
      ...prevValue,
      [filterName]: searchBasedFilter,
    }));
  };

  const handleDateRangeChange = async (value: any, name: string) => {
    let filters = {};
    if (value === null) {
      let newFilters = selectedFilters;
      delete newFilters[`${name}`];
      filters = newFilters;
    } else {
      const start = new Date(value?.[0]._d);
      const end = new Date(value?.[1]._d);
      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 59, 59);
      filters = {
        ...selectedFilters,
        [name]: {
          ">": start,
          "<": end,
        },
      };
    }
    setSelectedFilters(filters);
    // await getTableData(filters);
    setRefreshReTable(!refreshReTable);
  };

  const generatePDF = async () => {
    const selectionCriteria = {
      ...(activeTabValue === "1" && {
        "Day 0": selectedFilters.day0
          ? `${convertDateInReadableFormat(
              selectedFilters?.day0[">"]
            )} to ${convertDateInReadableFormat(selectedFilters?.day0["<"])}`
          : "NA",
        "Due Date": selectedFilters.dueDate
          ? `${convertDateInReadableFormat(
              selectedFilters?.dueDate[">"]
            )} to ${convertDateInReadableFormat(selectedFilters?.dueDate["<"])}`
          : "NA",
      }),
      ...(activeTabValue === "0" && {
        "Site Number": selectedFilters?.siteNumber
          ? selectedFilters?.siteNumber?.["="]
          : "NA",
        "Site Name": selectedFilters?.siteName
          ? selectedFilters?.siteName?.["="]
          : "NA",
      }),
    };
    

    const univiewService = new Uniview("distributions", "siteReport");
    const extra = {
      selectionCriteria,
      reportName: "Site Segregated Data",
      selectedTableRow: selectedRowValues,
    };

    try {
      const res = await univiewService.generatePdf(
        selectedFilters,
        {},
        "Site Segregated Data",
        "data",
        extra
      );
      if (res.statusCode === 200) {
        ReNotification({
          header: "Downloaded Successfully.",
          description: "File Download Successful! ",
          type: "success",
        });
        setSelectedRowValues([]);
        setSelectedRowKeys([]);
      }
    } catch (e) {
      console.log(e);
      ReNotification({
        header: "File Download Failed",
        description: "Please Try Again Later.",
        type: "error",
      });
    }
  };

  // useEffect(() => {
  //   handleFilterReset();
  // }, [activeTabValue]);

  useEffect(() => {
    getFitersData();
  }, []);

  const commonGaintFilterForm = (
    <ReusableForm
      onSubmit={() => {}}
      formInstance={form}
      resetFieldsAfterSubmit={false}
      className="form"
    >
      <div className="top-filter">
        <div className="date-container">
          {activeTabValue === "1" && (
            <>
              <ReDatePicker
                label="Day 0"
                name="day0"
                type="rangePicker"
                dateFormat="DD-MMM-YYYY"
                width="100%"
                featureDates
                onChange={handleDateRangeChange}
              />
              <ReDatePicker
                label="Due Date"
                name="dueDate"
                type="rangePicker"
                dateFormat="DD-MMM-YYYY"
                width="100%"
                featureDates
                onChange={handleDateRangeChange}
              />
            </>
          )}
        </div>
      </div>
      <div className="filterItems">
        {activeTabValue === "0" && (
          <>
            <ReFilter
              reInputProps={{
                name: "siteNumber",
                label: "Site Number",
                type: "simple",
                prefix: <SearchOutlined />,
              }}
              filterItems={showFilterItems?.siteNumber || []}
              handleCheckboxClick={handleFilterCheckboxSelect}
              onTextFieldChange={handleFilterTextChange}
              loading={loading?.siteNumber}
            />
            <ReFilter
              reInputProps={{
                name: "siteName",
                label: "Site Name",
                type: "simple",
                prefix: <SearchOutlined />,
              }}
              filterItems={showFilterItems?.siteName || []}
              handleCheckboxClick={handleFilterCheckboxSelect}
              onTextFieldChange={handleFilterTextChange}
              loading={loading?.siteName}
            />
          </>
        )}
      </div>
    </ReusableForm>
  );

  return (
    <Container>
      <GiantFilter>
        <div className="tab-container">
          <ReTab
            activeKey={activeTabValue}
            onChange={(id: string) => {
              setActiveTabValue(id);
            }}
            tabBarExtraContent={
              <Space direction="horizontal">
                <Button onClick={handleFilterReset}>Reset</Button>
              </Space>
            }
            styles={{ padding: "0" }}
            items={[
              {
                title: "Segregated Report",
                key: "0",
                children: commonGaintFilterForm,
              },
              // {
              //   title: "Aggregated Report",
              //   key: "1",
              //   children: commonGaintFilterForm,
              // },
            ]}
          />
        </div>
      </GiantFilter>
      {/* <ReTable
            name="Site Analytics"
            columns={
              activeTabValue === "0"
                ? segregatedSitesReportColumns
                : aggregatedSitesReportColumns
            }
            data={tableData}
            loading={loading?.table}
            showTableStats
            rowSelection={{
              selectedRowKeys: selectedRowKeys,
              type: "checkbox",
              setSelectedRowKeys: setSelectedRowKeys,
              rowKey: "id",
              selectRowtype: "allData",
              selectedRowValues: selectedRowValues,
              setSelectedRowValues: setSelectedRowValues,
            }}
            pagination={{
              total: paginationPageSize,
            }}
            scroll={{
              x: activeTabValue === "0" ? 2800 : 2450,
              y: 800,
            }}
            uniView={
              activeTabValue === "0"
                ? {
                    uniView: "distributions",
                    viewName: "siteReport",
                    apiUrl: API_URL,
                    option: {
                      orderBy: {
                        key: "createdAt",
                        order: "ASC",
                      },
                      limit: 10,
                    },
                    filter: selectedFilters,
                    returnResponse: (res) => {
                      if (res?.status) {
                        setTableData(createNewDataForTable(res?.data));
                      }
                    },
                  }
                : undefined
            }
            exportOption={{
              csv: {
                endPoint: activeTabValue === "1" ? "aggregateData" : "data",
                selectRowtype: "allData",
                disabled: !tableData.length,
                params: {
                  uniView: "distributions",
                  viewName: "siteReport",
                  apiUrl: API_URL,
                  option: {
                    orderBy: {
                      key: "createdAt",
                      order: "ASC",
                    },
                    limit: 10,
                  },
                  filter: selectedFilters,
                },
              },
              pdf: {
                onClick: generatePDF,
                disabled: !tableData.length,
                loading: false,
              },
            }}
          /> */}
      <ReTable
        name="Site Analytics Report"
        title="Site Analytics"
        refresh={refreshReTable}
        columns={segregatedSitesReportColumns}
        showTableStats
        selectAllOnlyCurrentPage={true}
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          type: "checkbox",
          setSelectedRowKeys: setSelectedRowKeys,
          rowKey: "id",
          selectRowtype: "allData",
          selectedRowValues: selectedRowValues,
          setSelectedRowValues: setSelectedRowValues,
        }}
        scroll={{
          x: 2800,
          y: 800,
        }}
        uniView={
          activeTabValue === "0"
            ? {
                uniView: "distributions",
                viewName: "siteReport",
                apiUrl: API_URL,
                option: {
                  orderBy: {
                    key: "createdAt",
                    order: "ASC",
                  },
                  limit: 10,
                },
                filter: selectedFilters,
              }
            : undefined
        }
        exportOption={{
          csv: {
            endPoint: "data",
            selectRowtype: "allData",
            params: {
              uniView: "distributions",
              viewName: "siteReport",
              apiUrl: API_URL,
              option: {
                orderBy: {
                  key: "createdAt",
                  order: "ASC",
                },
                limit: 10,
              },
              filter: selectedFilters,
            },
          },
          pdf: {
            onClick: generatePDF,
          },
        }}
      />
    </Container>
  );
};

export default SitesReport;
