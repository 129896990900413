import {
  SearchOutlined,
  PlusOutlined,
  FileSearchOutlined,
  ExpandOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import Search from 'antd/lib/input/Search';
import { useEffect, useState } from 'react';
import * as Styles from './TemplateManagementStyles';
import { useNavigate } from 'react-router-dom';
import { makeRequest } from '../../API/Common';

function TemplateManagement() {
  const navigate = useNavigate();
  const [allTemplateData, setAllTemplateData] = useState<any>([]);
  const [viewTemplateData, setViewTemplateData] = useState<any>([]);
  const [searchTerm, setsearchTerm] = useState('');

  useEffect(() => {
    onPageLoad();
  }, []);

  async function onPageLoad() {
    try {
      const response = await makeRequest('template/fetch-all');
      const allTemplateRes = response.fileUrls; // Assuming your API returns the data in this format
      setAllTemplateData(allTemplateRes); // Store all template data
      setViewTemplateData(allTemplateRes); // Set view data to all templates
    } catch (error) {
      console.error('Error fetching templates:', error);
    }
  }

  const onSearch = (searchTerm: string) => {
    const value = searchTerm || '';
    const filterData: any = [];
    setsearchTerm(value);
    if (value === '') {
      setViewTemplateData(allTemplateData);
      return;
    }

    allTemplateData.forEach((data: any) => {
      if (data.name.toLowerCase().includes(value.toLowerCase().trim())) {
        filterData.push(data);
      }
    });

    setViewTemplateData(filterData);
  };

  return (
    <Styles.Container>
      <div className='heading'>
        <h2>Template Manager</h2>
      </div>
      <div className='searchBar'>
        <Search
          placeholder='Search Templates'
          allowClear
          enterButton={<SearchOutlined />}
          size='large'
          onSearch={onSearch}
        />
      </div>
      <div className='headingSection'>
        <h2>
          {searchTerm?.length > 0 ? 'Search Results' : 'Available Templates'}
        </h2>
        <div className='addNewBtn'>
          <Button
            type='primary'
            onClick={() => {
              navigate('/template-management/new-template');
            }}
          >
            <PlusOutlined /> Add New
          </Button>
        </div>
      </div>
      {viewTemplateData?.length ? (
        <div className='allNewTemplate'>
          {viewTemplateData.map((data: any, index: number) => (
            <div className='category' key={data.id}>
              <div className='templateInfo'>
                <h3>{data.name}</h3>
                <div
                  className={`newTemplateDesign ${index > 0 ? 'blur' : ''}`}
                  key={data.name}
                >
                  <img src={data.screenshot} alt={data.name} />
                </div>
                <Button
                  icon={<ExpandOutlined />}
                  onClick={() => window.open(data.url, '_blank')}
                >
                  Open Editor
                </Button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className='templatesNotFound'>
          <FileSearchOutlined />
          <div className='text'>No Templates Found</div>
        </div>
      )}
    </Styles.Container>
  );
}

export default TemplateManagement;
