import { useEffect, useState } from "react";
import ReDrawer from "../../unity-ui-common/Components/ReDrawer";
import CreateSafetyDocument from "../CreateSafetyDocument/CreateSafetyDocument";
import moment from "moment";
import { report } from "../../API/Report";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import { Link, useNavigate } from "react-router-dom";
import { ruleEngine } from "../../API/RuleEngine";
import ReTable from "../../unity-ui-common/Components/ReTable/ReTable";
import { convertDateInReadableFormat } from "../../Helper/Methods";
import ReModal from "../../unity-ui-common/Components/ReModal";
import RulebuilderQueryDes from "../RulebuilderQueryDes/RulebuilderQueryDes";
import * as styles from "../../Routes/Search/SearchStyles";
import {
  ArrowLeftOutlined,
  CloseOutlined,
  EyeOutlined,
  UploadOutlined
} from "@ant-design/icons";
import { Button,
  Upload,
  UploadFile,
  UploadProps,
 } from "antd";
import { file } from "../../API/File";
interface IProps {
  visibility: boolean;
  setVisibility: Function;
  existingSafetyDocumentData: any;
}

function CreateVersionOfSafetyDocument(props: IProps) {
  const { visibility, setVisibility, existingSafetyDocumentData } = props;
  const navigate = useNavigate();
  const [formData, setFormData] = useState<any>({});
  const [initialFormData, setInitialFormData] = useState<any>({});
  const [createNewReportForm, setCreateNewReportForm] =
    useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
   const [isMatchingRulesVisable, setIsMatchingRulesVisible] =
    useState<boolean>(false);
    const [desModalVisibility, setDesModalVisibility] = useState<boolean>(false);
    const [matchingRules, setMatchingRules] = useState<any>([]);
    const [selectedRule, setSelectedRule] = useState<any>({});
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [filesInfo, setFilesInfo] = useState<Array<any>>([]);
    const [uploadingFile, setUploadingFile] = useState<any>(false);
    const [isCheckRulesLoading ,setIsCheckRulesLoading] = useState<boolean>(false);
    const [selectedMatchingRulesRowKeys, setSelectedMatchingRulesRowKeys] =
    useState<string[]>([]);


    const checkForMatchingRules = async (values: any) => {
      const payload = {
        investigatorCausality: values.investigatorCausality,
        sponsorCausality: values.sponsorCausality,
        country: values.country,
        studyNumber: values.studyID,
        investigationalProduct: values.product,
        seriousCriteria: values.seriousnessCriteria,
        saeReportedTerm: values.saeReportedTerm,
      };
           setIsCheckRulesLoading(true)
      const res = await ruleEngine.check({
        e2br3data: payload,
        isOther: values.typeValue ? true : false,
      });
      if (res?.status) {
        setCreateNewReportForm(false);
        setIsMatchingRulesVisible(true);
        setMatchingRules(res.data);
        setSelectedMatchingRulesRowKeys(res.data.map((row: any) => row.ruleId)); // by default all rows selected
      }
    setIsCheckRulesLoading(false)
    };

  const handleSubmit = async (values: any) => {
    setFormData(values);
    setInitialFormData(values)
    checkForMatchingRules(values)
    // console.log(values)
    // delete values?.severity;
    // const payload = {
    //   ...values,
    //   serialNumber: existingSafetyDocumentData.serialNumber,
    // };
    // const machingRules = existingSafetyDocumentData.matchingRules.map(
    //   (rul: any) => rul.id
    // );
    // const res = await report.create(payload, machingRules);
    // if (res) {
    //   navigate(`/reports/view/${res.id}`);
    //   ReNotification({
    //     description: "Version Created Successfully",
    //     type: "success",
    //   });
    // }
    // setVisibility(false);
  };


  const handleCreateReportClicks = async () => {
    // now create a api call for creation
    setIsSubmitting(true);
    delete formData?.severity; 
    const payload = {
        ...formData,
        serialNumber: existingSafetyDocumentData.serialNumber,
      };   
    const create = await report.create(payload, selectedMatchingRulesRowKeys);
    if (create) {
        navigate(`/reports/view/${create.id}`);
        ReNotification({
        description: "Version Created Successfully",
        type: "success",
      });
    }
    setIsSubmitting(false);
  };

  const createFormInitialData = async (data: any) => {
    const newSeverityValue = Array.isArray(data?.meta?.severity)
      ? data?.meta?.severity?.map((ele: any, idx: number) => {
          return {
            key: `${ele}_${idx}`,
            label: ele,
          };
        })
      : [];
    setInitialFormData({
      type: data?.type,
      typeValue: data?.typeValue,
      studyID: data?.studyID,
      product: data?.product,
      sourceSiteID: data?.sourceSiteID,
      lrd: data?.lrd ? moment(data?.lrd).utc() : "",
      safetyDatabaseReportNumber: data?.safetyDatabaseReportNumber,
      investigatorCausality: data?.meta?.investigatorCausality,
      sponsorCausality: data?.meta?.sponsorCausality,
      seriousnessCriteria: data?.meta?.seriousnessCriteria,
      country: data?.country,
      saeReportedTerm: data?.meta?.saeReportedTerms,
      studyTitle: data?.studyTitle,
      attachment: data?.attachments,
      sourceSiteName: data?.sourceSiteName,
      severity: newSeverityValue,
    });
  };

  useEffect(() => {
    createFormInitialData(existingSafetyDocumentData);
  }, [existingSafetyDocumentData]);
  const matchingRulescolumns = [
    {
      title: "Rule Title",
      dataIndex: "ruleName",
    },
    {
      title: "Rule Description",
      dataIndex: "contactRuleDesc",
      render: (contactRuleDesc: string, record: any) => {
        const text = contactRuleDesc ? (
          <Link
            to=""
            onClick={() => {
              setDesModalVisibility(true);
              setSelectedRule(record);
            }}
          >
            <EyeOutlined /> View
          </Link>
        ) : (
          "-"
        );
        return text;
      },
    },
  ];
  const handleDrawerRevertAndClose = () => {
    if (
    
      createNewReportForm &&
      !isMatchingRulesVisable
    ) {
      setVisibility(false);
    } else if (
     
      !createNewReportForm &&
      isMatchingRulesVisable
    ) {
      setCreateNewReportForm(true);
      setIsMatchingRulesVisible(false);
    } else {
      setIsMatchingRulesVisible(false);
      setCreateNewReportForm(true);
    }
  };

  const uploadFile = async (fileObj: any) => {
    setUploadingFile(true);

    const filenameArr = fileObj["name"].split(".");
    const file_type = filenameArr[filenameArr.length - 1];

    const res = await file.getUrl(file_type);
    const fileName = filenameArr[0];

    await file.remoteUploadFile(res.url, fileObj);
    setUploadingFile(false);

    return {
      fileUID: fileObj.uid,
      id: res.id,
      filename: fileName,
      file_type,
    };
  };
  const uploadProps: UploadProps = {
    onRemove: (fileObj: any) => {
      const index = fileList.indexOf(fileObj);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFilesInfo((prev) =>
        prev.filter((item) => item.fileUID != fileObj.uid)
      );
    },
    beforeUpload: async (fileObj: any) => {
      setFileList([fileObj]);
      const res = await uploadFile(fileObj);
      setFilesInfo([res]);
      return true;
    },
    fileList,
    disabled: uploadingFile,
    accept: ".xml",
  };

  let handleAutoFillFileUpload = async () => {
    let res = await ruleEngine.import("{}", filesInfo[0]);
    if (res?.status) {
      let val = res?.data
    
      setInitialFormData((prev: any) => ({
        type: val?.type,
      
        studyID: val?.studyNumber,
        product: val?.investigationalProduct?.join(", "),
      
        lrd: val?.day0 ? moment(val?.day0) : "",
        safetyDatabaseReportNumber: val?.safetyDatabaseReportNumber, // Use initialFormData here
        investigatorCausality: val?.investigatorCausality,
        sponsorCausality: val?.sponsorCausality,
        seriousnessCriteria: val?.seriousCriteria,
        country: val?.country,
        saeReportedTerm: val?.saeReportedTerm,
        studyTitle: val?.studyTitle,
        sonarFix:val?.sonarFix
      }));
      setFileList([]);
    } else {
      ReNotification({
        description: res?.message,
        duration: 1000,
        placement: "bottomLeft",
        type: "error",
      });
    }
  };
  return (
    <div>
      <ReDrawer
        visibility={visibility}
        width={750}
        closable={false}
        // title="Create Version"
        // onCancel={() => {
        //   setVisibility(false);
        //   setCreateNewReportForm(true)
        // }}
        title={
          <div>
            {createNewReportForm &&
            !isMatchingRulesVisable ? (
              <CloseOutlined
                onClick={handleDrawerRevertAndClose}
                style={{
                  fontSize: "18px",
                  color: "black",
                  marginRight: "0.5rem",
                }}
              />
            ) : (
              <ArrowLeftOutlined
                onClick={handleDrawerRevertAndClose}
                style={{
                  fontSize: "18px",
                  color: "black",
                  marginRight: "0.5rem",
                }}
              />
            )}

            <span>Create Version</span>
          </div>
        }
      > 
      
    
      {createNewReportForm && (
        <>
        <div style={{ display: "flex",flexDirection:"column" ,gap: "10px"}}> 
        <Upload {...uploadProps}>
          <Button
            disabled={false} // Always enabled for upload
            size="middle"
            icon={<UploadOutlined />}
          >
            Upload File
          </Button>
        </Upload>
        {fileList?.length>0&& !uploadingFile &&
        <Button
         style={{ width: '150px' }}
          type="primary"
          size="middle"
          onClick={handleAutoFillFileUpload}
          disabled={fileList.length === 0 && !uploadingFile} // Adjusted condition for enabling the button
        >
          Submit
        </Button>}
      </div>
       {fileList?.length===0&& <CreateSafetyDocument
          submitButtonText="Next"
          initialData={initialFormData}
          onFormSubmit={handleSubmit}
          type="version"
          typeOfImport={1}
        />}
        </>)}
        
         {isMatchingRulesVisable && (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p>
                  <span>Safety Document Type: </span>
                  {formData?.type === 0
                    ? formData.typeValue
                    : `${formData.type}-day SUSAR`}
                </p>
                <p>
                  <span>Safety Document ID: </span>
                  {formData?.safetyDatabaseReportNumber
                    ? formData.safetyDatabaseReportNumber
                    : "-"}
                </p>
              </div>
              <p>
                <span>Day 0: </span>
                {formData?.lrd
                  ? convertDateInReadableFormat(formData?.lrd)
                  : "-"}
              </p>
            </div>
            <ReTable
              name="Applicable Rules"
              title="Applicable Rules"
              data={matchingRules}
              loading = {isCheckRulesLoading}
              columns={matchingRulescolumns}
              rowSelection={{
                selectedRowKeys: selectedMatchingRulesRowKeys,
                type: "checkbox",
                setSelectedRowKeys: setSelectedMatchingRulesRowKeys,
                rowKey: "ruleId",
              }}
            />
            <ReModal
              title="Description"
              visibility={desModalVisibility}
              onOkay={() => {}}
              onCancel={() => {
                setDesModalVisibility(false);
              }}
              footer={false}
            >
              <RulebuilderQueryDes ruleData={selectedRule} />
            </ReModal>
            <styles.CreateReportBtn>
              <Button
                type="primary"
                size="middle"
                onClick={handleCreateReportClicks}
                loading={isSubmitting}
              >
                Create
              </Button>
            </styles.CreateReportBtn>
          </>
          
        )}
      </ReDrawer>
    </div>
  );
}

export default CreateVersionOfSafetyDocument;
