import styled from "styled-components";
export const Container = styled.div`
     
     display: grid;
  gap: 1rem;
  justify-content: center;
  margin: 1rem auto 3rem auto;
  max-width: 60vw;
`;

export const Wrapper = styled.div`
     height:95vh;
     width: 100%;
     overflow-y: auto;
     
   `;