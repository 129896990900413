import { useEffect, useState } from "react";
import moment from "moment";
import CaseDetailHeader from "../ReportDetailHeader/ReportDetailHeader";
import * as styles from "./SafetyDocumentDetailInformationStyles";
import { auth } from "../../API/Auth";
import ReusableForm from "../../unity-ui-common/Components/ReForm";
import ReInput from "../../unity-ui-common/Components/ReFormFields/ReInput";
import ReSelect from "../../unity-ui-common/Components/ReFormFields/ReSelect";
import ReDatePicker from "../../unity-ui-common/Components/ReFormFields/ReDatePicker";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import { convertDateInReadableFormat } from "../../Helper/Methods";
import ReRadioGroup from "../../unity-ui-common/Components/ReFormFields/ReRadioGroup";
import { Collapse, Button, Form, UploadProps ,UploadFile, Upload} from "antd";
import {
  InvestigatorCausalityDropDownItems,
  seriousnessCriteriaDropDownItems,
  seriousnessCriteriaDropDownItemsDisplay,
  severityDropDownItems,
} from "../../Helper/Mappings";
import { convertCamelToSimpleCase } from "../../unity-ui-common/Methods/Methods";
import { report } from "../../API/Report";
import { ruleEngine } from "../../API/RuleEngine";
import ReCustomSelect from "../../unity-ui-common/Components/ReCustomSelect/ReCustomSelect";
import ReTable from "../../unity-ui-common/Components/ReTable/ReTable";
import ReModal from "../../unity-ui-common/Components/ReModal";
import { Link } from "react-router-dom";
import {
 EyeOutlined,
 UploadOutlined
} from "@ant-design/icons";
import RulebuilderQueryDes from "../RulebuilderQueryDes/RulebuilderQueryDes";
import { file } from "../../API/File";
import { createGlobalStyle } from "styled-components";
type Props = {
  setReportDetails: Function;
  reportDetials: any;
  reportId: string | undefined;
  allVersions: any;
  selectedReport: any;
  setSelectedReport: React.Dispatch<any>;
  handleReportChange?:(newReportId: string) => void;
  setCommonRefresh:React.Dispatch<boolean>;
  isDataFetching:boolean;
};

function SafetyDocumentDetailInformation(props: Props) {
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const { reportDetials, reportId, setReportDetails, handleReportChange, setCommonRefresh,isDataFetching } = props;
   
   
  const [selectedRule, setSelectedRule] = useState<any>({});
  const [desModalVisibility, setDesModalVisibility] = useState<boolean>(false);
  const [allCountries, setAllCountries] = useState<any>([]);
  const [editable, setEditable] = useState(false);
  const [isRuleVisible,setIsRuleVisible] = useState(false)
  const [matchingRules, setMatchingRules] = useState<any>([]);
  const [documentTypeOtherVisibility, setdocumentTypeOtherVisibility] =
    useState<boolean>(false);
    const [uploadingFile, setUploadingFile] = useState<any>(false);
    const [filesInfo, setFilesInfo] = useState<Array<any>>([]);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [selectedMatchingRulesRowKeys, setSelectedMatchingRulesRowKeys] =
    useState<string[]>([]);
    const [isCheckRulesLoading ,setIsCheckRulesLoading] = useState<boolean>(false);
    const [isMatchingRulesVisable, setIsMatchingRulesVisible] =
    useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [commaSeperatedValues, setCommaSeperatedValues] = useState<any>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [formSubmitValue,setFromSubmitValue] = useState<any>({})
    const [isXMLUpload,setIsXMLUpload] = useState(false)
  const setReportData = async () => {
    if (reportId) {
      const seriousnessCriteriaValue = Array.isArray(
        reportDetials?.meta?.seriousnessCriteria
      )
        ? reportDetials?.meta?.seriousnessCriteria?.map(
            (seriousnessValue: string) => {
              return convertCamelToSimpleCase(seriousnessValue);
            }
          )
        : reportDetials?.meta?.seriousnessCriteria;

      const severityValue = Array.isArray(reportDetials?.meta?.severity)
        ? reportDetials?.meta?.severity?.filter((val: any) => val !== "")
        : [];

      setCommaSeperatedValues({
        seriousnessCriteria: seriousnessCriteriaValue,
        investigatorCausality: reportDetials?.meta?.investigatorCausality,
        sponsorCausality: reportDetials?.meta?.sponsorCausality,
        severity: severityValue,
      });

      form.setFieldsValue({
        safetyDatabaseReportNumber: reportDetials.safetyDatabaseReportNumber,
        seriousnessCriteria: reportDetials?.meta?.seriousnessCriteria,
        product: reportDetials.product,
        studyID: reportDetials.studyID,
        investigatorCausality: reportDetials?.meta?.investigatorCausality,
        studyTitle: reportDetials.studyTitle,
        saeReportedTerms: reportDetials?.meta?.saeReportedTerms,
        sourceSiteName: reportDetials.sourceSiteName,
        sourceSiteID: reportDetials.sourceSiteID,
        sponsorCausality: reportDetials?.meta?.sponsorCausality,
        lrd: reportDetials.lrd ? moment(reportDetials.lrd) : null,
        type: reportDetials.type,
        typeValue: reportDetials.typeValue,
        country: reportDetials?.country,
      });
    }
  };
  const checkForMatchingRules = async (values: any) => {
    if(isXMLUpload){
      const payload = {
        investigatorCausality: values.investigatorCausality,
        sponsorCausality: values.sponsorCausality,
        country: values.country,
        studyNumber: values.studyID,
        investigationalProduct: values.product,
        seriousCriteria: values.seriousnessCriteria,
        saeReportedTerm: values.saeReportedTerm,
      };
           
      const res = await ruleEngine.check({
        e2br3data: payload,
        isOther: values.typeValue ? true : false,
      });
      if (res?.status) {
        setMatchingRules(res?.data);
        setSelectedMatchingRulesRowKeys(res.data.map((row: any) => row.ruleId));
      }
    }else{
      setIsCheckRulesLoading(true)
      let matchingRules = [];
      const rules = reportDetials?.matchingRules || []
      for (let i = 0; i < rules.length; i++) {
      
        let matchingRule = {
          ruleName: rules[i].name,
          ruleId: rules[i].id,
          parent: rules[i]?.parentId || "",
          caseRuleDesc: rules[i]?.caseRuleDesc || null,
          contactRuleDesc: rules[i]?.contactRuleDesc || null,
          dueDays: rules[i]?.dueDays || "",
        };
        matchingRules.push(matchingRule);
      
    }
    setMatchingRules(matchingRules);
    setSelectedMatchingRulesRowKeys(matchingRules.map((row: any) => row.ruleId)); // by default all rows selected
    }
   
      
      setIsMatchingRulesVisible(true);
      
      
      setIsRuleVisible(true)
    // }
     setIsCheckRulesLoading(false)
  };
  const saveReport = async (values: any) => {
    setSubmitLoading(true);
    setFromSubmitValue(values);
    checkForMatchingRules(values)
    setSubmitLoading(false);
    // setSubmitLoading(true);
    // setCommaSeperatedValues({
    //   seriousnessCriteria: values.seriousnessCriteria,
    //   investigatorCausality: values.investigatorCausality,
    //   sponsorCausality: values.sponsorCausality,
    // });

    // let newSeverityValue: string[] = [];
    // values?.severity?.forEach((item: any) => {
    //   newSeverityValue.push(item.label);
    // });

    // values["meta"] = {
    //   saeReportedTerms: values.saeReportedTerms,
    //   seriousnessCriteria: values.seriousnessCriteria,
    //   severity: newSeverityValue,
    //   investigatorCausality: values.investigatorCausality,
    //   sponsorCausality: values.sponsorCausality,
    // };
    // delete values.saeReportedTerms;
    // delete values.seriousnessCriteria;
    // delete values.investigatorCausality;
    // delete values.sponsorCausality;
    // delete values.severity;

    // let res = await report.update(reportId, values);

    // if (res && res?.status === 200) {
    //   ReNotification({
    //     description: "Report saved Successfully",
    //     duration: 1000,
    //     placement: "bottomLeft",
    //     type: "success",
    //   });
    //   setEditable(false);
    //   setReportDetails({
    //     ...reportDetials,
    //     ...values,
    //   });
    // } else {
    //   ReNotification({
    //     description: "An error ocurred",
    //     duration: 1000,
    //     placement: "bottomLeft",
    //     type: "error",
    //   });
    // }
    // setSubmitLoading(false);
  };

const handleUpdateReport = async()=>{
   setSubmitLoading(true);
    setCommaSeperatedValues({
      seriousnessCriteria: formSubmitValue.seriousnessCriteria,
      investigatorCausality: formSubmitValue.investigatorCausality,
      sponsorCausality: formSubmitValue.sponsorCausality,
    });

    let newSeverityValue: string[] = [];
    formSubmitValue?.severity?.forEach((item: any) => {
      newSeverityValue.push(item.label);
    });

    formSubmitValue["meta"] = {
      saeReportedTerms: formSubmitValue.saeReportedTerms,
      seriousnessCriteria: formSubmitValue.seriousnessCriteria,
      severity: newSeverityValue,
      investigatorCausality: formSubmitValue.investigatorCausality,
      sponsorCausality: formSubmitValue.sponsorCausality,
    };
    delete formSubmitValue.saeReportedTerms;
    delete formSubmitValue.seriousnessCriteria;
    delete formSubmitValue.investigatorCausality;
    delete formSubmitValue.sponsorCausality;
    delete formSubmitValue.severity;
    formSubmitValue.matchingRule = selectedMatchingRulesRowKeys;
    let res = await report.update(reportId, formSubmitValue);
    setIsXMLUpload(false)
    if (res && res?.status === 200) {
      ReNotification({
        description: "Report saved Successfully",
        duration: 1000,
        placement: "bottomLeft",
        type: "success",
      });
      setEditable(false);
      setCommonRefresh(false)
      setCommonRefresh(true)
      setReportDetails({
        ...reportDetials,
        ...formSubmitValue,
      });
      setIsRuleVisible(false)
    } else {
      ReNotification({
        description: "An error ocurred",
        duration: 1000,
        placement: "bottomLeft",
        type: "error",
      });
    }
    setSubmitLoading(false);
}

  const handleFormChanges = (changedValues: any, allValues: any) => {
    if (allValues.type === 0) {
      setdocumentTypeOtherVisibility(true);
    } else {
      setdocumentTypeOtherVisibility(false);
    }
  };

  const handleSeriousnessCriteriaDisplay = () => {
    let finalValue = "";
    if (
      commaSeperatedValues?.seriousnessCriteria &&
      commaSeperatedValues?.seriousnessCriteria?.length > 0 &&
      Array.isArray(commaSeperatedValues?.seriousnessCriteria)
    ) {
      const formatedValueArr = commaSeperatedValues?.seriousnessCriteria?.map(
        (ele: any) => {
          let formattedValue;
          seriousnessCriteriaDropDownItemsDisplay?.forEach(
            (dropDownOptions) => {
              if (dropDownOptions.key === ele) {
                formattedValue = dropDownOptions.title;
              }
            }
          );
          return formattedValue;
        }
      );
      finalValue = formatedValueArr?.join(", ");
    }

    return finalValue || "-";
  };

  const getCountries = async () => {
    const res = await ruleEngine.getCountries();
    setAllCountries(res);
  };

  useEffect(() => {
    getCountries();
    setReportData();
    if (reportDetials?.type === 0) {
      setdocumentTypeOtherVisibility(true);
    }
  }, [reportId, reportDetials]);
  const matchingRulescolumns = [
    {
      title: "Rule Title",
      dataIndex: "ruleName",
    },
    {
      title: "Rule Description",
      dataIndex: "contactRuleDesc",
      render: (contactRuleDesc: string, record: any) => {
        
        const text = contactRuleDesc ? (
          <Link
            to=""
            onClick={() => {
              setDesModalVisibility(true);
              setSelectedRule(record);
            }}
          >
            <EyeOutlined /> View
          </Link>
        ) : (
          "-"
        );
        return text;
      },
    },
  ];

  const uploadFile = async (fileObj: any) => {
    setUploadingFile(true);

    const filenameArr = fileObj["name"].split(".");
    const file_type = filenameArr[filenameArr.length - 1];

    const res = await file.getUrl(file_type);
    const fileName = filenameArr[0];

    await file.remoteUploadFile(res.url, fileObj);
    setUploadingFile(false);

    return {
      fileUID: fileObj.uid,
      id: res.id,
      filename: fileName,
      file_type,
    };
  };
  const uploadProps: UploadProps = {
    onRemove: (fileObj: any) => {
      const index = fileList.indexOf(fileObj);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFilesInfo((prev) =>
        prev.filter((item) => item.fileUID != fileObj.uid)
      );
    },
    beforeUpload: async (fileObj: any) => {
      setFileList([fileObj]);
      const res = await uploadFile(fileObj);
      setFilesInfo([res]);
      return true;
    },
    fileList,
    disabled: uploadingFile,
    accept: ".xml",
  };
  let handleAutoFillFileUpload = async () => {
    let res = await ruleEngine.import("{}", filesInfo[0]);
    if (res?.status) {
      let val = res?.data
      form.setFieldsValue({
        safetyDatabaseReportNumber: val.safetyDatabaseReportNumber,
        seriousnessCriteria: val?.seriousCriteria,
        product: val?.investigationalProduct?.join(", "),
        studyID: val.studyNumber,
        investigatorCausality: val?.investigatorCausality,
        studyTitle: val.studyTitle,
        saeReportedTerms: val?.saeReportedTerm,
        sourceSiteName: "",
        sourceSiteID: "",
        sponsorCausality: val?.sponsorCausality,
        lrd: val.day0 ? moment(val.day0) : null,
        type: val.type,
        typeValue: "",
        country: val?.country,
        sonarFix:val?.sonarFix
      });
      setIsXMLUpload(true)
      setFileList([]);
    } else {
      setIsXMLUpload(false)
      ReNotification({
        description: res?.message,
        duration: 1000,
        placement: "bottomLeft",
        type: "error",
      });
    }
  };
  return (
    <styles.Container>
      <CaseDetailHeader
        reportId={form.getFieldValue("safetyDatabaseReportNumber")}
        lrd={convertDateInReadableFormat(form.getFieldValue("lrd"))}
        dueDate={convertDateInReadableFormat(reportDetials?.dueDate)}
        allVersions={props.allVersions}
        selectedReport={props.selectedReport}
        setSelectedReport={props.setSelectedReport}
        handleReportChange={handleReportChange}
        setIsMatchingRulesVisible={setIsMatchingRulesVisible}
        setIsRuleVisible={setIsRuleVisible}
        isDataFetching={isDataFetching}
      />
      <Collapse bordered={false} className="dropDown">
        <Panel header="Detailed Information" key="1">
          <styles.Options>
            {!editable && auth.isPermissionExists(["1"]) && (
              <Button
                size="large"
                type="link"
                onClick={() => {
                  setEditable(true);
                  setIsXMLUpload(false)
                }}
              >
               Edit
              </Button>
            )}
            {editable && (
              <>
                <Button
                  size="large"
                  type="text"
                  onClick={() => {
                    if(isRuleVisible){
                      setIsRuleVisible(false)
                    }else{
                    setEditable(false);
                    setReportData();
                    setIsXMLUpload(false)
                    }
                  }}
                >
                 {isRuleVisible?"Back":"Cancel"}    
                </Button>

               {fileList?.length===0&& <Button
                  size="large"
                  loading={submitLoading}
                  type="link"
                  onClick={() => {
                    if(isRuleVisible){
                       handleUpdateReport()
                    }else{
                      form.submit();
                    }
                    
                  }}
                >
                {isRuleVisible?"Save":"Next"}
                </Button>}
              </>
            )}
          </styles.Options>
          {editable&& form.getFieldValue("type") !== 0 && !isRuleVisible &&
                  <div style={{ display: "flex",flexDirection:"column" ,gap: "10px", paddingBottom:"10px"}}> 
                  <Upload {...uploadProps}>
                    <Button
                      disabled={false} // Always enabled for upload
                      size="middle"
                      icon={<UploadOutlined />}
                    >
                      Upload File
                    </Button>
                  </Upload>
                  {fileList?.length>0&& !uploadingFile &&
                  <Button
                   style={{ width: '150px' }}
                    type="primary"
                    size="middle"
                    onClick={handleAutoFillFileUpload}
                    disabled={fileList.length === 0 && !uploadingFile} // Adjusted condition for enabling the button
                  >
                    Submit
                  </Button>}
                </div>
                 }
          {!isRuleVisible&& fileList?.length===0&&
          <ReusableForm
            formInstance={form}
            onSubmit={saveReport}
            layout="horizontal"
            onChange={handleFormChanges}
            className="safety-document-edit-form"
          >
            <div className="formItem">
              <span>Safety Document Type</span>
              {editable ? (
                <div className="formInput">
                  <ReRadioGroup
                    defaultValue={7}
                    label="Safety Document"
                    noStyle
                    name="type"
                    items={[
                      {
                        title: "7 day SUSAR",
                        value: 7,
                      },
                      {
                        title: "15 day SUSAR",
                        value: 15,
                      },
                      {
                        title: "Other",
                        value: 0,
                      },
                    ]}
                  />
                  {documentTypeOtherVisibility && (
                    <div style={{ marginTop: "0.5rem" }}>
                      <ReInput
                        type="simple"
                        name="typeValue"
                        label=""
                        noStyle
                        size="small"
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className="formData">
                  {form.getFieldValue("typeValue")?.length > 0 &&
                  form.getFieldValue("type") === 0
                    ? form.getFieldValue("typeValue")
                    : form.getFieldValue("type") + " day SUSAR"}
                </div>
              )}
            </div>
            <div className="formItem">
              <span>SAE Reported Term(s)</span>
              {editable ? (
                <div className="formInput">
                  <ReInput
                    noStyle
                    name="saeReportedTerms"
                    label=""
                    type="simple"
                  />
                </div>
              ) : form.getFieldValue("saeReportedTerms") ? (
                <div className="formData">
                  {form.getFieldValue("saeReportedTerms")}
                </div>
              ) : (
                "-"
              )}
            </div>
            <div className="formItem">
              <span>Seriousness Criteria</span>
              {editable ? (
                <div className="formInput">
                  <ReSelect
                    noStyle
                    name="seriousnessCriteria"
                    label=""
                    items={seriousnessCriteriaDropDownItems?.map((ele: any) => {
                      return {
                        value: ele.key,
                        title: ele.title,
                      };
                    })}
                    type="multiple"
                  />
                </div>
              ) : form.getFieldValue("seriousnessCriteria")?.length ? (
                <div className="formData">
                  {handleSeriousnessCriteriaDisplay()}
                </div>
              ) : (
                "-"
              )}
            </div>
            <div className="formItem">
              <span>Product</span>
              {editable ? (
                <div className="formInput">
                  <ReInput noStyle name="product" label="" type="simple" />
                </div>
              ) : form.getFieldValue("product") ? (
                <div className="formData">{form.getFieldValue("product")}</div>
              ) : (
                "-"
              )}
            </div>
            <div className="formItem">
              <span>Severity</span>
              {editable ? (
                <div className="formInput">
                  <ReCustomSelect
                    formInstance={form}
                    label=""
                    noStyle
                    name="severity"
                    dropDownItems={severityDropDownItems?.map((ele: any) => {
                      return {
                        key: ele.title,
                        label: ele.title,
                      };
                    })}
                    defaultValues={reportDetials?.meta?.severity?.map(
                      (ele: any, idx: number) => {
                        return {
                          key: `${ele}_${idx}`,
                          label: ele,
                        };
                      }
                    )}
                  />
                </div>
              ) : (
                <div className="formData">
                  {reportDetials?.meta?.severity?.length
                    ? // Array.isArray(commaSeperatedValues?.severity) ? (
                      //   // commaSeperatedValues?.severity?.join(", ")
                      //   ""
                      // ) : (
                      //   // form.getFieldValue("severity")
                      //   ""
                      // )
                      commaSeperatedValues?.severity?.join(", ")
                    : "-"}
                </div>
              )}
            </div>
            <div className="formItem">
              <span>Study Number</span>
              {editable ? (
                <div className="formInput">
                  <ReInput noStyle name="studyID" label="" type="simple" />
                </div>
              ) : form.getFieldValue("studyID") ? (
                <div className="formData">{form.getFieldValue("studyID")}</div>
              ) : (
                "-"
              )}
            </div>
            <div className="formItem">
              <span>Investigator Causality</span>
              {editable ? (
                <div className="formInput">
                  <ReSelect
                    noStyle
                    name="investigatorCausality"
                    label=""
                    type="multiple"
                    items={InvestigatorCausalityDropDownItems?.map(
                      (ele: any) => {
                        return {
                          value: ele.title,
                          title: ele.title,
                        };
                      }
                    )}
                  />
                </div>
              ) : (
                <div className="formData">
                  {form.getFieldValue("investigatorCausality")?.length
                    ? Array.isArray(commaSeperatedValues?.investigatorCausality)
                      ? commaSeperatedValues?.investigatorCausality?.join(", ")
                      : form.getFieldValue("investigatorCausality")
                    : "-"}
                </div>
              )}
            </div>
            <div className="formItem">
              <span>Study Title</span>
              {editable ? (
                <div className="formInput">
                  <ReInput noStyle name="studyTitle" label="" type="simple" />
                </div>
              ) : form.getFieldValue("studyTitle") ? (
                <div className="formData">
                  {form.getFieldValue("studyTitle")}
                </div>
              ) : (
                "-"
              )}
            </div>
            <div className="formItem">
              <span>Sponsor Causality</span>
              {editable ? (
                <div className="formInput">
                  <ReSelect
                    allowClear={false}
                    noStyle
                    name="sponsorCausality"
                    type="multiple"
                    label=""
                    items={InvestigatorCausalityDropDownItems?.map(
                      (ele: any) => {
                        return {
                          value: ele.title,
                          title: ele.title,
                        };
                      }
                    )}
                  />
                </div>
              ) : (
                <div className="formData">
                  {form.getFieldValue("sponsorCausality")?.length
                    ? Array.isArray(commaSeperatedValues?.sponsorCausality)
                      ? commaSeperatedValues?.sponsorCausality?.join(", ")
                      : form.getFieldValue("sponsorCausality")
                    : "-"}
                </div>
              )}
            </div>
            <div className="formItem">
              <span>Site Number</span>
              {editable ? (
                <div className="formInput">
                  <ReInput noStyle name="sourceSiteID" label="" type="simple" />
                </div>
              ) : form.getFieldValue("sourceSiteID") ? (
                <div className="formData">
                  {form.getFieldValue("sourceSiteID")}
                </div>
              ) : (
                "-"
              )}
            </div>
            <div className="formItem">
              <span>Day 0</span>
              {editable ? (
                <div className="formInput">
                  <ReDatePicker
                    allowClear={false}
                    noStyle
                    label=""
                    name="lrd"
                    type="simple"
                    dateFormat="DD-MMM-YYYY"
                    width="100%"
                    featureDates
                  />
                </div>
              ) : (
                <div className="formData">
                  {convertDateInReadableFormat(form.getFieldValue("lrd") || "")}
                </div>
              )}
            </div>
            <div className="formItem">
              <span>Site Name</span>
              {editable ? (
                <div className="formInput">
                  <ReInput
                    noStyle
                    name="sourceSiteName"
                    label=""
                    type="simple"
                  />
                </div>
              ) : form.getFieldValue("sourceSiteName") ? (
                <div className="formData">
                  {form.getFieldValue("sourceSiteName")}
                </div>
              ) : (
                "-"
              )}
            </div>
            <div className="formItem">
              <span>Safety Document ID</span>
              {editable ? (
                <div className="formInput">
                  <ReInput
                    label=""
                    noStyle
                    name="safetyDatabaseReportNumber"
                    type="simple"
                  />
                </div>
              ) : form.getFieldValue("safetyDatabaseReportNumber") ? (
                <div className="formData">
                  {form.getFieldValue("safetyDatabaseReportNumber")}
                </div>
              ) : (
                "-"
              )}
            </div>
            <div className="formItem">
              <span>Country</span>
              {editable ? (
                <div className="formInput">
                  <ReSelect
                    label=""
                    noStyle
                    name="country"
                    searchable
                    items={allCountries.map((cou: any) => {
                      return {
                        value: cou.alpha2,
                        title: cou.name,
                      };
                    })}
                  />
                </div>
              ) : form.getFieldValue("country") ? (
                <div className="formData">
                  {
                    allCountries?.find(
                      (co: any) => co.alpha2 === form.getFieldValue("country")
                    )?.name
                  }
                </div>
              ) : (
                "-"
              )}
            </div>
           
          </ReusableForm>}
          {isMatchingRulesVisable&& isRuleVisible && (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p>
                  <span>Safety Document Type: </span>
                  {form.getFieldValue('type') === 0
            ? form.getFieldValue('typeValue')
            : `${form.getFieldValue('type')}-day SUSAR`}
                </p>
                <p>
                  <span>Safety Document ID: </span>
                  {form.getFieldValue('safetyDatabaseReportNumber') || "-"}
                </p>
              </div>
              <p className="spacing">
                <span>Day 0: </span>
                {form.getFieldValue('lrd')
              ? convertDateInReadableFormat(form.getFieldValue('lrd'))
              : "-"}
              </p>
            </div>
            <div>

            </div>
            <ReTable
              name="Applicable Rules"
              title="Applicable Rules"
              data={matchingRules}
              loading = {isCheckRulesLoading}
              columns={matchingRulescolumns}
              rowSelection={{
                selectedRowKeys: selectedMatchingRulesRowKeys,
                type: "checkbox",
                setSelectedRowKeys: setSelectedMatchingRulesRowKeys,
                rowKey: "ruleId",
              }}
            />
            <ReModal
              title="Description"
              visibility={desModalVisibility}
              onOkay={() => {}}
              onCancel={() => {
                setDesModalVisibility(false);
              }}
              footer={false}
            >
              <RulebuilderQueryDes ruleData={selectedRule} />
            </ReModal>
            {/* <styles.CreateReportBtn>
              <Button
                type="primary"
                size="middle"
                onClick={handleCreateReportClicks}
                loading={isSubmitting}
              >
                Create
              </Button>
            </styles.CreateReportBtn> */}
          </>
          
        )}
        </Panel>
      </Collapse>
    </styles.Container>
  );
}

export default SafetyDocumentDetailInformation;
