import ReusableForm from "../../unity-ui-common/Components/ReForm";
import ReDatePicker from "../../unity-ui-common/Components/ReFormFields/ReDatePicker";
import ReFilter from "../../unity-ui-common/Components/ReFormFields/ReFilter";
import ReTab from "../../unity-ui-common/Components/ReTab";
import ReTable from "../../unity-ui-common/Components/ReTable/ReTable";
import { useEffect, useState } from "react";
import { Button, Form } from "antd";
import { Container } from "../SitesReport/SitesReportStyles";
import { ICheckbox } from "../../unity-ui-common/Components/ReFormFields/Interface";
import { SearchOutlined } from "@ant-design/icons";
import * as styles from "./SafetyDocumentReportStyles";
import { IUniView } from "../../unity-ui-common/Components/ReTable/Interface";
import {
  // aggregatedSafetyDocColumns,
  safetyDocTypeFilter,
  segregatedDataColumn,
} from "./Constant";
import { convertDateInReadableFormat } from "../../Helper/Methods";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import { useNavigate } from "react-router-dom";
import { Uniview } from "../../API/Uniview";
import { API_URL } from "../../API/Config";

function SafetyDocumentReport() {
  let allFilterItems: any = {
    product: [],
    studyID: [],
    type: [],
    safetyDatabaseReportNumber: [],
  };
  // const option = {
  //   limit: 10,
  //   orderBy: {
  //     createdAt: "ASC",
  //   },
  // };

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [refreshReTable, setRefreshReTable] = useState<boolean>(false);
  // const [tableData, setTableData] = useState<Array<any>>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [selectedRowValues, setSelectedRowValues] = useState<any[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<any>({});
  const [activeTabValue, setActiveTabValue] = useState<string>("0");
  const [showFilterItems, setShowFilterItems] = useState<any>(allFilterItems);
  // const [paginationPageSize, setPaginationPageSize] = useState<number>(0);
  const [loading, setLoading] = useState({
    product: false,
    studyID: false,
    type: false,
    safetyDatabaseReportNumber: false,
    table: false,
  });
  const defaultRequiredGroupby = [
    ["product", "studyID", "type", "safetyDatabaseReportNumber"],
    ["product", "studyID"],
  ];
  const [requiredGroupBy, setRequiredGroupBy] = useState(
    defaultRequiredGroupby
  );
  const UniViewConfig: IUniView = {
    uniView: "reports",
    viewName: "safetyDocumentReport",
    apiUrl: API_URL,
    option: {
      orderBy: {
        key: "createdAt",
        order: "ASC",
      },
      limit: 10,
    },
    filter: selectedFilters,
  };

  const handleFilterReset = async () => {
    form.resetFields();
    // setTableData([]);
    setShowFilterItems(allFilterItems);
    setSelectedFilters({});
    setRequiredGroupBy(defaultRequiredGroupby);
    setSelectedRowKeys([]);
    setSelectedRowValues([]);
    await getFitersData(
      {},
      activeTabValue === "0"
        ? defaultRequiredGroupby[0]
        : defaultRequiredGroupby[1]
    );
    // await getTableData({});
    navigate(`${window.location.pathname}`);
    setRefreshReTable(!refreshReTable);
  };

  const getFitersData = async (filter?: any, currentGroupBy?: string[]) => {
   
    if (!filter || Object?.keys(filter)?.length === 0) {
      setLoading((prevValue) => ({
        ...prevValue,
        product: true,
        studyID: true,
        type: true,
        safetyDatabaseReportNumber: true,
      }));
    } else if (currentGroupBy && currentGroupBy?.length) {
      let loadingState: any = {};
      currentGroupBy?.forEach((ele: string) => {
        loadingState[ele] = true;
      });
      setLoading(loadingState);
    }
    const dataFetcher = new Uniview("reports", "safetyDocumentReport");
    const groupByElements =
      currentGroupBy || requiredGroupBy[parseInt(activeTabValue)];
    const res = await dataFetcher.getFilter(filter || {}, groupByElements);
   
    setLoading((prevValue) => ({
      ...prevValue,
      product: false,
      studyID: false,
      type: false,
      safetyDatabaseReportNumber: false,
    }));
    if (res?.status) {
      if (res?.data?.type && res?.data?.type?.length) {
        res.data.type = res.data.type.map(
          (ele: any) => safetyDocTypeFilter[ele.name]
        );
      }
      let filterData: any = {};
      groupByElements?.forEach((filterItems) => {
        filterData[filterItems] = res?.data?.[filterItems];
      });
      setShowFilterItems((prevValue: any) => ({
        ...prevValue,
        ...filterData,
      }));
      allFilterItems = {
        ...allFilterItems,
        ...filterData,
      };
    }
  };
  
  const handleFilterCheckboxSelect = async (
    filterName: string,
    checkboxEle: ICheckbox
  ) => {
    let filter = selectedFilters,
      groupBy = requiredGroupBy;
    let currentGroupBy = requiredGroupBy[parseInt(activeTabValue)];
    const index = currentGroupBy.indexOf(filterName);
    if (form.getFieldValue(checkboxEle?.name)) {
      setShowFilterItems((prevValue: any) => ({
        ...prevValue,
        [filterName]: allFilterItems?.[filterName],
      }));
      if (filter.hasOwnProperty(filterName)) {
        delete filter[filterName];
      }
      index === -1 && currentGroupBy.push(filterName);
    } else {
      setShowFilterItems((prevValue: any) => ({
        ...prevValue,
        [filterName]: [checkboxEle],
      }));
      filter[filterName] = {
        "=": checkboxEle?.name,
      };
      index !== -1 && currentGroupBy.splice(index, 1);
    }
    groupBy[parseInt(activeTabValue)] = currentGroupBy;
    setSelectedFilters(filter);
    setRequiredGroupBy(groupBy);
    await getFitersData(filter, currentGroupBy);
    // await getTableData(filter);
    setRefreshReTable(!refreshReTable);
  };

  const handleFilterTextChange = (filterName: string): any => {
    if (
      !allFilterItems?.[filterName] ||
      allFilterItems?.[filterName]?.length === 0
    )
      return;
    const lowerCaseSearchTerm = form.getFieldValue(filterName)?.toLowerCase();
    const searchBasedFilter = allFilterItems?.[filterName]?.filter(
      (ele: any) =>
        ele.label.toLowerCase().includes(lowerCaseSearchTerm) ||
        ele.name.toLowerCase().includes(lowerCaseSearchTerm)
    );
    setShowFilterItems((prevValue: any) => ({
      ...prevValue,
      [filterName]: searchBasedFilter,
    }));
  };

  const generatePDF = async () => {
    const selectionCriteria = {
      "Day 0": selectedFilters?.lrd
        ? `${convertDateInReadableFormat(
            selectedFilters?.lrd[">"]
          )} to ${convertDateInReadableFormat(selectedFilters?.lrd["<"])}`
        : "NA",
      ...(activeTabValue === "1" && {
        "Due Date": selectedFilters?.dueDate
          ? `${convertDateInReadableFormat(
              selectedFilters?.dueDate[">"]
            )} to ${convertDateInReadableFormat(selectedFilters?.dueDate["<"])}`
          : "NA",
      }),
      Product: selectedFilters?.product
        ? selectedFilters?.product?.["="]
        : "NA",
      "Study Number": selectedFilters?.studyID
        ? selectedFilters?.studyID?.["="]
        : "NA",
      ...(activeTabValue === "0" && {
        "Safety Document Type": selectedFilters?.type
          ? `${selectedFilters?.type?.["="]}-day SUSAR`
          : "NA",
      }),
      ...(activeTabValue === "0" && {
        "Safety Document ID": selectedFilters?.safetyDatabaseReportNumber
          ? selectedFilters?.safetyDatabaseReportNumber?.["="]
          : "NA",
      }),
    };
    const univiewService = new Uniview("reports", "safetyDocumentReport");
    const extra = {
      selectionCriteria,
      reportName: "Safety Document Segregated Data",
      selectedTableRow: selectedRowKeys,
    };
    try {
      const res = await univiewService.generatePdf(
        selectedFilters,
        {},
        "Safety Document Report",
        "data",
        extra
      );
      if (res.statusCode === 200) {
        ReNotification({
          header: "Downloaded Successfully.",
          description: "File Download Successful! ",
          type: "success",
        });
        setSelectedRowValues([]);
        setSelectedRowKeys([]);
      }
    } catch (e) {
      console.log(e);
      ReNotification({
        header: "File Download Failed",
        description: "Please Try Again Later.",
        type: "error",
      });
    }
  };

  const handleDateRangeChange = async (value: any, name: string) => {
    let filters = {};
    if (value === null) {
      let newFilters = selectedFilters;
      delete newFilters[`${name}`];
      filters = newFilters;
    } else {
      const start = new Date(value?.[0]._d);
      const end = new Date(value?.[1]._d);
      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 59, 59);
      filters = {
        ...selectedFilters,
        [name]: {
          ">": start,
          "<": end,
        },
      };
    }
    setSelectedFilters(filters);
    await getFitersData(filters, requiredGroupBy[parseInt(activeTabValue)]);
    // await getTableData(filters);
    setRefreshReTable(!refreshReTable);
  };

  // useEffect(() => {
  //   handleFilterReset();
  // }, [activeTabValue]);

  useEffect(() => {
    getFitersData();
  }, []);

  return (
    <Container>
      <styles.GiantFilter>
        <div className="tab-container">
          <ReTab
            activeKey={activeTabValue}
            onChange={(id: string) => {
              setActiveTabValue(id);
            }}
            styles={{ padding: "0" }}
            items={[
              {
                title: "Segregated Report",
                key: "0",
                children: <></>,
              },
              // {
              //   title: "Aggregated Report",
              //   key: "1",
              //   children: <></>,
              // },
            ]}
            tabBarExtraContent={
              <div className="reset-button">
                <Button onClick={handleFilterReset}>Reset</Button>
              </div>
            }
          />
        </div>
        <ReusableForm
          onSubmit={() => {}}
          formInstance={form}
          resetFieldsAfterSubmit={false}
          className="form"
        >
          {loading?.table && <div className="hidden-worker"></div>}
          <div className="top-filter">
            <div className="date-container">
              <ReDatePicker
                label="Day 0"
                name="lrd"
                type="rangePicker"
                dateFormat="DD-MMM-YYYY"
                width="100%"
                featureDates
                onChange={handleDateRangeChange}
              />
              {activeTabValue === "1" && (
                <ReDatePicker
                  label="Due Date"
                  name="dueDate"
                  type="rangePicker"
                  dateFormat="DD-MMM-YYYY"
                  width="100%"
                  featureDates
                  onChange={handleDateRangeChange}
                />
              )}
            </div>
          </div>
          <div className="filterItems">
            <ReFilter
              reInputProps={{
                name: "product",
                label: "Product",
                type: "simple",
                prefix: <SearchOutlined />,
              }}
              filterItems={showFilterItems?.product || []}
              handleCheckboxClick={handleFilterCheckboxSelect}
              onTextFieldChange={handleFilterTextChange}
              loading={loading?.product}
            />
            <ReFilter
              reInputProps={{
                name: "studyID",
                label: "Study Number",
                type: "simple",
                prefix: <SearchOutlined />,
              }}
              filterItems={showFilterItems?.studyID || []}
              handleCheckboxClick={handleFilterCheckboxSelect}
              onTextFieldChange={handleFilterTextChange}
              loading={loading?.studyID}
            />
            {activeTabValue === "0" && (
              <ReFilter
                reInputProps={{
                  name: "type",
                  label: "Safety Document Type",
                  type: "simple",
                  prefix: <SearchOutlined />,
                }}
                filterItems={showFilterItems?.type || []}
                handleCheckboxClick={handleFilterCheckboxSelect}
                onTextFieldChange={handleFilterTextChange}
                loading={loading?.type}
              />
            )}
            {activeTabValue === "0" && (
              <ReFilter
                reInputProps={{
                  name: "safetyDatabaseReportNumber",
                  label: "Safety Document ID",
                  type: "simple",
                  prefix: <SearchOutlined />,
                }}
                filterItems={showFilterItems?.safetyDatabaseReportNumber || []}
                handleCheckboxClick={handleFilterCheckboxSelect}
                onTextFieldChange={handleFilterTextChange}
                loading={loading?.safetyDatabaseReportNumber}
              />
            )}
          </div>
        </ReusableForm>
      </styles.GiantFilter>
      <styles.Container>
        {/* <ReTable
              name="Safety Document"
              columns={
                activeTabValue === "0"
                  ? segregatedDataColumn
                  : aggregatedSafetyDocColumns
              }
              refresh={refreshReTable}
              data={tableData}
              loading={loading?.table}
              showTableStats={activeTabValue === "0" ? true : undefined}
              rowSelection={{
                selectedRowKeys: selectedRowKeys,
                type: "checkbox",
                setSelectedRowKeys: setSelectedRowKeys,
                rowKey: activeTabValue === "0" ? "id" : "type",
                selectRowtype: "allData",
                selectedRowValues: selectedRowValues,
                setSelectedRowValues: setSelectedRowValues,
              }}
              pagination={{
                total: paginationPageSize,
              }}
              uniView={activeTabValue === "0" ? UniViewConfig : undefined}
              exportOption={{
                csv: {
                  endPoint: activeTabValue === "1" ? "aggregateData" : "data",
                  selectRowtype: activeTabValue === "1" ? "allData" : "id",
                  disabled: !tableData.length,
                  params: UniViewConfig,
                },
                pdf: {
                  onClick: generatePDF,
                  disabled: !tableData.length,
                  loading: false,
                },
              }}
            /> */}

        {/* Segregated Report table */}
        
        <ReTable
          name="Safety Document Report"
          title="Safety Document"
          columns={segregatedDataColumn}
          refresh={refreshReTable}
          uniView={UniViewConfig}
          showTableStats
          selectAllOnlyCurrentPage={true}
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            type: "checkbox",
            setSelectedRowKeys: setSelectedRowKeys,
            rowKey: "id",
            selectRowtype: "allData",
            selectedRowValues: selectedRowValues,
            setSelectedRowValues: setSelectedRowValues,
          }}
          exportOption={{
            csv: {
              endPoint: "data",
              selectRowtype: "id",
              params: UniViewConfig,
            },
            pdf: {
              onClick: generatePDF,
              loading: false,
            },
          }}
        />
      </styles.Container>
    </Container>
  );
}

export default SafetyDocumentReport;
